import React, { useEffect, useState } from "react";
import { match as Match, useLocation } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { injectIntl, IntlShape } from "react-intl";
import { Bar } from "react-chartjs-2";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import classNames from "classnames";
import IntlMessages from "../../../../utils/messages";
import { EnumBookingFrom, EnumPaths } from "../../../../utils/navigation";
import Button from "../../../../components/Tailwind/Button";
import {
  EnumPermissionEntity,
  EnumServiceType,
  SearchableSpaceTimelineFilterInput,
} from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import SpaceTimeFrame from "../../../../components/SpaceTimeFrame";
import PageSubheader from "../../../../components/PageSubheader";
import Table, { ALL_FILTER, IFilterField } from "../../../../components/Table";
import { getTranslation } from "../../../../utils/translation";
import { EnumFilterFields } from "../../../../utils/filter";
import images from "../../../../images";
import {
  BookingStatus,
  bookingStatusEnum,
} from "../../../../enums/BookingStatusEnum";
import { getBookableTypologiesFilter } from "../../../../utils/types";
import {
  displayDayHH,
  displayDayYYYYMMDD,
  eachUnitOfInterval,
  getDayInLocal,
  getTodayInCenterTimezone,
  transformDateForQuery,
} from "../../../../utils/config";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const EnumBackGroundColorTypology = [
  "rgba(153, 102, 255, 0.5)",
  "rgba(255, 99, 132, 0.5)",
  "rgba(53, 162, 235, 0.5)",
  "rgba(54, 192, 0, 0.5)",
  "rgba(255, 150, 0, 0.5)",
  "rgba(36, 36, 36, 0.5)",
];

const EnumBorderColorTypology = [
  "rgba(153, 102, 255)",
  "rgb(255, 99, 132)",
  "rgb(53, 162, 235)",
  "rgb(54, 192, 0)",
  "rgb(255, 150, 0)",
  "rgb(36, 36, 36)",
];

interface Props {
  match: Match<{ cid: string }>;
  intl: IntlShape;
  backURL: string;
}

const LIMIT = 5;

const ListBookingProducts = (props: Props) => {
  const { match, intl, backURL } = props;

  const location = useLocation<{ date?: Date }>();
  const { state } = location;

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(
    state?.date
      ? new Date(state.date)
      : getTodayInCenterTimezone(centerTimezone)
  );

  const searchSpaceTimelines = GroundGraphqlContextStore.useStoreActions(
    actions => actions.product.searchSpaceTimelines
  );

  const spaceTimelines = GroundGraphqlContextStore.useStoreState(
    s => s.product.spaceTimelines
  );

  const total = GroundGraphqlContextStore.useStoreState(
    s => s.product.spacesTimelinesTotal
  );

  const setBookingForm = GroundGraphqlContextStore.useStoreActions(
    actions => actions.cart.setBookingForm
  );

  const fetchSpaces = (pageIndex = 0, filters?: IFilterField<any>[]) => {
    // setLoading is handled automatically in the Table component
    setLoading(true);

    let filter: SearchableSpaceTimelineFilterInput = {
      or: getBookableTypologiesFilter(),
    };

    if (filters?.length) {
      filters.forEach(f => {
        if (f.type === EnumFilterFields[EnumFilterFields.TYPOLOGY]) {
          if (f.value?.value !== ALL_FILTER) {
            filter = {
              or: [{ typology: { eq: `${f.value.value}` } }],
            };
          }
        }
      });
    }

    searchSpaceTimelines({
      buildingId: match.params.cid,
      date: transformDateForQuery(date, centerTimezone),
      limit: LIMIT,
      filter,
      from: pageIndex * LIMIT,
    }).finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchSpaces();

    // Prevents going back from the options to this screen and having the form filled with the previous values
    setBookingForm(null);
  }, [date]);

  const handleChangeDate = (d: Date) => {
    setDate(d);
  };

  const spacesTableHead = ["header.SPACE", "general.actions"];

  const center = GroundGraphqlContextStore.useStoreState(
    state => state.center.center
  );

  const selectedDateFormatted = displayDayYYYYMMDD(date, centerTimezone);

  const spacesTableBody = spaceTimelines?.map(spaceTimeline => {
    const timelineDate = spaceTimeline.timeline_dates.find(
      e => e.formatted_date === selectedDateFormatted
    );

    return {
      rowElements: [
        {
          element: (
            <div className="flex flew-row items-center">
              <SpaceTimeFrame
                {...props}
                space={spaceTimeline}
                timelineDate={timelineDate}
              />
            </div>
          ),
        },
        {
          element: (
            <div className="flex items-end ml-8">
              {timelineDate?.available && (
                <Link
                  to={{
                    pathname: `${match.url}/${spaceTimeline.id}/${EnumPaths.BOOK}`,
                    state: {
                      date,
                      from: EnumBookingFrom.BOOKING_LIST,
                    },
                  }}
                >
                  <Button
                    id="btn-book"
                    name="btn-book"
                    item={null}
                    type="button"
                    outline
                  >
                    <span className="text-center">
                      <IntlMessages id="page.list.bookings.book" />
                    </span>
                  </Button>
                </Link>
              )}
            </div>
          ),
        },
      ],
    };
  });

  const { startDay, endDay } = getDayInLocal(date);
  const hours = eachUnitOfInterval(startDay, endDay, "hour");

  const occupancyRateOptions: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: intl.formatMessage({
          id: "page.list.bookings.chart.occupancy.rate.title",
        }),
      },
    },
    scales: {
      y: {
        suggestedMax: 100,
        suggestedMin: 0,
      },
    },
  };

  const bookingsOptions: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: intl.formatMessage({
          id: "page.list.bookings.chart.bookings.title",
        }),
      },
    },
  };

  const occupancyRateDatasets = spaceTimelines?.length
    ? spaceTimelines?.map((timeline, index) => {
        const hoursStatus = timeline.timeline_dates[0].hours_status;

        const data = hours.map(h => {
          const el = hoursStatus.find(e => e.start === h.toISOString());

          return el?.indicators.occupancy_rate
            ? el?.indicators.occupancy_rate * 100
            : 0;
        });

        return {
          label: getTranslation(timeline.name),
          data: data,
          backgroundColor: EnumBackGroundColorTypology[index],
          borderColor: EnumBorderColorTypology[index],
          borderWidth: 1,
        };
      })
    : [];

  const bookingsDatasets = spaceTimelines?.length
    ? spaceTimelines?.map((timeline, index) => {
        const hoursStatus = timeline.timeline_dates[0].hours_status;

        const data = hours.map(h => {
          const el = hoursStatus.find(e => e.start === h.toISOString());

          return el?.indicators.bookings.length || 0;
        });

        return {
          label: getTranslation(timeline.name),
          data: data,
          backgroundColor: EnumBackGroundColorTypology[index],
          borderColor: EnumBorderColorTypology[index],
          borderWidth: 1,
        };
      })
    : [];

  const renderLegendItem = (bookingStatusItem: BookingStatus) => {
    const { status, cellBgColor } = bookingStatusItem;

    return cellBgColor ? (
      <div key={`status_${status}`} className="flex items-center">
        <div className={`inline-block ${cellBgColor} w-3 h-3 mr-2`} />
        <span className="text-black text-12px leading-normal">
          <IntlMessages id={`page.calendar.legend.${status.toLowerCase()}`} />
        </span>
      </div>
    ) : null;
  };

  return !spaceTimelines || !center ? (
    <div className="loading" />
  ) : (
    <>
      {/**
       * When we were loading the form was initialized again and we would lose all data
       * Show loading here in order to not lose the fields data after a loading
       */}
      <div className={classNames({ loading })} />

      {/* Hide when loading */}
      <div className={classNames({ hidden: loading })}>
        <Helmet>
          <title>
            {intl.formatMessage({
              id: "page.list.booking.products.document.title",
            })}
          </title>
        </Helmet>

        <PageSubheader
          title="page.list.bookings.space.selected"
          goBackEnabled
          goBackId="btn-back-book"
          goBackURL={backURL}
          location={location}
        />

        <div className="px-8 pb-4">
          <Table
            head={spacesTableHead}
            body={spacesTableBody}
            noDataText={`page.list.products.${EnumServiceType.SPACE}.empty`}
            onChange={({ pageIndex, filters }) =>
              fetchSpaces(pageIndex, filters)
            }
            paginationTotal={total}
            paginationLimit={LIMIT}
            permissionEntity={EnumPermissionEntity.BOOKING_SPACE_LIST}
            filterBlockTitle="page.calendar.filter.title"
            useFilterBlock
            date={date}
            onChangeDate={handleChangeDate}
          />

          <div className="flex items-center gap-5 mt-5">
            {bookingStatusEnum.map(renderLegendItem)}

            <div className="flex items-center">
              <img
                src={images.combined}
                alt="combined"
                className="w-3 h-3 mr-2"
              />
              <span className="text-black text-12px leading-normal">
                <IntlMessages id="page.calendar.legend.combined" />
              </span>
            </div>
          </div>
        </div>

        <div className="flex my-8">
          <div className="w-1/2">
            <Bar
              options={occupancyRateOptions}
              data={{
                labels: hours.map(h => displayDayHH(h, center.timezone)),
                datasets: occupancyRateDatasets,
              }}
            />
          </div>
          <div className="w-1/2">
            <Bar
              options={bookingsOptions}
              data={{
                labels: hours.map(h => displayDayHH(h, center.timezone)),
                datasets: bookingsDatasets,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default injectIntl(ListBookingProducts);
