import classnames from "classnames";
import React from "react";
import { Product } from "../../../lib/ground-aws-graphql-core/models/Product";
import IntlMessages from "../../../utils/messages";
import { getImageUrl } from "../../../utils/picture";
import { getTranslation } from "../../../utils/translation";

interface Props {
  product: Product;
  onRemoveOption: (o: Product) => void;
}

const Option = (props: Props): JSX.Element => {
  const { product } = props;
  const { id, pictures, checkStock, stock, enabled, provider, name } = product;

  const picture = pictures?.length ? pictures[0] : null;

  // Can't use "!checkStock" instead of "checkStock === false" to verify the variable because it can be null and, in this case, null is equal to true
  const hasStock = checkStock === false || (stock ? stock > 0 : false);

  const className = classnames(
    "mt-2 sm:mt-0 sm:col-span-1 rounded-10 border h-full",
    {
      "opacity-1 bg-white border-ground-gray-200": hasStock,
      "opacity-70 bg-ground-white-200 border-red-500": !hasStock,
    }
  );

  const classNameEnabled = () =>
    classnames(
      "absolute bottom-0 right-0 block h-2 w-2 rounded-full text-white shadow-solid",
      {
        "bg-red-400": !enabled && provider.enabled,
        "bg-green-400": enabled && provider.enabled,
        "bg-black-400": !enabled && !provider.enabled,
        "bg-purple-400": enabled && !provider.enabled,
      }
    );

  return (
    <div className={className} key={id}>
      <div className="relative flex px-2 py-2">
        <span className="inline-block relative">
          <img
            src={getImageUrl(null, picture, 50, 50)}
            alt={getTranslation(name)}
          />
          <span className={classNameEnabled()} />
        </span>
        <div className="flex flex-col ml-2 justify-around">
          <span className="text-14px text-ground-gray-100">
            {getTranslation(name)}
          </span>
          <span
            className="text-10px text-ground-gray-400 cursor-pointer z-10"
            onClick={() => props.onRemoveOption(props.product)}
          >
            <IntlMessages id="general.delete" />
          </span>
        </div>
        {!hasStock && (
          <span className="text-13px text-red-500 flex absolute inset-0 items-center justify-center uppercase">
            <IntlMessages id="general.out.of.stock" />
          </span>
        )}
      </div>
    </div>
  );
};

export default Option;
