import React from "react";
import { Privatization } from "../../../../redux/models/Privatization";
import IntlMessages from "../../../../utils/messages";
import Table from "../../../../components/Table";
import { displayDayDDMMYYYY } from "../../../../utils/config";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";

interface Props {
  privatizations: Privatization[];
  title: string;
}

const CardPrivatizations = (props: Props): JSX.Element => {
  const { privatizations, title } = props;

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const privatizationsTableHead = ["general.company", "general.privatization"];

  const privatizationsTableBody = privatizations?.map(privatization => {
    const { enterprise, start, end } = privatization;

    return {
      rowElements: [
        { element: enterprise?.name },
        {
          element: `${displayDayDDMMYYYY(
            start,
            centerTimezone
          )} > ${displayDayDDMMYYYY(end, centerTimezone)}`,
        },
      ],
    };
  });

  return (
    <div className="bg-white shadow sm:rounded-lg my-30px">
      <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
        <h3 className="text-16px leading-6 font-medium text-gray-900">
          <IntlMessages id={title} />
        </h3>
      </div>
      <Table
        head={privatizationsTableHead}
        body={privatizationsTableBody}
        noDataText="general.list.empty"
        className="px-3 py-5"
      />
    </div>
  );
};

export default CardPrivatizations;
