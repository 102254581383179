import React from "react";
import { FieldAttributes } from "formik";
import HeaderSingleProduct from "../../../../../components/Tailwind/Block/HeaderSingleProduct";
import ImageGallery from "../../../../../components/Tailwind/ImageGallery";
import {
  ActionTypes,
  Image,
  isDependableTypology,
} from "../../../../../utils/types";
import ListSingleProducts from "../../single-products";
import {
  EnumProductTypology,
  EnumServiceType,
} from "../../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { Product } from "../../../../../lib/ground-aws-graphql-core/models/Product";
import DefaultForm, {
  AdditionalFieldAttributes,
} from "../../../../../components/Form";
import { validateMultipleFields } from "../../../../../utils/form";

interface Props {
  item: Product | null;
  productTypology: EnumProductTypology | null | undefined;
  serviceType: EnumServiceType;
  onAddSingleProduct: (o: Product) => void;
  onRemoveSingleProduct: (o: Product) => void;
  singleProducts: {
    id: string;
    singleProduct: Product;
    action: ActionTypes;
  }[];
  pictures: Image[];
  onAddImage: (result: string | ArrayBuffer, file: File) => void;
  onGenerateImage?: (values: any) => void;
  onRemoveImage: (el: Image) => void;
  isImageLoading?: boolean;
  defaultFormFields: FieldAttributes<AdditionalFieldAttributes>[];
  showPanel: boolean;
  submitForm: (values: any[], actions: any) => void;
}

const CardProductInformation = (props: Props): JSX.Element => {
  const {
    defaultFormFields,
    showPanel,
    submitForm,
    item,
    productTypology,
    serviceType,
    onAddSingleProduct,
    onRemoveSingleProduct,
    singleProducts,
    pictures,
    onAddImage,
    onGenerateImage,
    onRemoveImage,
    isImageLoading,
  } = props;

  const isDependable = isDependableTypology(productTypology);

  return (
    <div>
      <DefaultForm
        fields={defaultFormFields}
        onSubmit={submitForm}
        showPanel={showPanel}
      >
        {({ values, validateField, setFieldTouched }) => (
          <>
            {isDependable && (
              <>
                <HeaderSingleProduct
                  index={defaultFormFields.length}
                  typology={productTypology}
                  serviceType={serviceType}
                  title={`page.product.${
                    productTypology === EnumProductTypology.SERVICED_SPACE
                      ? "services"
                      : "products"
                  }.title`}
                  onAddSingleProduct={onAddSingleProduct}
                  onRemoveSingleProduct={onRemoveSingleProduct}
                  items={singleProducts}
                />
                <ListSingleProducts
                  index={defaultFormFields.length}
                  items={singleProducts}
                  onRemoveSingleProduct={onRemoveSingleProduct}
                />
              </>
            )}

            <ImageGallery
              entity={item}
              label="general.image.gallery.photos"
              index={defaultFormFields.length + 2}
              max={5}
              images={pictures}
              onAddImage={onAddImage}
              onGenerateImage={async () => {
                const areFieldsValid = await validateMultipleFields(
                  ["typology", "sku"],
                  setFieldTouched,
                  validateField
                );
                if (!areFieldsValid) return;

                if (onGenerateImage) onGenerateImage(values);
              }}
              onRemoveImage={onRemoveImage}
              isImageLoading={isImageLoading}
            />
          </>
        )}
      </DefaultForm>
    </div>
  );
};

export default CardProductInformation;
