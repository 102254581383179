import React, { useState } from "react";
import { useIntl } from "react-intl";

interface KeywordTagProps {
  name: string;
  onDelete: (name: string) => void;
}

const KeywordTag = ({ name, onDelete }: KeywordTagProps) => {
  return (
    <span className="inline-flex items-center px-2 rounded-full text-sm bg-ground-gray-500 text-gray-900">
      {name}

      <button
        type="button"
        className="p-1 ml-1 text-red-500 hover:text-red-700"
        onClick={() => onDelete(name)}
      >
        X
      </button>
    </span>
  );
};

interface Props {
  onKeywordsChange: (keywords: string[]) => void;
}

const KeywordsManager = ({ onKeywordsChange }: Props): JSX.Element => {
  const [newKeyword, setNewKeyword] = useState("");
  const [keywordList, setKeywordList] = useState<string[]>([]);

  const intl = useIntl();

  const handleAddKeyword = () => {
    const trimmedKeyword = newKeyword.trim().toLowerCase();
    if (trimmedKeyword !== "") {
      setKeywordList([...keywordList, trimmedKeyword]);
      onKeywordsChange([...keywordList, trimmedKeyword]);
      setNewKeyword("");
    }
  };

  const handleDeleteKeyword = (keyword: string) => {
    const updatedKeywordList = keywordList.filter(k => k !== keyword);
    setKeywordList(updatedKeywordList);
    onKeywordsChange(updatedKeywordList);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();

      handleAddKeyword();
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-wrap gap-2">
        {keywordList.map((tag, index) => (
          <KeywordTag key={index} name={tag} onDelete={handleDeleteKeyword} />
        ))}
      </div>

      <input
        type="text"
        value={newKeyword}
        onChange={e => setNewKeyword(e.target.value)}
        onKeyDown={handleKeyDown}
        className="p-2 mt-2 text-sm text-gray-700 border-ground-gray-500 border rounded-md outline-none"
        placeholder={intl.formatMessage({ id: "general.ai.keywords" })}
      />
    </div>
  );
};

export default KeywordsManager;
