import React, { useState } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { FieldAttributes } from "formik";
import { toast } from "react-toastify";
import { getAdminUserLocaleItems } from "../../../../utils/types";
import Block from "../../../../components/Tailwind/Block";
import Header from "../../../../components/Tailwind/Block/Header";
import Footer from "../../../../components/Tailwind/Block/Footer";
import contextStore from "../../../../redux/store";
import history from "../../../../history";
import { EnumPaths } from "../../../../utils/navigation";
import {
  EnumBackOfficeUserRole,
  EnumPermissionEntity,
} from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { Center } from "../../../../lib/ground-aws-graphql-core/models/Center";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import DefaultForm, {
  AdditionalFieldAttributes,
} from "../../../../components/Form";
import overrideClasses from "../../../../utils/overrideClasses";

interface Props {
  intl: IntlShape;
  center: Center;
}

const AdministratorCreate = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(true);

  const { intl, center } = props;

  const createAuthorizationAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.authorization.createAuthorization
  );

  const createAdminUser = GroundGraphqlContextStore.useStoreActions(
    actions => actions.backOfficeUser.createAdminUser
  );

  const createAdminUserAction = contextStore.useStoreActions(
    actions => actions.account.createAdminUser
  );

  const handleSubmit = values => {
    const valuesClone = { ...values };
    setLoading(true);
    valuesClone.enabled = enabled;

    createAdminUserAction({
      center,
      values: valuesClone,
      callbacks: {
        createUser: createAdminUser,
        createAuthorization: createAuthorizationAction,
      },
    })
      .then(() => {
        toast(
          intl.formatMessage({
            id: "page.administrator.create.administrator.success",
          }),
          { type: "success" }
        );

        const url = `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${center.id}/${EnumPaths.INFORMATIONS}`;
        history.push(url);
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "page.administrator.create.administrator.error",
          }),
          {
            type: "error",
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const localeItems = getAdminUserLocaleItems();

  const formFields: FieldAttributes<AdditionalFieldAttributes>[] = [
    {
      name: "last_name",
      label: "general.name",
      placeholder: "general.name",
      initialValue: "",
      required: true,
    },
    {
      name: "first_name",
      label: "general.firstname",
      placeholder: "general.firstname",
      initialValue: "",
      required: true,
    },
    {
      name: "email",
      label: "general.email",
      placeholder: "general.email",
      initialValue: "",
      required: true,
    },
    {
      name: "role",
      label: "general.role",
      placeholder: "general.role",
      initialValue: EnumBackOfficeUserRole.CenterAdmin,
      required: true,
      as: "select",
      disabled: true,
      hidden: true,
    },
    {
      name: "locale",
      label: "general.locale.preference",
      placeholder: "general.locale.preference",
      initialValue: "",
      required: true,
      as: "select",
      options: localeItems,
    },
  ];

  return (
    <>
      {/**
       * When we were loading the form was initialized again and we would lose all data
       * Show loading here in order to not lose the fields data after a loading
       */}
      <div className={overrideClasses({ loading })} />

      {/* Hide when loading */}
      <div className={overrideClasses({ hidden: loading })}>
        <Block>
          <Header
            item={null}
            title="page.list.administrators.create.administrator"
            entity={EnumPermissionEntity.BACK_OFFICE_USER}
            checked={enabled}
            onChange={setEnabled}
            className="border-b border-gray-200"
          />

          <DefaultForm fields={formFields} onSubmit={handleSubmit} disablePanel>
            {() => (
              <Footer
                item={null}
                index={formFields.length + 1}
                labels={["general.cancel", "general.delete"]}
              />
            )}
          </DefaultForm>
        </Block>
      </div>
    </>
  );
};
export default injectIntl(AdministratorCreate);
