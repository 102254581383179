import { ThunkCreator } from "easy-peasy";
import { FieldAttributes } from "formik";
import { htmlToText } from "html-to-text";
import React, { useEffect, useMemo, useState } from "react";
import { IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import { toast } from "react-toastify";
import DefaultForm, {
  AdditionalFieldAttributes,
} from "../../../components/Form";
import Block from "../../../components/Tailwind/Block";
import Header from "../../../components/Tailwind/Block/Header";
import Button from "../../../components/Tailwind/Button";
import ImageGallery from "../../../components/Tailwind/ImageGallery";
import history from "../../../history";
import { GroundGraphqlContextStore } from "../../../lib/ground-aws-graphql-core";
import {
  EnumNotificationType,
  EnumPermissionEntity,
} from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import { getTodayInCenterTimezone } from "../../../utils/config";
import IntlMessages from "../../../utils/messages";
import { EnumPaths } from "../../../utils/navigation";
import {
  ActionTypes,
  Image,
  getNotificationTypeItemsForm,
} from "../../../utils/types";
import DeliveryScheduleComponent, {
  DeliverySchedule,
  DeliveryScheduleOptions,
} from "./form-delivery-schedule";
import RecipientsComponent, {
  NotificationRecipients,
  RecipientTargetType,
} from "./form-recipients";

interface Props {
  notificationType?: EnumNotificationType;
  match: Match<{ cid: string; id?: string }>;
  intl: IntlShape;
}

const NotificationForm = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [pictures, setPictures] = useState<Image[]>([]);
  const { match, intl, notificationType } = props;

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const [notificationRecipients, setNotificationRecipients] =
    useState<NotificationRecipients>({
      user: null,
      allRecipients: {
        administrators: false,
        android: false,
        ios: false,
      },
      choice: RecipientTargetType.USER,
    });

  const [notificationDeliverySchedule, setNotificationDeliverySchedule] =
    useState<DeliverySchedule>({
      date: getTodayInCenterTimezone(centerTimezone),
      delivery: DeliveryScheduleOptions.IMMEDIATELY,
    });

  const getNews = GroundGraphqlContextStore.useStoreActions(
    actions => actions.news.getNews
  );
  
  const setNews = GroundGraphqlContextStore.useStoreActions(
    actions => actions.news.setNews
  );

  const news = GroundGraphqlContextStore.useStoreState(
    state => state.news.news
  );

  const getEvent = GroundGraphqlContextStore.useStoreActions(
    actions => actions.event.getEvent
  );
  
  const setEvent = GroundGraphqlContextStore.useStoreActions(
    actions => actions.event.setEvent
  );

  const event = GroundGraphqlContextStore.useStoreState(
    state => state.event.event
  );

  useEffect(() => {
    fetchData();

    return () => {
      if (event) setEvent(null);
      if (news) setNews(null);
    }
  }, []);

  const fetchData = () => {
    if (match.params.id) {
      setLoading(true);

      const promises: ThunkCreator[] = [];
      switch (notificationType) {
        case EnumNotificationType.NEWS:
          promises.push(getNews({ id: match.params.id }));
          break;
        case EnumNotificationType.EVENT:
          promises.push(getEvent({ id: match.params.id }));
          break;
        default:
          break;
      }

      Promise.all(promises).finally(() => {
        setLoading(false);
      });
    }
  };

  const handleSubmit = (values: { [key: string]: any }) => {
    setLoading(true);

    const { type, description, title } = values;

    let data;
    if (news) data = { type, itemId: news.id };
    if (event) data = { type, itemId: event.id };

    createNotification({
      building_id: match.params.cid,
      type,
      headings: JSON.parse(title),
      contents: JSON.parse(description),
      recipients: {
        administrators: notificationRecipients.allRecipients.administrators,
        android_users: notificationRecipients.allRecipients.android,
        ios_users: notificationRecipients.allRecipients.ios,
        ...(notificationRecipients.user && {
          user_ids: [notificationRecipients.user.id],
        }),
      },
      ...(notificationDeliverySchedule.delivery ===
        DeliveryScheduleOptions.SPECIFIC_TIME && {
        send_after: notificationDeliverySchedule.date.toISOString(),
      }),
      pictures,
      data,
    })
      .then(() => {
        history.push(
          `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${match.params.cid}/${EnumPaths.NOTIFICATIONS}`
        );
        toast(
          intl.formatMessage({
            id: "page.notification.create.notification.success",
          }),
          { type: "success" }
        );
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "page.notification.create.notification.error",
          }),
          {
            type: "error",
          }
        );
      })
      .finally(() => setLoading(false));
  };

  const createNotification = GroundGraphqlContextStore.useStoreActions(
    actions => actions.notification.createNotification
  );

  const notificationTypeItemsForm = getNotificationTypeItemsForm(intl);

  // return true si user is selected
  const userOk =
    notificationRecipients.choice === RecipientTargetType.USER &&
    notificationRecipients.user &&
    notificationRecipients.user.id;

  // return true si one of the checkbox is checked
  const allUsersOk =
    notificationRecipients.choice === RecipientTargetType.ALL &&
    notificationRecipients.allRecipients &&
    Object.values(notificationRecipients.allRecipients).includes(true);

  // recipient is set
  const requiredOk = userOk || allUsersOk;

  const recipientsField = useMemo(
    () => ({
      name: "recipients",
      label: "page.notification.recipients",
      placeholder: "page.notification.recipients",
      initialValue: undefined,
      required: true,
      validate: () => {
        if (!requiredOk) return "general.required.field";

        return "";
      },
      component: () => (
        <RecipientsComponent
          notificationRecipients={notificationRecipients}
          onChange={data => {
            setNotificationRecipients(data);
          }}
        />
      ),
    }),
    [notificationRecipients]
  );

  const deliveryImmediatelyOk =
    notificationDeliverySchedule.delivery ===
    DeliveryScheduleOptions.IMMEDIATELY;

  const deliverySpecificTimeOk =
    notificationDeliverySchedule.delivery ===
      DeliveryScheduleOptions.SPECIFIC_TIME &&
    notificationDeliverySchedule.date;

  // delivery schedule is set
  const deliveryOk = deliveryImmediatelyOk || deliverySpecificTimeOk;

  const deliveryScheduleField = useMemo(
    () => ({
      name: "delivery_schedule",
      label: "page.notification.delivery.schedule",
      placeholder: "page.notification.delivery.schedule",
      initialValue: undefined,
      required: true,
      validate: () => {
        if (!deliveryOk) return "general.required.field";

        return "";
      },
      component: () => (
        <DeliveryScheduleComponent
          deliverySchedule={notificationDeliverySchedule}
          onChange={data => {
            setNotificationDeliverySchedule(data);
          }}
        />
      ),
    }),
    [notificationDeliverySchedule]
  );

  const type = notificationType
    ? notificationTypeItemsForm?.find(e => e.value === notificationType)
    : null;

  const getInitialValues = (): { title: string; description: string } => {
    const values = { title: "", description: "" };
    switch (notificationType) {
      case EnumNotificationType.NEWS:
        values.title = news?.title;
        values.description = convertToPlainText(news?.description);
        break;
      case EnumNotificationType.EVENT:
        values.title = event?.title;
        values.description = convertToPlainText(event?.description);
        break;
      default:
        break;
    }

    return values;
  };

  const handleAddPicture = (picture: string | ArrayBuffer, file: File) => {
    const items = [...pictures];
    items.push({
      picture,
      source: true,
      action: ActionTypes.TO_ADD,
      file,
    });

    setPictures(items);
  };

  const handleRemovePicture = (el: Image) => {
    const items = pictures.filter(e => e.picture !== el.picture);
    if (el.source) {
      setPictures(items);
    } else {
      const picturesToDelete = [...items];
      picturesToDelete.push({
        ...el,
        action: ActionTypes.TO_DELETE,
      });
      setPictures(picturesToDelete);
    }
  };

  const convertToPlainText = (description: string) => {
    if (!description) {
      return "";
    }
    const desc = JSON.parse(description);
    const value = Object.keys(desc).reduce(
      (
        acc: {
          [key: string]: string;
        },
        key
      ) => {
        acc[key] = htmlToText(desc[key]);

        return acc;
      },
      {}
    );

    return JSON.stringify(value);
  };

  const formFields: FieldAttributes<AdditionalFieldAttributes>[] = [
    {
      name: "title",
      label: "general.title",
      placeholder: "general.title",
      initialValue: getInitialValues().title,
      required: true,
      translatable: true,
    },
    {
      name: "description",
      label: "general.description",
      placeholder: "general.description",
      initialValue: getInitialValues().description,
      required: true,
      translatable: true,
      as: "textarea",
    },
    {
      name: "type",
      label: "general.type",
      placeholder: "general.type",
      initialValue: type?.value,
      value: type?.value,
      disabled: !!notificationType,
      required: true,
      as: "select",
      options: notificationTypeItemsForm,
    },
    recipientsField,
    deliveryScheduleField,
  ];

  const labels = {
    creation: "page.notification.create.notification",
  };

  return loading ? (
    <div className="loading" />
  ) : (
    <div className="px-8">
      <Block>
        <Header
          item={null}
          title={labels.creation}
          entity={EnumPermissionEntity.NEWS}
          className="border-b border-gray-200"
        />

        <DefaultForm fields={formFields} onSubmit={handleSubmit} disablePanel>
          {() => (
            <>
              <ImageGallery
                entity={null}
                label="general.image.gallery.photos"
                index={formFields.length}
                max={1}
                images={pictures}
                onAddImage={handleAddPicture}
                onRemoveImage={handleRemovePicture}
              />
              <div className="flex justify-center px-2 py-6">
                <Button
                  id="btn-send-notification"
                  name="btn-send-notification"
                  item={null}
                  type="submit"
                  disabled={loading}
                >
                  <span className="text-center">
                    <IntlMessages id="general.submit" />
                  </span>
                </Button>
              </div>
            </>
          )}
        </DefaultForm>
      </Block>
    </div>
  );
};

export default NotificationForm;
