import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Location } from "history";
import { injectIntl, IntlShape } from "react-intl";
import { toast } from "react-toastify";
import { TableChangeParams } from "../../../components/Table/types";
import { GroundGraphqlContextStore } from "../../../lib/ground-aws-graphql-core";
import PageSubheader from "../../../components/PageSubheader";
import { EnumPaths } from "../../../utils/navigation";
import history from "../../../history";
import Table from "../../../components/Table";
import Dropdown, {
  DropdownValues,
} from "../../../components/Tailwind/Dropdown";
import images from "../../../images";
import Badge from "../../../components/Tailwind/Badge";
import { getTranslation } from "../../../utils/translation";
import ConfirmModal from "../../../utils/modal/confirm";
import IntlMessages from "../../../utils/messages";
import { displayDayDDMMYYYY_HHMM } from "../../../utils/config";
import { PriceUnitVariation } from "../../../lib/ground-aws-graphql-core/models/Api/PriceUnitVariations";

interface Props {
  intl: IntlShape;
  location: Location<{
    pageIndex: number;
    limit: number;
    type: string;
  }>;
}

const DEFAULT_LIMIT = 20;

const ListPriceUnitVariations = (props: Props): JSX.Element => {
  const { intl, location } = props;
  const [loading, setLoading] = useState(false);

  const [
    showDeletePriceUnitVariationModal,
    setShowDeletePriceUnitVariationModal,
  ] = useState(false);

  const [priceUnitVariationId, setPriceUnitVariationId] = useState<string>();
  const [initialParams, setInitialParams] = useState<TableChangeParams | null>(
    location?.state
  );

  const listPriceUnitVariations = GroundGraphqlContextStore.useStoreActions(
    actions => actions.priceUnitVariations.listPriceUnitVariations
  );

  const priceUnitVariations = GroundGraphqlContextStore.useStoreState(
    state => state.priceUnitVariations.priceUnitVariations.items
  );

  const total = GroundGraphqlContextStore.useStoreState(
    state => state.priceUnitVariations.priceUnitVariations.total
  );

  const setPriceUnitVariation = GroundGraphqlContextStore.useStoreActions(
    actions => actions.priceUnitVariations.setPriceUnitVariation
  );

  const deletePriceUnitVariation = GroundGraphqlContextStore.useStoreActions(
    actions => actions.priceUnitVariations.deletePriceUnitVariation
  );

  const setAsDefault = GroundGraphqlContextStore.useStoreActions(
    actions => actions.priceUnitVariations.setAsDefault
  );

  useEffect(() => {
    if (location?.state) {
      fetchData(location?.state?.pageIndex, true, location?.state?.limit);
    } else fetchData();
  }, []);

  const fetchData = (pageIndex = 0, loader = true, limit = DEFAULT_LIMIT) => {
    if (loader) setLoading(true);

    listPriceUnitVariations({
      offset: pageIndex * limit,
      limit,
    }).finally(() => setLoading(false));
  };

  const addNewPriceUnitVariation = () => {
    history.push(
      `/${EnumPaths.ROOT}/${EnumPaths.PRICE_UNIT_VARIATIONS}/${EnumPaths.CREATION_MODE}`
    );
  };

  const handleOnChange = (changeParams: TableChangeParams) => {
    const { pageIndex, limit } = changeParams;

    setInitialParams(changeParams);

    fetchData(pageIndex, false, limit);
  };

  const handleEditPriceUnitVariation = (id: string) => {
    const editLink = `/${EnumPaths.ROOT}/${EnumPaths.PRICE_UNIT_VARIATIONS}/${id}/${EnumPaths.EDIT_MODE}`;
    setPriceUnitVariation(null);
    history.push(editLink);
  };

  const handleDelete = () => {
    setLoading(true);
    setShowDeletePriceUnitVariationModal(false);
    deletePriceUnitVariation({ id: priceUnitVariationId! })
      .then(() => {
        toast(
          intl.formatMessage({
            id: "page.price.unit.variation.delete.success",
          }),
          { type: "success" }
        );
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "page.price.unit.variation.delete.error",
          }),
          {
            type: "error",
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSetAsDefault = (id: string) => {
    setLoading(true);
    setAsDefault({ id })
      .then(() => {
        toast(
          intl.formatMessage({
            id: "page.price.unit.variation.as.default.success",
          }),
          { type: "success" }
        );
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "page.price.unit.variation.as.default.error",
          }),
          {
            type: "error",
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const tableHead = [
    "page.price.unit.variation.label",
    "page.price.unit.variation.code",
    "general.status",
    "page.price.unit.variation.is_default",
    "page.price.unit.variation.created_at",
    "page.price.unit.variation.updated_at",
    "general.actions",
  ];

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const tableBody = priceUnitVariations?.map(
    (priceUnitVariation: PriceUnitVariation) => {
      const { id, code, label, enabled, is_default, created_at, updated_at } =
        priceUnitVariation;
      const editLink = `/${EnumPaths.ROOT}/${EnumPaths.PRICE_UNIT_VARIATIONS}/${id}/${EnumPaths.EDIT_MODE}`;

      const dropdownValues: DropdownValues[] = [
        {
          id: "edit_price_unit_variation",
          label: "page.list.price.unit.variations.edit",
          icon: images.edit,
          link: editLink,
        },
        {
          id: "set_as_default",
          label: "page.list.price.unit.variations.set_as_default",
          icon: images.edit,
          onClick: () => {
            handleSetAsDefault(id);
          },
        },
        {
          id: "delete",
          label: "general.delete",
          icon: images.deleteIcon,
          onClick: () => {
            setShowDeletePriceUnitVariationModal(
              !showDeletePriceUnitVariationModal
            );
            setPriceUnitVariationId(id);
          },
        },
      ];

      const elements = [
        {
          element: getTranslation(label),
          onCellClick: () => handleEditPriceUnitVariation(id),
        },
        {
          element: code,
          onCellClick: () => handleEditPriceUnitVariation(id),
        },
        {
          element: enabled ? (
            <Badge label="general.actif" bgClassName="bg-ground-green-100" />
          ) : (
            <Badge label="general.inactif" bgClassName="bg-red-500" />
          ),
          onCellClick: () => handleEditPriceUnitVariation(id),
        },
        {
          element: is_default ? (
            <Badge label="general.yes" bgClassName="bg-ground-green-100" />
          ) : (
            <Badge label="general.no" bgClassName="bg-red-500" />
          ),
          onCellClick: () => handleEditPriceUnitVariation(id),
        },
        {
          element: displayDayDDMMYYYY_HHMM(created_at, centerTimezone),
          onCellClick: () => handleEditPriceUnitVariation(id),
        },
        {
          element: displayDayDDMMYYYY_HHMM(updated_at, centerTimezone),
          onCellClick: () => handleEditPriceUnitVariation(id),
        },
        {
          element: (
            <Dropdown
              values={dropdownValues}
              dataCy={`price-unit-variation-dropdown-${id}`}
            />
          ),
        },
      ];

      return {
        rowElements: elements,
      };
    }
  );

  return (
    <div>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: `page.list.price.unit.variations.document.title`,
          })}
        </title>
      </Helmet>

      <PageSubheader
        title="page.list.price.unit.variations.title"
        nbOfResults={total}
        buttonRightTitle="page.list.price.unit.variations.create.price.unit.variation"
        buttonRightAction={addNewPriceUnitVariation}
        buttonRightId="btn-create-price-unit-variation"
      />

      <Table
        initialParams={initialParams}
        head={tableHead}
        body={tableBody}
        noDataText="page.list.price.unit.variations.empty"
        onChange={handleOnChange}
        paginationLimit={DEFAULT_LIMIT}
        paginationTotal={total}
        loading={loading}
        setLoading={setLoading}
        className="px-8"
      />

      <ConfirmModal
        isOpen={showDeletePriceUnitVariationModal}
        onRequestClose={() =>
          setShowDeletePriceUnitVariationModal(
            !showDeletePriceUnitVariationModal
          )
        }
        toggle={() =>
          setShowDeletePriceUnitVariationModal(
            !showDeletePriceUnitVariationModal
          )
        }
        handleConfirm={handleDelete}
        content={
          <IntlMessages id="page.list.price.unit.variations.delete.price.unit.variation" />
        }
      />
    </div>
  );
};

export default injectIntl(ListPriceUnitVariations);
