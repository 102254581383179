import React, { useState, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { match as Match, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { injectIntl, IntlShape } from "react-intl";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { FieldAttributes } from "formik";
import IntlMessages from "../../../../utils/messages";
import { getTranslation } from "../../../../utils/translation";
import Button from "../../../../components/Tailwind/Button";
import Event from "../../../../utils/tracking-event";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import {
  EnumBookingReason,
  EnumUnit,
} from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import PageSubheader from "../../../../components/PageSubheader";
import Block from "../../../../components/Tailwind/Block";
import SpaceTimeFrame from "../../../../components/SpaceTimeFrame";
import { getCenterGql, getProductGql } from "../../../../utils/gql/gql";
import { EnumBookingFrom, EnumPaths } from "../../../../utils/navigation";
import history from "../../../../history";
import DefaultForm, {
  AdditionalFieldAttributes,
} from "../../../../components/Form";
import FormTime from "../../../../components/Form/FormTime";
import FormDependentField from "../../../../components/Form/FormDependentField";
import UserComponent, { UserFormData, UserType } from "./form-user-component";
import { getSpaceOpeningHours } from "../../../../components/TimeFrame";
import overrideClasses from "../../../../utils/overrideClasses";
import { getPaymentMethodsOptions } from "../../../../utils/types";
import { BookingFormData } from "../../../../lib/ground-aws-graphql-core/models/Api/Cart";
import {
  BookingStatus,
  bookingStatusEnum,
} from "../../../../enums/BookingStatusEnum";
import images from "../../../../images";
import { Center } from "../../../../lib/ground-aws-graphql-core/models/Center";
import { SpaceTimeline } from "../../../../lib/ground-aws-graphql-core/models/Product";
import {
  displayDayDDMMYYYY,
  getBookingPriceUnitsInBO,
  getTodayInCenterTimezone,
  getTotalTime,
  isBefore,
  startOfDay,
  transformDateForQuery,
} from "../../../../utils/config";
import { messageWithDefault } from "../../../../utils/messages/general";

interface Props {
  match: Match<{ cid: string }>;
  intl: IntlShape;
  backURL: string;
}

export enum EnumHalfDay {
  MORNING = "morning",
  AFTERNOON = "afternoon",
}

const BookingCreate = (props: Props) => {
  const { intl, backURL } = props;

  const location = useLocation<{ date?: string; from?: EnumBookingFrom }>();
  const { state } = location;

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const today = getTodayInCenterTimezone(centerTimezone, true);

  const date = state?.date ? new Date(state.date) : today;
  const from = state?.from || EnumBookingFrom.BOOKING_LIST;
  const { cid, pid } = useParams<{ cid: string; pid: string }>();

  const [loading, setLoading] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");

  const [userFormData, setUserFormData] = useState<UserFormData | null>(null);
  const [showPaymentMethods, setShowPaymentMethods] = useState(false);

  const listUserPaymentMethods = GroundGraphqlContextStore.useStoreActions(
    actions => actions.paymentMethods.listUserPaymentMethods
  );

  const listPaymentMethods = GroundGraphqlContextStore.useStoreActions(
    actions => actions.paymentMethods.listPaymentMethods
  );

  const setUserPaymentMethods = GroundGraphqlContextStore.useStoreActions(
    actions => actions.paymentMethods.setUserPaymentMethods
  );

  const userPaymentMethods = GroundGraphqlContextStore.useStoreState(
    s => s.paymentMethods.userPaymentMethods
  );

  const paymentMethods = GroundGraphqlContextStore.useStoreState(
    s => s.paymentMethods.paymentMethods
  );

  const bookingForm = GroundGraphqlContextStore.useStoreState(
    s => s.cart.bookingForm
  );

  const setBookingForm = GroundGraphqlContextStore.useStoreActions(
    actions => actions.cart.setBookingForm
  );

  const cart = GroundGraphqlContextStore.useStoreState(s => s.cart.cart);

  const deleteCart = GroundGraphqlContextStore.useStoreActions(
    actions => actions.cart.deleteCart
  );

  const createCart = GroundGraphqlContextStore.useStoreActions(
    actions => actions.cart.createCart
  );

  const finalizeCart = GroundGraphqlContextStore.useStoreActions(
    actions => actions.cart.finalizeCart
  );

  const getProduct = GroundGraphqlContextStore.useStoreActions(
    actions => actions.product.getProduct
  );

  const searchProductOptions = GroundGraphqlContextStore.useStoreActions(
    actions => actions.product.searchProductOptions
  );

  const product = GroundGraphqlContextStore.useStoreState(
    s => s.product.product
  );

  const getSpaceTimeline = GroundGraphqlContextStore.useStoreActions(
    actions => actions.product.getSpaceTimeline
  );

  const spaceTimeline = GroundGraphqlContextStore.useStoreState(
    s => s.product.spaceTimeline
  );

  const getCenter = GroundGraphqlContextStore.useStoreActions(
    actions => actions.center.getCenter
  );

  const center = GroundGraphqlContextStore.useStoreState(s => s.center.center);

  const start = startOfDay(date);
  const selectedDate = transformDateForQuery(start, centerTimezone);

  useEffect(() => {
    getCenter({ id: cid, gql: getCenterGql });
    getProduct({ id: pid, gql: getProductGql });
    getSpaceTimeline({
      id: pid,
      date: selectedDate, // 2024-01-18
    });

    if (bookingForm) {
      setSelectedSlot(bookingForm.selectedSlot);
      setSelectedUnit(bookingForm.selectedUnit);
      setUserFormData(bookingForm.userFormData);
      setShowPaymentMethods(bookingForm.showPaymentMethods);
    }
  }, []);

  const doesUserExist = !!(
    (userFormData?.choice === UserType.USER && userFormData?.user?.id) ||
    (userFormData?.choice === UserType.ACCOUNT_LESS &&
      userFormData?.accountlessUser?.firstname &&
      userFormData?.accountlessUser?.lastname &&
      userFormData?.accountlessUser?.email)
  );

  const handleSubmit = async (values: any) => {
    const { reason, time, participants, priceUnitId, comment, paymentMethod } =
      values;

    if (
      doesUserExist &&
      reason &&
      time.end &&
      time.start &&
      selectedSlot &&
      selectedUnit &&
      priceUnitId
    ) {
      const selectedPriceUnit = priceUnits?.find(p => p.id === priceUnitId);

      if (product && selectedPriceUnit) {
        try {
          const bookingFormValues: BookingFormData = {
            ...values,
            selectedSlot,
            selectedUnit,
            selectedPriceUnitId: priceUnitId,
            userFormData,
            showPaymentMethods,
          };

          // Deep equal for objects needed, hence the usage of lodash's isEqual
          const isFormUnchanged = _.isEqual(bookingFormValues, bookingForm);

          if (bookingForm && !isFormUnchanged && cart)
            await deleteCart({ id: cart.id });

          if (bookingForm && isFormUnchanged && cart) {
            // Go to selecting options screen
            history.push(
              `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${cid}/${EnumPaths.CARTS}/${cart.id}/${EnumPaths.OPTIONS}`,
              {
                productId: pid,
                selectedPriceUnit,
                selectedPaymentMethod: paymentMethod,
                userId: userFormData?.user?.id,
                comment,
                from,
                date,
              }
            );

            return;
          }

          setBookingForm(bookingFormValues);
          setLoading(true);

          const [productOptionsResponse, createCartResponse] =
            await Promise.all([
              searchProductOptions({
                buildingId: cid,
                productId: pid,
                priceUnitVariationId: selectedPriceUnit.variation.id,
              }),
              createCart({
                products: [
                  {
                    id: product.id,
                    booking: {
                      total_participants: participants || 1,
                      start_date: time.start.toISOString(),
                      end_date: time.end.toISOString(),
                    },
                    price_unit: { id: selectedPriceUnit.id },
                  },
                ],
                user:
                  userFormData.choice === UserType.USER
                    ? { id: userFormData?.user?.id }
                    : {
                        id: userFormData?.accountlessUser?.id,
                        first_name: userFormData?.accountlessUser?.firstname,
                        last_name: userFormData?.accountlessUser?.lastname,
                        email: userFormData?.accountlessUser?.email,
                      },
              }),
            ]);

          if (!createCartResponse.success)
            throw new Error(createCartResponse.error_code);

          if (
            productOptionsResponse.filter(
              (option: any) => option.price_units.items.length > 0
            ).length > 0
          ) {
            // Go to selecting options screen
            history.push(
              `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${cid}/${EnumPaths.CARTS}/${createCartResponse.data.id}/${EnumPaths.OPTIONS}`,
              {
                productId: pid,
                selectedPriceUnit,
                selectedPaymentMethod: paymentMethod,
                userId: userFormData?.user?.id,
                comment,
                from,
                date,
              }
            );
          } else {
            const finalizeCartResponse = await finalizeCart({
              cartId: createCartResponse.data.id,
              comment,
              payment_method: paymentMethod,
            });

            if (!finalizeCartResponse.success)
              throw new Error(finalizeCartResponse.error_code);

            toast(
              intl.formatMessage({ id: "page.booking.create.booking.success" }),
              {
                type: "success",
              }
            );

            if (process.env.REACT_APP_EVENT_NAME_BOOKING_CREATED) {
              Event(
                "bookings",
                process.env.REACT_APP_EVENT_NAME_BOOKING_CREATED
              );
            }

            if (from === EnumBookingFrom.CALENDAR) {
              // go to calendar
              history.push(
                `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${cid}/${EnumPaths.CALENDAR}`
              );
            } else if (from === EnumBookingFrom.BOOKING_LIST) {
              // go to booking list
              history.push(
                `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${cid}/${EnumPaths.BOOKINGS}`,
                { date }
              );
            }

            setLoading(false);
          }
        } catch (error: any) {
          toast(
            intl.formatMessage({
              id: messageWithDefault(
                intl,
                `page.booking.create.booking.${error.message}`,
                "page.booking.create.booking.error"
              ),
            }),
            {
              type: "error",
            }
          );

          if (process.env.REACT_APP_EVENT_NAME_BOOKING_FAILED) {
            Event(
              "bookings",
              process.env.REACT_APP_EVENT_NAME_BOOKING_FAILED,
              intl.messages[`page.booking.create.booking.${error.message}`]
                ? { error_code: error.message }
                : undefined
            );
          }

          setLoading(false);
        }
      }
    }
  };

  const priceUnits = product?.priceUnits?.items;
  const priceUnitsByUnit = _.groupBy(priceUnits, "unit");

  // We need to sort the keys in this order : DAY, HALF_DAY, HOUR, and HALF_HOUR
  // Fortunately we can just use the alphabetic order but we might need a more complex sorting method if we add some other units
  const units = Object.keys(priceUnitsByUnit).sort();
  const bookingUnits: EnumUnit[] = [
    ...new Set(
      units.map(p =>
        [EnumUnit.HALF_HOUR, EnumUnit.HOUR].includes(EnumUnit[p])
          ? EnumUnit.HOUR
          : EnumUnit[p]
      )
    ),
  ];

  const timeSlotsOptions = bookingUnits.map(unit => ({
    unit,
    options:
      unit === EnumUnit.HOUR
        ? units.filter(u =>
            [EnumUnit.HALF_HOUR, EnumUnit.HOUR].includes(EnumUnit[u])
          )
        : units.filter(u => unit === EnumUnit[u]),
  }));

  const formUnitPriceVariations = selectedUnit
    ? timeSlotsOptions
        .find(u => u.unit === EnumUnit[selectedUnit])
        ?.options?.reduce(
          (
            acc: {
              value: string;
              label: string;
            }[],
            option
          ) => {
            const priceUnitsForUnit = priceUnitsByUnit[option];
            if (getBookingPriceUnitsInBO().includes(EnumUnit[option])) {
              priceUnitsForUnit.forEach(priceUnitForUnit => {
                acc.push({
                  value: priceUnitForUnit.id,
                  label: intl.formatMessage(
                    {
                      id: `page.booking.create.booking.unit.variation.${priceUnitForUnit.currency}`,
                    },
                    {
                      variationName: getTranslation(
                        priceUnitForUnit.variation.name
                      ),
                      price: priceUnitForUnit.price,
                      unit: intl.formatMessage({
                        id: `page.booking.create.booking.unit.${priceUnitForUnit.unit.toLowerCase()}`,
                      }),
                    }
                  ),
                });
              });
            }

            return acc;
          },
          []
        )
    : [];

  useEffect(() => {
    if (userFormData && showPaymentMethods) {
      const { choice } = userFormData;
      if (choice === UserType.ACCOUNT_LESS) {
        listPaymentMethods({ excludedFromBackOffice: false });
      } else if (userFormData.user?.id) {
        const params = {
          userId: userFormData.user.id,
          excludedFromBackOffice: false,
        };
        listUserPaymentMethods(params);
      }
    }
  }, [userFormData?.choice, userFormData?.user?.id, showPaymentMethods]);

  const paymentMethodsOptions = getPaymentMethodsOptions(
    userFormData?.choice === UserType.ACCOUNT_LESS
      ? paymentMethods.items
      : userPaymentMethods.items
  );

  const renderLegendItem = (bookingStatusItem: BookingStatus) => {
    const { status, cellBgColor } = bookingStatusItem;

    return cellBgColor ? (
      <div key={`status_${status}`} className="flex items-center">
        <div className={`inline-block ${cellBgColor} w-3 h-3 mr-2`} />
        <span className="text-black text-12px leading-normal">
          <IntlMessages id={`page.calendar.legend.${status.toLowerCase()}`} />
        </span>
      </div>
    ) : null;
  };

  const renderSpace = (center: Center, space: SpaceTimeline) => {
    const timelineDate = space.timeline_dates.find(
      e => e.formatted_date === selectedDate
    );

    return (
      <Block className="flex px-8 py-4 border border-neutral-100 shadow-ground-1">
        <SpaceTimeFrame {...props} space={space} timelineDate={timelineDate} />
      </Block>
    );
  };

  const timelineDate = center
    ? spaceTimeline?.timeline_dates.find(e => e.formatted_date === selectedDate)
    : null;

  const { timeInterval, closingHours, openingHours } =
    getSpaceOpeningHours(timelineDate);

  const timeField = useMemo(
    () => ({
      name: "time",
      label: "page.booking.create.booking.slot",
      initialValue: bookingForm?.time,
      required: true,
      onChange: ({ target: { value } }) => {
        // value is DAY, afternoon, morning, HOUR
        setSelectedSlot(value);
        const v = [EnumHalfDay.MORNING, EnumHalfDay.AFTERNOON].includes(value)
          ? EnumUnit.HALF_DAY
          : value;
        setSelectedUnit(v); // DAY, HALF_DAY, HOUR
      },
      validate: (timeValues: { start: Date; end: Date }) => {
        if (timeValues?.start && timeValues?.end) {
          if (isBefore(timeValues.end, timeValues.start))
            return "page.booking.create.booking.endDateAfterStartDateException";

          return "";
        }

        return "general.required.field";
      },
      component: (props: any) => (
        <FormTime
          {...props}
          timeSlotsOptions={timeSlotsOptions}
          date={date}
          minTime={timeInterval?.start}
          maxTime={timeInterval?.end}
          closingHours={closingHours}
          openingHours={openingHours}
          selectedSlot={selectedSlot}
        />
      ),
    }),
    [selectedSlot, spaceTimeline, product, bookingForm?.time]
  );

  const userField = useMemo(
    () => ({
      name: "user",
      label: "general.occupant",
      placeholder: "general.occupant",
      initialValue: undefined,
      required: true,
      validate: () => {
        if (!doesUserExist) return "general.required.field";

        return "";
      },
      component: () => (
        <UserComponent
          userFormData={userFormData}
          onChange={ufd => {
            // init payment methods to empty when changing user mode or removing user
            if (
              !ufd ||
              (ufd.choice === UserType.USER && !ufd.user?.id) ||
              ufd.choice === UserType.ACCOUNT_LESS
            ) {
              setUserPaymentMethods({ items: [], total: 0, has_more: false });
              if (ufd?.choice === UserType.ACCOUNT_LESS) {
                setUserFormData({
                  accountlessUser: ufd.accountlessUser,
                  choice: ufd.choice,
                });
              } else {
                setUserFormData(ufd);
              }
            } else {
              setUserFormData(ufd);
            }
          }}
        />
      ),
    }),
    [userFormData, userPaymentMethods, paymentMethods]
  );

  const formFields: FieldAttributes<AdditionalFieldAttributes>[] = useMemo(
    () => [
      {
        name: "date",
        label: "general.date",
        placeholder: "general.date",
        initialValue:
          bookingForm?.date || displayDayDDMMYYYY(date, centerTimezone),
        required: true,
        disabled: true,
      },
      timeField,
      {
        name: "priceUnitId",
        label: "page.booking.create.booking.unit.price",
        initialValue: bookingForm?.selectedPriceUnitId,
        required: true,
        as: "select",
        options: formUnitPriceVariations,
        disabled: !selectedUnit,
      },
      {
        name: "participants",
        label: "page.booking.create.booking.participants.number",
        placeholder: "page.booking.create.booking.participants.number",
        initialValue: bookingForm?.participants,
        required: true,
        type: "number",
        min: 1,
        max: product?.capacity,
      },
      {
        name: "totalPrice",
        label: "general.total.price",
        placeholder: "general.total.price",
        initialValue: bookingForm?.totalPrice ?? "",
        required: false,
        disabled: true,
        component: (props: any) => (
          <FormDependentField
            {...props}
            onDepend={(formValues, touched, setFieldValue) => {
              useEffect(() => {
                if (
                  formValues.time?.start &&
                  formValues.time?.end &&
                  formValues.priceUnitId &&
                  formValues.participants &&
                  selectedUnit
                ) {
                  const selectedPriceUnit = priceUnits?.find(
                    p => p.id === formValues.priceUnitId
                  );
                  const { start, end } = formValues.time;

                  const totalTime = getTotalTime(
                    start,
                    end,
                    selectedPriceUnit?.unit
                  );

                  const totalPrice =
                    selectedPriceUnit &&
                    selectedPriceUnit.price *
                      (product?.flexible ? formValues.participants : 1) *
                      totalTime;

                  setShowPaymentMethods(!!(totalPrice && totalPrice > 0));

                  setFieldValue(
                    "totalPrice",
                    intl.formatMessage(
                      { id: "page.booking.create.booking.total.price" },
                      { price: totalPrice }
                    )
                  );
                }
              }, [
                formValues.time,
                formValues.priceUnitId,
                formValues.participants,
                touched.time,
                touched.priceUnitId,
                touched.participants,
              ]);
            }}
          />
        ),
      },
      {
        name: "reason",
        label: "general.reason",
        placeholder: "general.reason",
        initialValue: bookingForm?.reason,
        required: true,
        as: "select",
        options: [
          {
            value: EnumBookingReason.ONSITE,
            label: intl.formatMessage({
              id: "page.list.bookings.reason.ONSITE",
            }),
          },
          {
            value: EnumBookingReason.MAINTENANCE,
            label: intl.formatMessage({
              id: "page.list.bookings.reason.MAINTENANCE",
            }),
          },
          {
            value: EnumBookingReason.OTHER,
            label: intl.formatMessage({
              id: "page.list.bookings.reason.OTHER",
            }),
          },
        ],
      },
      userField,
      {
        name: "paymentMethod",
        label: "general.payment_method",
        placeholder: "general.payment_method",
        initialValue: bookingForm?.paymentMethod,
        hidden: !showPaymentMethods,
        required: true,
        as: "select",
        options: paymentMethodsOptions,
        validate: paymentMethodValue => {
          if (
            !paymentMethodsOptions.some(pm => pm.value === paymentMethodValue)
          )
            return "general.required.field";

          return "";
        },
      },
      {
        name: "comment",
        label: "general.comment",
        placeholder: "general.comment",
        initialValue: bookingForm?.comment,
        required: false,
        as: "textarea",
      },
    ],
    [
      timeField,
      userField,
      paymentMethodsOptions,
      showPaymentMethods,
      product,
      bookingForm,
    ]
  );

  return !center || !product || !spaceTimeline ? (
    <div className="loading" />
  ) : (
    <>
      {/**
       * When we were loading the form was initialized again and we would lose all data
       * Show loading here in order to not lose the fields data after a loading
       */}
      <div className={overrideClasses({ loading })} />

      {/* Hide when loading */}
      <div className={overrideClasses({ hidden: loading })}>
        <Helmet>
          <title>
            {intl.formatMessage({
              id: "page.booking.create.document.title",
            })}
          </title>
        </Helmet>

        <PageSubheader
          goBackEnabled
          goBackId="btn-back-book"
          goBackURL={backURL}
          location={location}
        />

        <div className="px-8">
          {renderSpace(center, spaceTimeline)}

          <div className="flex items-center gap-5 mt-5">
            {bookingStatusEnum.map(renderLegendItem)}

            <div className="flex items-center">
              <img
                src={images.combined}
                alt="combined"
                className="w-3 h-3 mr-2"
              />
              <span className="text-black text-12px leading-normal">
                <IntlMessages id="page.calendar.legend.combined" />
              </span>
            </div>
          </div>

          <Block>
            <span className="h-16 px-8 py-2 flex items-center text-17px leading-5 text-neutral-900">
              <IntlMessages id="page.list.bookings.book.create" />
            </span>

            <DefaultForm
              fields={formFields}
              onSubmit={handleSubmit}
              disablePanel
            >
              {() => (
                <div className="flex justify-center px-2 py-6">
                  <Button
                    id="btn-book"
                    name="btn-book"
                    item={null}
                    type="submit"
                    disabled={loading}
                  >
                    <span className="text-center">
                      <IntlMessages id="general.submit" />
                    </span>
                  </Button>
                </div>
              )}
            </DefaultForm>
          </Block>
        </div>
      </div>
    </>
  );
};

export default injectIntl(BookingCreate);
