import React from "react";
import AIText from "../AIText";
import IntlMessages from "../../utils/messages";
import KeywordsManager from "../Keywords";
import Button from "../Tailwind/Button";

interface Props {
  isDescriptionEmpty: boolean;
  setDescriptionKeywords: (keywords: string[]) => void;
  disabled: boolean;
  onClick: () => void;
  isDescriptionLoading: boolean;
}

const DescriptionGenerator: React.FC<Props> = ({
  isDescriptionEmpty,
  setDescriptionKeywords,
  disabled,
  onClick,
  isDescriptionLoading,
}) => {
  return (
    <div className="flex flex-col h-full w-full justify-between">
      {isDescriptionLoading ? (
        <AIText message="general.ai.loading" className="flex-1" />
      ) : (
        <>
          <AIText message="general.ai.help" />

          {isDescriptionEmpty && (
            <div className="mt-2">
              <KeywordsManager onKeywordsChange={setDescriptionKeywords} />
            </div>
          )}

          <Button
            id="btn-generate-description"
            name="btn-generate-description"
            data-cy="btn-generate-description"
            item={null}
            type="button"
            outline
            disabled={disabled}
            onClick={onClick}
          >
            <IntlMessages
              id={
                isDescriptionEmpty
                  ? "general.ai.generate.description"
                  : "general.ai.translate.text"
              }
            />
          </Button>
        </>
      )}
    </div>
  );
};

export default DescriptionGenerator;
