/*
 *
 * PriceUnitVariation Service
 *
 */
import { gql } from "apollo-boost";
import { ApolloClient } from "../../store/provider";
import { searchAllPriceUnitVariations as searchAllPriceUnitVariationsQuery } from "../../api/graphql/queries";
import { ModelPriceUnitVariationConnection } from "../../models/PriceUnitVariation";

const DEFAULT_LIMIT = 100;

export const searchAllPriceUnitVariations = payload => {
  const request = payload.gql ? payload.gql : searchAllPriceUnitVariationsQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: {
      filter: payload ? (payload.filter ? payload.filter : null) : null,
      limit: payload?.limit || DEFAULT_LIMIT,
      from: payload ? (payload.from ? payload.from : 0) : 0,
    },
  }) as {
    data: {
      searchAllPriceUnitVariations: ModelPriceUnitVariationConnection;
    };
  };
};
