import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Location } from "history";
import { injectIntl, IntlShape } from "react-intl";
import { toast } from "react-toastify";
import { TableChangeParams } from "../../../../components/Table/types";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import PageSubheader from "../../../../components/PageSubheader";
import { EnumPaths } from "../../../../utils/navigation";
import history from "../../../../history";
import Table from "../../../../components/Table";
import Dropdown, {
  DropdownValues,
} from "../../../../components/Tailwind/Dropdown";
import images from "../../../../images";
import { getTranslation } from "../../../../utils/translation";
import ConfirmModal from "../../../../utils/modal/confirm";
import IntlMessages from "../../../../utils/messages";
import { displayDayDDMMYYYY_HHMM } from "../../../../utils/config";
import { IncidentType } from "../../../../lib/ground-aws-graphql-core/models/Api/Incidents";

interface Props {
  intl: IntlShape;
  location: Location<{
    pageIndex: number;
    limit: number;
    type: string;
  }>;
}

const DEFAULT_LIMIT = 10;

const ListIncidentTypes = (props: Props): JSX.Element => {
  const { intl, location } = props;
  const [loading, setLoading] = useState(false);

  const [showDeleteIncidentTypeModal, setShowDeleteIncidentTypeModal] =
    useState(false);

  const [incidentTypeId, setIncidentTypeId] = useState<number>();
  const [initialParams, setInitialParams] = useState<TableChangeParams | null>(
    location?.state
  );

  const listIncidentTypes = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.listIncidentTypes
  );

  const incidentTypes = GroundGraphqlContextStore.useStoreState(
    actions => actions.incidents.incidentTypes.items
  );

  const total = GroundGraphqlContextStore.useStoreState(
    actions => actions.incidents.incidentTypes.total
  );

  const setIncidentType = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.setIncidentType
  );

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const deleteIncidentType = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.deleteIncidentType
  );

  useEffect(() => {
    if (location?.state) {
      fetchData(location?.state?.pageIndex, true, location?.state?.limit);
    } else fetchData();
  }, []);

  const fetchData = (pageIndex = 0, loader = true, limit = DEFAULT_LIMIT) => {
    if (loader) setLoading(true);

    listIncidentTypes({
      offset: pageIndex * limit,
      limit,
    }).finally(() => setLoading(false));
  };

  const addNewIncidentType = () => {
    history.push(
      `/${EnumPaths.ROOT}/${EnumPaths.INCIDENT_TYPES}/${EnumPaths.CREATION_MODE}`
    );
  };

  const handleOnChange = (changeParams: TableChangeParams) => {
    const { pageIndex, limit } = changeParams;
    setInitialParams(changeParams);
    fetchData(pageIndex, false, limit);
  };

  const handleEditIncidentType = (id: number) => {
    const editLink = `/${EnumPaths.ROOT}/${EnumPaths.INCIDENT_TYPES}/${id}/${EnumPaths.EDIT_MODE}`;
    setIncidentType(null);
    history.push(editLink);
  };

  const handleDelete = () => {
    setLoading(true);
    setShowDeleteIncidentTypeModal(false);
    deleteIncidentType({ id: incidentTypeId! })
      .then(() => {
        toast(
          intl.formatMessage({
            id: "page.incident.type.delete.success",
          }),
          { type: "success" }
        );
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "page.incident.type.delete.error",
          }),
          {
            type: "error",
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const tableHead = [
    "page.incident.type.label",
    "page.incident.type.code",
    "page.incident.type.created_at",
    "page.incident.type.updated_at",
    "general.actions",
  ];

  const tableBody = incidentTypes.map((incidentType: IncidentType) => {
    const { id, code, label, created_at, updated_at } = incidentType;
    const editLink = `/${EnumPaths.ROOT}/${EnumPaths.INCIDENT_TYPES}/${id}/${EnumPaths.EDIT_MODE}`;

    const dropdownValues: DropdownValues[] = [
      {
        id: "edit_incident_type_key",
        label: "page.list.incident.types.edit",
        icon: images.edit,
        link: editLink,
      },
      {
        id: "delete",
        label: "general.delete",
        icon: images.deleteIcon,
        onClick: () => {
          setShowDeleteIncidentTypeModal(!showDeleteIncidentTypeModal);
          setIncidentTypeId(id);
        },
      },
    ];

    const elements = [
      {
        element: getTranslation(label),
        onCellClick: () => handleEditIncidentType(id),
      },
      {
        element: code,
        onCellClick: () => handleEditIncidentType(id),
      },
      {
        element: displayDayDDMMYYYY_HHMM(created_at, centerTimezone),
        onCellClick: () => handleEditIncidentType(id),
      },
      {
        element: displayDayDDMMYYYY_HHMM(updated_at, centerTimezone),
        onCellClick: () => handleEditIncidentType(id),
      },
      {
        element: (
          <Dropdown
            values={dropdownValues}
            dataCy={`incident-type-dropdown-${id}`}
          />
        ),
      },
    ];

    return {
      rowElements: elements,
    };
  });

  return (
    <div>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: `page.list.incident.types.document.title`,
          })}
        </title>
      </Helmet>

      <PageSubheader
        title="page.list.incident.types.title"
        nbOfResults={total}
        buttonRightTitle="page.list.incident.types.create.incident.type"
        buttonRightAction={addNewIncidentType}
        buttonRightId="btn-create-incident-type"
      />

      <Table
        initialParams={initialParams}
        head={tableHead}
        body={tableBody}
        noDataText="page.list.incident.types.empty"
        onChange={handleOnChange}
        paginationLimit={DEFAULT_LIMIT}
        paginationTotal={total}
        loading={loading}
        setLoading={setLoading}
        className="px-8"
      />

      <ConfirmModal
        isOpen={showDeleteIncidentTypeModal}
        onRequestClose={() =>
          setShowDeleteIncidentTypeModal(!showDeleteIncidentTypeModal)
        }
        toggle={() =>
          setShowDeleteIncidentTypeModal(!showDeleteIncidentTypeModal)
        }
        handleConfirm={handleDelete}
        content={<IntlMessages id="page.list.incident.types.delete" />}
      />
    </div>
  );
};

export default injectIntl(ListIncidentTypes);
