import { AxiosResponse } from "axios";
import axiosBackend from "../../../../axiosBackend";
import { EnumLocale, EnumProductTypology } from "../../api/graphql/types";
import { EnumImageGenerationEntityType } from "../../../../enums/AIEnum";

export interface GenerateEventNewsDescriptionOpts {
  title: string;
  locale: EnumLocale;
  keywords?: string[];
}

export interface GenerateProductDescriptionOpts {
  name: string;
  typology: EnumProductTypology;
  capacity: number;
  locale: EnumLocale;
  keywords?: string[];
}

export interface TranslateTextDescriptionOpts {
  text: string;
  locale: EnumLocale;
}

export interface GenerateImageOpts {
  entity_type: EnumImageGenerationEntityType;
  description?: string;
  typology?: EnumProductTypology;
  title?: string;
}

export const generateEventDescription = async (
  payload: GenerateEventNewsDescriptionOpts
): Promise<AxiosResponse<{ success: boolean; data: string }>> => {
  const response = await axiosBackend
    .post("/ai/event_description", payload)
    .catch(error => {
      return error.response;
    });

  return response;
};

export const generateNewsDescription = async (
  payload: GenerateEventNewsDescriptionOpts
): Promise<AxiosResponse<{ success: boolean; data: string }>> => {
  const response = await axiosBackend
    .post("/ai/news_description", payload)
    .catch(error => {
      return error.response;
    });

  return response;
};

export const generateProductDescription = async (
  payload: GenerateProductDescriptionOpts
): Promise<AxiosResponse<{ success: boolean; data: string }>> => {
  const response = await axiosBackend
    .post("/ai/product_description", payload)
    .catch(error => {
      return error.response;
    });

  return response;
};

export const translateText = async (
  payload: TranslateTextDescriptionOpts
): Promise<AxiosResponse<{ success: boolean; data: string }>> => {
  const response = await axiosBackend
    .post("/ai/translate", payload)
    .catch(error => {
      return error.response;
    });

  return response;
};

export const generateImage = async (
  payload: GenerateImageOpts
): Promise<AxiosResponse<{ success: boolean; data: string }>> => {
  const response = await axiosBackend
    .post("/ai/image", payload)
    .catch(error => {
      return error.response;
    });

  return response;
};
