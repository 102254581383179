import { action, Action, Thunk, thunk } from "easy-peasy";
import { Injections } from "../../../store";
import { StoreModel } from "../..";
import { IListResponse } from "../../../services/Api/PaymentMethods";
import { IMultiLanguage } from "..";

export interface PriceUnitVariation {
  id: string;
  enabled: boolean;
  code: string;
  label: string;
  is_default: boolean;
  created_at: string;
  updated_at: string;
}

export interface ListPriceUnitVariationsOpts {
  limit?: number;
  offset?: number;
}

export interface CreatePriceUnitVariationOpts {
  code: string;
  label: IMultiLanguage;
  enabled: boolean;
}

export interface GetPriceUnitVariationOpts {
  id: string;
}

export interface SetAsDefaultOpts {
  id: string;
}

export interface DeletePriceUnitVariationOpts {
  id: string;
}

export interface UpdatePriceUnitVariationOpts {
  id: string;
  code?: string;
  label?: IMultiLanguage;
  enabled?: boolean;
}

export interface PriceUnitVariationsModel {
  priceUnitVariations: {
    items: PriceUnitVariation[];
    total: number;
    has_more: boolean;
  };
  priceUnitVariation: PriceUnitVariation | null;
  setPriceUnitVariations: Action<
    PriceUnitVariationsModel,
    { items: PriceUnitVariation[]; total: number; has_more: boolean }
  >;
  setPriceUnitVariation: Action<
    PriceUnitVariationsModel,
    PriceUnitVariation | null
  >;
  removePriceUnitVariation: Action<
    PriceUnitVariationsModel,
    DeletePriceUnitVariationOpts
  >;
  listPriceUnitVariations: Thunk<
    PriceUnitVariationsModel,
    ListPriceUnitVariationsOpts,
    Injections,
    StoreModel
  >;
  createPriceUnitVariation: Thunk<
    PriceUnitVariationsModel,
    CreatePriceUnitVariationOpts,
    Injections,
    StoreModel
  >;
  getPriceUnitVariation: Thunk<
    PriceUnitVariationsModel,
    GetPriceUnitVariationOpts,
    Injections,
    StoreModel
  >;
  deletePriceUnitVariation: Thunk<
    PriceUnitVariationsModel,
    DeletePriceUnitVariationOpts,
    Injections,
    StoreModel
  >;
  updatePriceUnitVariation: Thunk<
    PriceUnitVariationsModel,
    UpdatePriceUnitVariationOpts,
    Injections,
    StoreModel
  >;
  setAsDefault: Thunk<
    PriceUnitVariationsModel,
    SetAsDefaultOpts,
    Injections,
    StoreModel
  >;
}

export const priceUnitVariationsModel: PriceUnitVariationsModel = {
  priceUnitVariations: { items: [], total: 0, has_more: false },
  priceUnitVariation: null,
  setPriceUnitVariations: action((state, payload) => {
    state.priceUnitVariations = payload;
  }),
  setPriceUnitVariation: action((state, payload) => {
    state.priceUnitVariation = payload;
  }),
  removePriceUnitVariation: action((state, payload) => {
    state.priceUnitVariations.items = state.priceUnitVariations.items.filter(
      p => p.id !== payload.id
    );
    state.priceUnitVariations.total = state.priceUnitVariations.total - 1;
  }),
  listPriceUnitVariations: thunk(
    async (actions, payload: ListPriceUnitVariationsOpts, { injections }) => {
      const { priceUnitVariationRestService } = injections;
      const response =
        await priceUnitVariationRestService.listPriceUnitVariations(payload);
      if (response.status === 200 && response.data.success) {
        const res: IListResponse<PriceUnitVariation> = response.data;
        actions.setPriceUnitVariations(res.data);
      }
    }
  ),
  createPriceUnitVariation: thunk(
    async (actions, payload: CreatePriceUnitVariationOpts, { injections }) => {
      const { priceUnitVariationRestService } = injections;
      const response =
        await priceUnitVariationRestService.createPriceUnitVariation(payload);
      if (response.status === 201 && response.data.success) {
        actions.setPriceUnitVariation(response.data.data);
      }
    }
  ),
  getPriceUnitVariation: thunk(
    async (actions, payload: GetPriceUnitVariationOpts, { injections }) => {
      const { priceUnitVariationRestService } = injections;
      const response =
        await priceUnitVariationRestService.getPriceUnitVariation(payload.id);
      if (response.status === 200 && response.data.success) {
        actions.setPriceUnitVariation(response.data.data);
      }
    }
  ),
  deletePriceUnitVariation: thunk(
    async (actions, payload: DeletePriceUnitVariationOpts, { injections }) => {
      const { priceUnitVariationRestService } = injections;
      const response =
        await priceUnitVariationRestService.deletePriceUnitVariation(
          payload.id
        );
      if (response.status === 200 && response.data.success) {
        actions.setPriceUnitVariation(null);
        actions.removePriceUnitVariation(payload);
      }
    }
  ),
  updatePriceUnitVariation: thunk(
    async (
      actions,
      payload: UpdatePriceUnitVariationOpts,
      { injections, getState }
    ) => {
      const { priceUnitVariationRestService } = injections;

      const response =
        await priceUnitVariationRestService.updatePriceUnitVariation(payload);

      if (response.status === 200 && response.data.success) {
        const data = response.data.data;
        actions.setPriceUnitVariation(data);

        const state = getState();
        const index = state.priceUnitVariations.items.findIndex(
          p => p.id === data.id
        );

        if (index !== -1) {
          const newItems = [...state.priceUnitVariations.items];
          newItems.splice(index, 1, data);

          actions.setPriceUnitVariations({
            ...state.priceUnitVariations,
            items: newItems,
          });
        }
      }

      return response.data;
    }
  ),
  setAsDefault: thunk(
    async (actions, payload: SetAsDefaultOpts, { injections, getState }) => {
      const { priceUnitVariationRestService } = injections;

      const response = await priceUnitVariationRestService.setAsDefault(
        payload
      );

      if (response.status === 201 && response.data.success) {
        const data = response.data.data;
        actions.setPriceUnitVariation(data);

        const state = getState();
        const index = state.priceUnitVariations.items.findIndex(
          p => p.id === data.id
        );

        if (index !== -1) {
          const newItems = [...state.priceUnitVariations.items];
          newItems.splice(index, 1, data);
          actions.setPriceUnitVariations({
            ...state.priceUnitVariations,
            items: newItems.map(i => ({
              ...i,
              is_default: i.id === data.id ? data.is_default : false,
            })),
          });
        }
      }

      return response.data;
    }
  ),
};
