import React, { useEffect, useState } from "react";
import { IntlShape } from "react-intl";
import { FieldAttributes } from "formik";
import { match as Match } from "react-router-dom";
import { toast } from "react-toastify";
import Block from "../../../../../components/Tailwind/Block";
import Header from "../../../../../components/Tailwind/Block/Header";
import DefaultForm, {
  AdditionalFieldAttributes,
} from "../../../../../components/Form";
import overrideClasses from "../../../../../utils/overrideClasses";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";
import { isString } from "../../../../../utils/types";
import history from "../../../../../history";
import { EnumPaths } from "../../../../../utils/navigation";

interface Props {
  match?: Match<{ id: string }>;
  intl: IntlShape;
  edition: boolean;
}

const IncidentStatusForm = (props: Props): JSX.Element => {
  const { intl, match, edition } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [togglePanel, setTogglePanel] = useState(false);

  const getIncidentStatus = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.getIncidentStatus
  );

  const createIncidentStatus = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.createIncidentStatus
  );

  const updateIncidentStatus = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.updateIncidentStatus
  );

  const setIncidentStatus = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.setIncidentStatus
  );

  const incidentStatus = GroundGraphqlContextStore.useStoreState(
    state => state.incidents.incidentStatus
  );

  const formFields: FieldAttributes<AdditionalFieldAttributes>[] = [
    {
      name: "code",
      type: "number",
      label: "page.incident.status.code",
      placeholder: "page.incident.status.code.placeholder",
      initialValue: incidentStatus?.code,
      required: true,
      disabled: edition,
      min: 0,
      validate: value => {
        if (value >= 0) return "";

        return "page.incident.status.code.more.than.zero";
      },
    },
    {
      name: "label",
      label: "page.incident.status.label",
      placeholder: "page.incident.status.label.placeholder",
      initialValue: incidentStatus?.label,
      required: true,
      translatable: true,
    },
  ];

  const fetchData = () => {
    setLoading(true);
    if (match?.params.id) {
      getIncidentStatus({ id: match?.params.id }).finally(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();

    // Clean the event from the state when we unmount this component
    return () => {
      setIncidentStatus(null);
    };
  }, []);

  const handleSubmit = (values: any) => {
    setLoading(true);
    const { label, ...others } = values;
    if (edition && incidentStatus) {
      updateIncidentStatus({
        ...others,
        label: isString(label) ? JSON.parse(label) : label,
        id: incidentStatus.id,
      })
        .then(() => {
          history.push(`/${EnumPaths.ROOT}/${EnumPaths.INCIDENT_STATUS}`);
          toast(
            intl.formatMessage({
              id: "page.incident.status.update.success",
            }),
            { type: "success" }
          );
        })
        .catch(() => {
          history.push(`/${EnumPaths.ROOT}/${EnumPaths.INCIDENT_STATUS}`);
          toast(
            intl.formatMessage({
              id: "page.incident.status.update.error",
            }),
            {
              type: "error",
            }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      createIncidentStatus({
        ...others,
        label: isString(label) ? JSON.parse(label) : label,
      })
        .then(() => {
          history.push(`/${EnumPaths.ROOT}/${EnumPaths.INCIDENT_STATUS}`);
          toast(
            intl.formatMessage({ id: "page.incident.status.create.success" }),
            { type: "success" }
          );
        })
        .catch(() => {
          history.push(`/${EnumPaths.ROOT}/${EnumPaths.INCIDENT_STATUS}`);
          toast(
            intl.formatMessage({ id: "page.incident.status.create.error" }),
            { type: "error" }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return loading ? (
    <div className="loading" />
  ) : (
    <>
      {/**
       * When we were loading the form was initialized again and we would lose all data
       * Show loading here in order to not lose the fields data after a loading
       */}
      <div className={overrideClasses({ loading })} />

      {/* Hide when loading */}
      <div className={overrideClasses("px-8 pb-32", { hidden: loading })}>
        <Block>
          <Header
            item={incidentStatus}
            title={
              edition
                ? "page.list.incident.status.update.incident.status"
                : "page.list.incident.status.create.incident.status"
            }
            className="border-b border-gray-200"
          />

          <DefaultForm
            fields={formFields}
            onSubmit={handleSubmit}
            showPanel={togglePanel}
          />
        </Block>
      </div>
    </>
  );
};

export default IncidentStatusForm;
