import React from "react";
import IntlMessages from "../../../utils/messages";
import Button from "../Button";
import AIText from "../../AIText";

interface Props {
  onGenerateImage: () => void;
  isImageLoading?: boolean;
}

const ImageInputIA = (props: Props): JSX.Element => {
  const { onGenerateImage, isImageLoading } = props;

  return (
    <div className="flex flex-col h-full w-full justify-between rounded-3 bg-transparent border border-ground-blue-100 border-dashed px-3">
      {isImageLoading ? (
        <AIText message="general.ai.loading" className="flex-1" />
      ) : (
        <>
          <AIText message="general.ai.help" />

          <Button
            id="btn-generate-image"
            name="btn-generate-image"
            data-cy="btn-generate-image"
            item={null}
            type="button"
            outline
            onClick={onGenerateImage}
          >
            <IntlMessages id={"general.ai.generate.image"} />
          </Button>
        </>
      )}
    </div>
  );
};

export default ImageInputIA;
