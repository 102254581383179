import { scrollToTop } from "../navigation";

export const validateMultipleFields = async (
  fields: string[],
  setFieldTouched,
  validateField
): Promise<boolean> => {
  for (const field of fields) {
    setFieldTouched(field, true);
  }

  const allValidations = await Promise.all(
    fields.map(field => validateField(field))
  );

  // Check if there are any validation errors
  // If there aren't any, each value should be null
  if (allValidations.some(v => v !== null)) {
    scrollToTop();

    return false;
  }

  return true;
};
