import { action, Action, Thunk, thunk } from "easy-peasy";
import { Injections } from "../../../store";
import { StoreModel } from "../..";
import {
  SearchableBooleanFilterInput,
  SearchableDateRangeFilterInput,
  SearchableStringFilterInput,
} from "../../../api/graphql/types";
import {
  IGetResponse,
  IListMediaResponse,
  IListResponse,
  IMultiLanguage,
} from "..";

export interface IncidentStatus {
  id: number;
  code: number;
  label: IMultiLanguage;
  created_at: string;
  updated_at: string;
}

export interface IncidentType {
  id: number;
  code: string;
  label: IMultiLanguage;
  created_at: string;
  updated_at: string;
}

export interface IncidentReason {
  id: number;
  code: string;
  label: IMultiLanguage;
  created_at: string;
  updated_at: string;
}

export interface IUser {
  id: string;
  last_name: string;
  first_name: string;
  email: string;
}

export interface Incident {
  id: number;
  ref: string;
  external_ref?: string | null;
  type: IncidentType;
  reason: IncidentReason;
  building: {
    id: string;
  };
  description?: IMultiLanguage;
  location?: IMultiLanguage;
  media: string;
  priority: number;
  status: IncidentStatus;
  rating: number;
  rating_authorized: boolean;
  resolution_date: string;
  user: IUser;
  pinned: boolean;
  viewer_id?: string;
  view_date?: string;
  created_at: string;
  updated_at: string;
  comments: string;
}

export interface IncidentComment {
  id: number;
  comment: IMultiLanguage;
  issuer?: string;
  created_at: string;
  updated_at: string;
}

export interface GetIncidentsOpts {
  id: string;
}

export interface UpdateIncidentsOpts {
  id: number;
  status_code?: number;
  external_ref?: string;
  rating_authorized?: boolean;
  high_priority?: boolean;
  resolution_date?: Date;
}

export interface ViewIncidentsOpts {
  id: number;
}

export interface CommentIncidentsOpts {
  id: number;
  comment: IMultiLanguage;
  issuer?: string;
}

export interface PinIncidentOpts {
  id: number;
}

export type SearchableIncidentsFilterInput = {
  status?: SearchableStringFilterInput;
  type?: SearchableStringFilterInput;
  reason?: SearchableStringFilterInput;
  lastname?: SearchableStringFilterInput;
  pinned?: SearchableBooleanFilterInput;
  viewed?: SearchableBooleanFilterInput;
  created_at?: SearchableDateRangeFilterInput;
};

export interface GetIncidentStatusOpts {
  id: string;
}

export interface GetIncidentTypeOpts {
  id: string;
}

export interface GetIncidentReasonOpts {
  id: string;
}

export interface DeleteIncidentStatusOpts {
  id: number;
}

export interface DeleteIncidentTypeOpts {
  id: number;
}

export interface DeleteIncidentReasonOpts {
  id: number;
}

export interface CreateIncidentStatusOpts {
  code: number;
  label: IMultiLanguage;
}

export interface CreateIncidentTypeOpts {
  code: string;
  label: IMultiLanguage;
}

export interface CreateIncidentReasonOpts {
  code: string;
  label: IMultiLanguage;
}

export interface UpdateIncidentStatusOpts {
  id: number;
  code?: number;
  label?: IMultiLanguage;
}

export interface UpdateIncidentTypeOpts {
  id: number;
  code?: string;
  label?: IMultiLanguage;
}

export interface UpdateIncidentReasonOpts {
  id: number;
  code?: string;
  label?: IMultiLanguage;
}

export interface ListIncidentStatusOpts {
  limit: number;
  offset: number;
}

export interface ListIncidentTypesOpts {
  limit: number;
  offset: number;
}

export interface ListIncidentReasonsOpts {
  id: string;
  limit: number;
  offset: number;
}

export interface ListIncidentsOpts {
  buildingId: string;
  limit: number;
  offset: number;
  filter?: SearchableIncidentsFilterInput;
}

export interface ListNotViewedIncidentsOpts {
  buildingId: string;
  limit: number;
  offset: number;
  filter: {
    viewed: SearchableBooleanFilterInput;
  };
}

export interface ListIncidentCommentsOpts {
  incidentId: string;
  limit?: number;
  offset?: number;
}

export interface ListMediaOpts {
  id: string;
}

export interface IncidentModel {
  incidents: {
    items: Incident[];
    total: number;
    has_more: boolean;
  };
  exportedIncidents: {
    items: Incident[];
    total: number;
    has_more: boolean;
  };
  notViewedincidents: {
    items: Incident[];
    total: number;
    has_more: boolean;
  };
  incidentsStatus: {
    items: IncidentStatus[];
    total: number;
    has_more: boolean;
  };
  incidentTypes: {
    items: IncidentType[];
    total: number;
    has_more: boolean;
  };
  incidentReasons: {
    items: IncidentReason[];
    total: number;
    has_more: boolean;
  };
  incidentComments: {
    items: IncidentComment[];
    total: number;
    has_more: boolean;
  };
  incident: Incident | null;
  incidentStatus: IncidentStatus | null;
  incidentType: IncidentType | null;
  incidentReason: IncidentReason | null;
  pictures: string[];

  setIncident: Action<IncidentModel, Incident | null>;
  setIncidentStatus: Action<IncidentModel, IncidentStatus | null>;
  setIncidentType: Action<IncidentModel, IncidentType | null>;
  setIncidentReason: Action<IncidentModel, IncidentReason | null>;
  setIncidents: Action<
    IncidentModel,
    {
      items: Incident[];
      total: number;
      has_more: boolean;
    }
  >;
  setExportedIncidents: Action<
    IncidentModel,
    {
      items: Incident[];
      total: number;
      has_more: boolean;
    }
  >;
  setNotViewedIncidents: Action<
    IncidentModel,
    {
      items: Incident[];
      total: number;
      has_more: boolean;
    }
  >;
  setIncidentsStatus: Action<
    IncidentModel,
    { items: IncidentStatus[]; total: number; has_more: boolean }
  >;
  setIncidentTypes: Action<
    IncidentModel,
    { items: IncidentType[]; total: number; has_more: boolean }
  >;
  setIncidentReasons: Action<
    IncidentModel,
    { items: IncidentReason[]; total: number; has_more: boolean }
  >;
  setIncidentComments: Action<
    IncidentModel,
    { items: IncidentComment[]; total: number; has_more: boolean }
  >;
  setPictures: Action<IncidentModel, string[]>;
  removeIncidentStatus: Action<IncidentModel, DeleteIncidentStatusOpts>;
  removeIncidentType: Action<IncidentModel, DeleteIncidentTypeOpts>;
  removeIncidentReason: Action<IncidentModel, DeleteIncidentReasonOpts>;
  getIncident: Thunk<IncidentModel, GetIncidentsOpts, Injections, StoreModel>;
  updateIncident: Thunk<
    IncidentModel,
    UpdateIncidentsOpts,
    Injections,
    StoreModel
  >;
  viewIncident: Thunk<IncidentModel, ViewIncidentsOpts, Injections, StoreModel>;
  commentIncident: Thunk<
    IncidentModel,
    CommentIncidentsOpts,
    Injections,
    StoreModel
  >;
  pinIncident: Thunk<IncidentModel, PinIncidentOpts, Injections, StoreModel>;
  unpinIncident: Thunk<IncidentModel, PinIncidentOpts, Injections, StoreModel>;
  listIncidents: Thunk<
    IncidentModel,
    ListIncidentsOpts,
    Injections,
    StoreModel
  >;
  exportIncidents: Thunk<
    IncidentModel,
    ListIncidentsOpts,
    Injections,
    StoreModel
  >;
  listNotViewedIncidents: Thunk<
    IncidentModel,
    ListNotViewedIncidentsOpts,
    Injections,
    StoreModel
  >;
  listIncidentStatus: Thunk<
    IncidentModel,
    ListIncidentStatusOpts | undefined,
    Injections,
    StoreModel
  >;
  listIncidentTypes: Thunk<
    IncidentModel,
    ListIncidentTypesOpts | undefined,
    Injections,
    StoreModel
  >;
  listIncidentReasons: Thunk<
    IncidentModel,
    { id: string; offset: number; limit: number },
    Injections,
    StoreModel
  >;
  getIncidentStatus: Thunk<
    IncidentModel,
    GetIncidentStatusOpts,
    Injections,
    StoreModel
  >;
  createIncidentStatus: Thunk<
    IncidentModel,
    CreateIncidentStatusOpts,
    Injections,
    StoreModel
  >;
  updateIncidentStatus: Thunk<
    IncidentModel,
    UpdateIncidentStatusOpts,
    Injections,
    StoreModel
  >;
  deleteIncidentStatus: Thunk<
    IncidentModel,
    DeleteIncidentStatusOpts,
    Injections,
    StoreModel
  >;
  getIncidentType: Thunk<
    IncidentModel,
    GetIncidentTypeOpts,
    Injections,
    StoreModel
  >;
  createIncidentType: Thunk<
    IncidentModel,
    CreateIncidentTypeOpts,
    Injections,
    StoreModel
  >;
  updateIncidentType: Thunk<
    IncidentModel,
    UpdateIncidentTypeOpts,
    Injections,
    StoreModel
  >;
  deleteIncidentType: Thunk<
    IncidentModel,
    DeleteIncidentTypeOpts,
    Injections,
    StoreModel
  >;
  getIncidentReason: Thunk<
    IncidentModel,
    { typeId: string; input: GetIncidentReasonOpts },
    Injections,
    StoreModel
  >;
  createIncidentReason: Thunk<
    IncidentModel,
    { typeId: string; input: CreateIncidentReasonOpts },
    Injections,
    StoreModel
  >;
  updateIncidentReason: Thunk<
    IncidentModel,
    { typeId: string; input: UpdateIncidentReasonOpts },
    Injections,
    StoreModel
  >;
  deleteIncidentReason: Thunk<
    IncidentModel,
    { typeId: string; input: DeleteIncidentReasonOpts },
    Injections,
    StoreModel
  >;
  listIncidentComments: Thunk<
    IncidentModel,
    ListIncidentCommentsOpts,
    Injections,
    StoreModel
  >;
  listMedia: Thunk<IncidentModel, ListMediaOpts, Injections, StoreModel>;
}

export const incidentModel: IncidentModel = {
  incidents: { items: [], total: 0, has_more: false },
  exportedIncidents: { items: [], total: 0, has_more: false },
  notViewedincidents: { items: [], total: 0, has_more: false },
  incidentsStatus: { items: [], total: 0, has_more: false },
  incidentTypes: { items: [], total: 0, has_more: false },
  incidentReasons: { items: [], total: 0, has_more: false },
  incidentComments: { items: [], total: 0, has_more: false },

  incident: null,
  incidentStatus: null,
  incidentType: null,
  incidentReason: null,
  pictures: [],

  setIncident: action((state, payload: Incident) => {
    state.incident = payload;
  }),
  setIncidentStatus: action((state, payload: IncidentStatus) => {
    state.incidentStatus = payload;
  }),
  setIncidentType: action((state, payload: IncidentType) => {
    state.incidentType = payload;
  }),
  setIncidentReason: action((state, payload: IncidentReason) => {
    state.incidentReason = payload;
  }),
  setIncidents: action((state, payload) => {
    state.incidents = payload;
  }),
  setExportedIncidents: action((state, payload) => {
    state.exportedIncidents = payload;
  }),
  setNotViewedIncidents: action((state, payload) => {
    state.notViewedincidents = payload;
  }),
  setIncidentsStatus: action((state, payload) => {
    state.incidentsStatus = payload;
  }),
  setIncidentTypes: action((state, payload) => {
    state.incidentTypes = payload;
  }),
  setIncidentReasons: action((state, payload) => {
    state.incidentReasons = payload;
  }),
  setIncidentComments: action((state, payload) => {
    state.incidentComments = payload;
  }),
  setPictures: action((state, payload) => {
    state.pictures = payload;
  }),
  removeIncidentStatus: action((state, payload) => {
    state.incidentsStatus.items = state.incidentsStatus.items.filter(
      p => p.id !== payload.id
    );
    state.incidentsStatus.total = state.incidentsStatus.total - 1;
  }),
  removeIncidentType: action((state, payload) => {
    state.incidentTypes.items = state.incidentTypes.items.filter(
      p => p.id !== payload.id
    );
    state.incidentTypes.total = state.incidentTypes.total - 1;
  }),
  removeIncidentReason: action((state, payload) => {
    state.incidentReasons.items = state.incidentReasons.items.filter(
      p => p.id !== payload.id
    );
    state.incidentReasons.total = state.incidentReasons.total - 1;
  }),
  getIncident: thunk(
    async (actions, payload: GetIncidentsOpts, { injections }) => {
      const { incidentService } = injections;
      const response = await incidentService.getIncident(payload.id);
      if (response.status === 200 && response.data.success) {
        actions.setIncident(response.data.data);
      }
    }
  ),
  updateIncident: thunk(
    async (actions, payload: UpdateIncidentsOpts, { injections, getState }) => {
      const { incidentService } = injections;

      const response = await incidentService.updateIncident(payload);

      if (response.status === 200 && response.data.success) {
        const updatedIncident = response.data.data;
        actions.setIncident(updatedIncident);

        const state = getState();
        const incidentIndex = state.incidents.items.findIndex(
          incident => incident.id === updatedIncident.id
        );

        if (incidentIndex !== -1) {
          const newIncidentsItems = [...state.incidents.items];
          newIncidentsItems.splice(incidentIndex, 1, updatedIncident);

          actions.setIncidents({
            ...state.incidents,
            items: newIncidentsItems,
          });
        }

        actions.listIncidentComments({ incidentId: payload.id.toString() });
      }

      return response.data;
    }
  ),
  viewIncident: thunk(
    async (actions, payload: ViewIncidentsOpts, { injections, getState }) => {
      const { incidentService } = injections;

      const response = await incidentService.viewIncident(payload);

      if (response.status === 200 && response.data.success) {
        const updatedIncident = response.data.data;
        actions.setIncident(updatedIncident);

        const state = getState();
        const incidentIndex = state.incidents.items.findIndex(
          incident => incident.id === updatedIncident.id
        );

        if (incidentIndex !== -1) {
          const newIncidentsItems = [...state.incidents.items];
          newIncidentsItems.splice(incidentIndex, 1, updatedIncident);

          actions.setIncidents({
            ...state.incidents,
            items: newIncidentsItems,
          });
        }
      }
    }
  ),
  commentIncident: thunk(
    async (actions, payload: CommentIncidentsOpts, { injections }) => {
      const { incidentService } = injections;

      const response = await incidentService.commentIncident(payload);
      actions.listIncidentComments({ incidentId: payload.id.toString() });

      return response.data;
    }
  ),
  pinIncident: thunk(
    async (actions, payload: PinIncidentOpts, { injections, getState }) => {
      const { incidentService } = injections;

      const response = await incidentService.pinIncident(payload);

      if (response.data.success) {
        const updatedIncident = response.data.data;
        actions.setIncident(updatedIncident);

        const state = getState();
        const incidentIndex = state.incidents.items.findIndex(
          incident => incident.id === updatedIncident.id
        );

        if (incidentIndex !== -1) {
          const newIncidentsItems = [...state.incidents.items];
          newIncidentsItems.splice(incidentIndex, 1, updatedIncident);

          actions.setIncidents({
            ...state.incidents,
            items: newIncidentsItems,
          });
        }
      }
    }
  ),
  unpinIncident: thunk(
    async (actions, payload: PinIncidentOpts, { injections, getState }) => {
      const { incidentService } = injections;

      const response = await incidentService.unpinIncident(payload);

      if (response.data.success) {
        const updatedIncident = response.data.data;
        actions.setIncident(updatedIncident);

        const state = getState();
        const incidentIndex = state.incidents.items.findIndex(
          incident => incident.id === updatedIncident.id
        );

        if (incidentIndex !== -1) {
          const newIncidentsItems = [...state.incidents.items];
          newIncidentsItems.splice(incidentIndex, 1, updatedIncident);

          actions.setIncidents({
            ...state.incidents,
            items: newIncidentsItems,
          });
        }
      }
    }
  ),
  listIncidents: thunk(
    async (actions, payload: ListIncidentsOpts, { injections }) => {
      const { incidentService } = injections;
      const response = await incidentService.listIncidents(
        payload.buildingId,
        payload.offset,
        payload.limit,
        payload.filter
      );
      if (response.status === 200 && response.data.success) {
        const incidents: IListResponse<Incident> = response.data;
        actions.setIncidents(incidents.data);
      }
    }
  ),
  exportIncidents: thunk(
    async (actions, payload: ListIncidentsOpts, { injections }) => {
      const { incidentService } = injections;
      const response = await incidentService.exportIncidents(
        payload.buildingId,
        payload.offset,
        payload.limit,
        payload.filter
      );
      if (response.status === 200 && response.data.success) {
        const incidents: IListResponse<Incident> = response.data;
        actions.setExportedIncidents(incidents.data);

        return incidents.data;
      }
    }
  ),
  listNotViewedIncidents: thunk(
    async (actions, payload: ListNotViewedIncidentsOpts, { injections }) => {
      const { incidentService } = injections;
      const response = await incidentService.listIncidents(
        payload.buildingId,
        payload.offset,
        payload.limit,
        payload.filter
      );
      if (response.status === 200 && response.data.success) {
        const incidents: IListResponse<Incident> = response.data;
        actions.setNotViewedIncidents(incidents.data);
      }
    }
  ),
  listIncidentStatus: thunk(
    async (actions, payload: ListIncidentStatusOpts, { injections }) => {
      const { incidentService } = injections;
      const response = await incidentService.listIncidentStatus(payload);
      if (response.status === 200 && response.data.success) {
        const incidentsStatus: IListResponse<IncidentStatus> = response.data;
        actions.setIncidentsStatus(incidentsStatus.data);

        return incidentsStatus.data;
      }
    }
  ),
  listIncidentTypes: thunk(
    async (actions, payload: ListIncidentTypesOpts, { injections }) => {
      const { incidentService } = injections;
      const response = await incidentService.listIncidentTypes(payload);
      if (response.status === 200 && response.data.success) {
        const incidentTypes: IListResponse<IncidentType> = response.data;
        actions.setIncidentTypes(incidentTypes.data);

        return incidentTypes.data;
      }
    }
  ),
  listIncidentReasons: thunk(
    async (actions, payload: ListIncidentReasonsOpts, { injections }) => {
      const { incidentService } = injections;
      const response = await incidentService.listIncidentReasons(
        payload.id,
        payload.offset,
        payload.limit
      );
      if (response.status === 200 && response.data.success) {
        const incidentReasons: IListResponse<IncidentReason> = response.data;
        actions.setIncidentReasons(incidentReasons.data);

        return incidentReasons.data;
      }
    }
  ),
  getIncidentStatus: thunk(
    async (actions, payload: GetIncidentStatusOpts, { injections }) => {
      const { incidentService } = injections;
      const response = await incidentService.getIncidentStatus(payload.id);
      if (response.status === 200 && response.data.success) {
        const incidentStatus: IGetResponse<IncidentStatus> = response.data;
        actions.setIncidentStatus(incidentStatus.data);

        return incidentStatus.data;
      }
    }
  ),
  createIncidentStatus: thunk(
    async (actions, payload: CreateIncidentStatusOpts, { injections }) => {
      const { incidentService } = injections;
      const response = await incidentService.createIncidentStatus(payload);
      if (response.status === 201 && response.data.success) {
        actions.setIncidentStatus(response.data.data);
      }
    }
  ),
  updateIncidentStatus: thunk(
    async (
      actions,
      payload: UpdateIncidentStatusOpts,
      { injections, getState }
    ) => {
      const { incidentService } = injections;

      const response = await incidentService.updateIncidentStatus(payload);

      if (response.status === 200 && response.data.success) {
        const data = response.data.data;
        actions.setIncidentStatus(data);

        const state = getState();
        const index = state.incidentsStatus.items.findIndex(
          p => p.id === data.id
        );

        if (index !== -1) {
          const newItems = [...state.incidentsStatus.items];
          newItems.splice(index, 1, data);

          actions.setIncidentsStatus({
            ...state.incidentsStatus,
            items: newItems,
          });
        }
      }

      return response.data;
    }
  ),
  deleteIncidentStatus: thunk(
    async (actions, payload: DeleteIncidentStatusOpts, { injections }) => {
      const { incidentService } = injections;
      const response = await incidentService.deleteIncidentStatus(payload.id);
      if (response.status === 200 && response.data.success) {
        actions.setIncidentStatus(null);
        actions.removeIncidentStatus(payload);
      }
    }
  ),
  getIncidentType: thunk(
    async (actions, payload: GetIncidentTypeOpts, { injections }) => {
      const { incidentService } = injections;
      const response = await incidentService.getIncidentType(payload.id);
      if (response.status === 200 && response.data.success) {
        const incidentType: IGetResponse<IncidentType> = response.data;
        actions.setIncidentType(incidentType.data);

        return incidentType.data;
      }
    }
  ),
  createIncidentType: thunk(
    async (actions, payload: CreateIncidentTypeOpts, { injections }) => {
      const { incidentService } = injections;
      const response = await incidentService.createIncidentType(payload);
      if (response.status === 201 && response.data.success) {
        actions.setIncidentType(response.data.data);
      }
    }
  ),
  updateIncidentType: thunk(
    async (
      actions,
      payload: UpdateIncidentTypeOpts,
      { injections, getState }
    ) => {
      const { incidentService } = injections;

      const response = await incidentService.updateIncidentType(payload);

      if (response.status === 200 && response.data.success) {
        const data = response.data.data;
        actions.setIncidentType(data);

        const state = getState();
        const index = state.incidentTypes.items.findIndex(
          p => p.id === data.id
        );

        if (index !== -1) {
          const newItems = [...state.incidentTypes.items];
          newItems.splice(index, 1, data);

          actions.setIncidentTypes({
            ...state.incidentsStatus,
            items: newItems,
          });
        }
      }

      return response.data;
    }
  ),
  deleteIncidentType: thunk(
    async (actions, payload: DeleteIncidentTypeOpts, { injections }) => {
      const { incidentService } = injections;
      const response = await incidentService.deleteIncidentType(payload.id);
      if (response.status === 200 && response.data.success) {
        actions.setIncidentStatus(null);
        actions.removeIncidentType(payload);
      }
    }
  ),
  getIncidentReason: thunk(
    async (
      actions,
      payload: { typeId: string; input: GetIncidentReasonOpts },
      { injections }
    ) => {
      const { incidentService } = injections;
      const response = await incidentService.getIncidentReason(
        payload.typeId,
        payload.input.id
      );
      if (response.status === 200 && response.data.success) {
        const incidentReason: IGetResponse<IncidentReason> = response.data;
        actions.setIncidentReason(incidentReason.data);

        return incidentReason.data;
      }
    }
  ),
  createIncidentReason: thunk(
    async (
      actions,
      payload: { typeId: string; input: CreateIncidentReasonOpts },
      { injections }
    ) => {
      const { incidentService } = injections;
      const response = await incidentService.createIncidentReason(
        payload.typeId,
        payload.input
      );
      if (response.status === 201 && response.data.success) {
        actions.setIncidentReason(response.data.data);
      }
    }
  ),
  updateIncidentReason: thunk(
    async (
      actions,
      payload: { typeId: string; input: UpdateIncidentReasonOpts },
      { injections, getState }
    ) => {
      const { incidentService } = injections;

      const response = await incidentService.updateIncidentReason(
        payload.typeId,
        payload.input
      );

      if (response.status === 200 && response.data.success) {
        const data = response.data.data;
        actions.setIncidentReason(data);

        const state = getState();
        const index = state.incidentReasons.items.findIndex(
          p => p.id === data.id
        );

        if (index !== -1) {
          const newItems = [...state.incidentReasons.items];
          newItems.splice(index, 1, data);

          actions.setIncidentReasons({
            ...state.incidentReasons,
            items: newItems,
          });
        }
      }

      return response.data;
    }
  ),
  deleteIncidentReason: thunk(
    async (
      actions,
      payload: { typeId: string; input: DeleteIncidentReasonOpts },
      { injections }
    ) => {
      const { incidentService } = injections;
      const response = await incidentService.deleteIncidentReason(
        payload.typeId,
        payload.input.id
      );
      if (response.status === 200 && response.data.success) {
        actions.setIncidentReason(null);
        actions.removeIncidentReason(payload.input);
      }
    }
  ),
  listIncidentComments: thunk(
    async (actions, payload: ListIncidentCommentsOpts, { injections }) => {
      const { incidentService } = injections;
      const response = await incidentService.listIncidentComments(
        payload.incidentId,
        payload.offset,
        payload.limit
      );
      if (response.status === 200 && response.data.success) {
        const incidentComments: IListResponse<IncidentComment> = response.data;
        actions.setIncidentComments(incidentComments.data);
      }
    }
  ),
  listMedia: thunk(async (actions, payload: ListMediaOpts, { injections }) => {
    const { incidentService } = injections;
    const response = await incidentService.listMedia(payload.id);
    if (response.status === 200 && response.data.success) {
      const resp: IListMediaResponse = response.data;
      actions.setPictures(resp.data.pictures);
    }
  }),
};
