import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { match as Match } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import classnames from "classnames";
import { injectIntl, IntlShape } from "react-intl";
import _ from "lodash";
import CardInformation from "../../../components/GroundBlocks/BlockCard/CardInformation";
import CardRatings from "../../../components/GroundBlocks/BlockCard/CardRating";
import CardHour from "../../../components/GroundBlocks/BlockCard/CardHour";
import SimpleMap from "../../../components/SimpleMap";
import Carousel from "../../../components/Carousel";
import CardAttributes from "../../../components/GroundBlocks/BlockCard/CardAttributes";
import SavePanel from "../../../components/Tailwind/SavePanel";
import {
  AttributeItem,
  getAttributes,
  CenterAttributeKeys,
  getRequiredAttributes,
} from "../../../utils/attribute";
import { getTranslation } from "../../../utils/translation";
import {
  FormValue,
  ActionTypes,
  Image,
  getCenterTypeItems,
  Item,
  EnumAction,
  canManageBuildingAuthorizations,
  canDeleteBuilding,
} from "../../../utils/types";
import contextStore from "../../../redux/store";
import {
  getCenterDaySlots,
  DaySlotItem,
  HoursRangeItem,
  ClosedDayItem,
  getCenterClosedDays,
  ExceptionalOpeningHourItem,
  getCenterExceptionalOpeningHours,
} from "../../../utils/dayslot";
import IntlMessages from "../../../utils/messages";
import { EnumPaths } from "../../../utils/navigation";
import history from "../../../history";
import ConfirmModal from "../../../utils/modal/confirm";
import HeaderAdmin from "../../../components/Tailwind/Block/HeaderAdmin";
import Block from "../../../components/Tailwind/Block";
import ListAdmin from "../admins";
import ModalCreateCenterType from "../creation/type";
import Button from "../../../components/Tailwind/Button";
import { getCypressTestId } from "../../../utils/config";
import { Display } from "../../../components/OperatorCustomization";
import { AttributeKey } from "../../../lib/ground-aws-graphql-core/models/AttributeKey";
import { Authorization } from "../../../lib/ground-aws-graphql-core/models/Authorization";
import {
  EnumAttributeType,
  EnumPermissionEntity,
  EnumBackOfficeUserRole,
} from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import { BackOfficeUser } from "../../../lib/ground-aws-graphql-core/models/BackOfficeUser";
import { GroundGraphqlContextStore } from "../../../lib/ground-aws-graphql-core";
import { GroundAuthContextStore } from "../../../lib/ground-aws-cognito-auth-core";
import CardClosedDays from "../../../components/GroundBlocks/BlockCard/CardClosedDays";
import { getCountries } from "../../../lang";
import { messageWithDefault } from "../../../utils/messages/general";

interface Props {
  intl: IntlShape;
  match: Match<{ cid: string }>;
}

const DEFAULT_LIMIT = 100;

const CenterUpdate = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [isAttributesLoaded, setIsAttributesLoaded] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [pictures, setPictures] = useState([] as Image[]);
  const [togglePanel, setTogglePanel] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [attributes, setAttributes] = useState([] as AttributeItem[]);
  const [filteredAttributeKeys, setFilteredAttributeKeys] = useState<
    AttributeKey[]
  >([]);
  const [daySlots, setDaySlots] = useState<DaySlotItem[]>([]);
  const [exceptionalOpeningHours, setExceptionalOpeningHours] = useState<
    ExceptionalOpeningHourItem[]
  >([]);
  const [closedDays, setClosedDays] = useState<ClosedDayItem[]>([]);
  const [hoursRanges, setHoursRanges] = useState<HoursRangeItem[]>([]);
  const [centerRating, setCenterRating] = useState(null || 0);
  const [modalCenterTypeOpen, setModalCenterTypeOpen] = useState(false);
  const [action, setAction] = useState("");

  const { intl, match } = props;

  const [authorizations, setAuthorizations] = useState<
    { authorization: Authorization; action: ActionTypes }[]
  >([]);

  const [attributeKeys, setAttributeKeys] = useState<AttributeKey[] | null>(
    null
  );

  const me = GroundAuthContextStore.useStoreState(
    state => state.authentication.me
  );

  const [fieldsToUpdate, setFieldsToUpdate] = useState(
    [] as { field: any; value: any }[]
  );

  const center = GroundGraphqlContextStore.useStoreState(
    state => state.center.center
  );

  const searchAllAttributeKeys = GroundGraphqlContextStore.useStoreActions(
    actions => actions.attributeKey.searchAllAttributeKeys
  );

  const getCenter = GroundGraphqlContextStore.useStoreActions(
    actions => actions.center.getCenter
  );

  const searchBuildingAuthorizations =
    GroundGraphqlContextStore.useStoreActions(
      actions => actions.authorization.searchBuildingAuthorizations
    );

  const buildingAuthorizations = GroundGraphqlContextStore.useStoreState(
    state => state.authorization.buildingAuthorizations.items
  );

  // TODO:: Replace by search
  const listCenterTypes = GroundGraphqlContextStore.useStoreActions(
    actions => actions.centerType.listCenterTypes
  );

  const updateCenter = contextStore.useStoreActions(
    actions => actions.center.updateCenter
  );

  const updateCenterAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.center.updateCenter
  );

  const deleteCenterAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.center.deleteCenter
  );

  const createCenterAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.center.createCenter
  );

  const createAuthorizationAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.authorization.createAuthorization
  );

  const centerTypes = GroundGraphqlContextStore.useStoreState(
    state => state.centerType.centerTypes
  );

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const countries = getCountries(locale);

  const generalFormRef = useRef<React.MutableRefObject<any>>();
  const infoFormRef = useRef<React.MutableRefObject<any>>();
  const attributesFormRef = useRef<React.MutableRefObject<any>>();

  useEffect(() => {
    const adminAuthorizationsToKeep = buildingAuthorizations?.map(
      authorization => ({
        authorization,
        action: ActionTypes.TO_KEEP,
      })
    );

    if (adminAuthorizationsToKeep && adminAuthorizationsToKeep.length > 0) {
      setAuthorizations(adminAuthorizationsToKeep);
    }
  }, [buildingAuthorizations]);

  const addFieldToUpdate = e => {
    const index = _.findIndex(fieldsToUpdate, { field: e.target.name });
    const array = fieldsToUpdate;

    if (index >= 0) {
      array.splice(index, 1, { field: e.target.name, value: e.target.value });
      setFieldsToUpdate(array);
    } else {
      setFieldsToUpdate([
        ...fieldsToUpdate,
        { field: e.target.name, value: e.target.value },
      ]);
    }
    handleShowSavePanel();
  };

  const handleDelete = (id: string) => {
    if (center?.operator?.id) {
      setLoading(true);
      deleteCenterAction({
        id,
      })
        .then(() => {
          history.push(`/${EnumPaths.ROOT}/${EnumPaths.CENTERS}`);
          toast(
            intl.formatMessage({
              id: "general.delete.building.success",
            }),
            { type: "success" }
          );
        })
        .catch(() => {
          toast(
            intl.formatMessage({
              id: "general.delete.building.success",
            }),
            {
              type: "error",
            }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onRemoveAuthorization = (a: Authorization) => {
    const items = Object.assign([], authorizations) as {
      authorization: Authorization;
      action: ActionTypes;
    }[];

    const results = items.filter(el => el.authorization.id !== a.id);
    const result = items.find(el => el.authorization.id === a.id);

    if (result && result.action === ActionTypes.TO_KEEP) {
      // If authorization exists (action === to_keep), we pass it to delete
      const el = { ...result };
      el.action = ActionTypes.TO_DELETE;
      results.push(el);
    }

    setAuthorizations(results);

    // show save panel
    handleShowSavePanel();
  };

  const handleSavePanel = () => {
    if (generalFormRef.current) {
      generalFormRef.current.validateForm().then(errors => {
        if (Object.keys(errors).length === 0) {
          infoFormRef.current.validateForm().then(err => {
            if (Object.keys(err).length === 0) {
              if (attributesFormRef.current) {
                attributesFormRef.current.validateForm().then(data => {
                  if (Object.keys(data).length === 0) {
                    saveCenterData();
                    setTogglePanel(!togglePanel);
                  } else {
                    attributesFormRef.current.showErrors(data);
                  }
                });
              } else {
                saveCenterData();
                setTogglePanel(!togglePanel);
              }
            } else {
              infoFormRef.current.showErrors(err);
            }
          });
        } else {
          generalFormRef.current.showErrors(errors);
        }
      });
    }
    setFieldsToUpdate([]);
  };

  const saveCenterData = () => {
    if (center) {
      const name = _.find(fieldsToUpdate, ["field", "name"]);
      const description = _.find(fieldsToUpdate, ["field", "description"]);
      const type = _.find(fieldsToUpdate, ["field", "type"]);
      const email = _.find(fieldsToUpdate, ["field", "email"]);
      const phone = _.find(fieldsToUpdate, ["field", "phone"]);
      const address = _.find(fieldsToUpdate, ["field", "address"]);
      const city = _.find(fieldsToUpdate, ["field", "city"]);
      const countryCode = _.find(fieldsToUpdate, ["field", "country"]);
      const zipCode = _.find(fieldsToUpdate, ["field", "zipCode"]);
      const latitude = _.find(fieldsToUpdate, ["field", "latitude"]);
      const longitude = _.find(fieldsToUpdate, ["field", "longitude"]);

      const country = countries.find(c => c.id === countryCode?.value);

      setLoading(true);

      updateCenter({
        center: {
          ...center,
          description: description ? description.value : center.description,
          name: name ? name.value : center.name,
          address: address ? address.value : center.address,
          city: city ? city.value : center.city,
          email: email?.value || center?.email || undefined,
          phone: phone ? phone.value : center.phone,
          country: country ? country.name : center.country,
          zipCode: zipCode ? zipCode.value : center.zipCode,
          location: {
            lat: latitude ? parseFloat(latitude.value) : center.location?.lat,
            lon: longitude ? parseFloat(longitude.value) : center.location?.lon,
          },
        },
        centerTypeId: type ? type.value : center.type?.id,
        values: null,
        attributeKeys: filteredAttributeKeys,
        attributes,
        pictures,
        enabled: center.enabled ? center.enabled : true,
        operator: center.operator,
        hoursRanges,
        daySlots,
        closedDays,
        exceptionalOpeningHours,
        authorizations,
        callbacks: {
          updateCenter: updateCenterAction,
          createCenter: createCenterAction,
          createAuthorization: createAuthorizationAction,
        },
      })
        .then(() => {
          setLoading(false);
          setRefresh(true);
          toast(
            intl.formatMessage({
              id: "page.center.update.center.success",
            }),
            { type: "success" }
          );
        })
        .catch(err => {
          setLoading(false);
          setHoursRanges([]);
          setClosedDays([]);
          setRefresh(true);

          if (err.graphQLErrors?.[0]) {
            toast(
              intl.formatMessage({
                id: messageWithDefault(
                  intl,
                  `page.center.update.center.${err.graphQLErrors[0].message}`,
                  `page.center.update.center.error`
                ),
              }),
              { type: "error" }
            );
          }
        });
    }
  };

  const handleTogglePanel = () => {
    setTogglePanel(!togglePanel);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (refresh) {
      fetchData();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    setHoursRanges([]);
    if (center) {
      const centerPictures = center.pictures?.reduce(
        (acc: Image[], picture) => {
          if (picture) {
            acc.push({
              picture,
              source: false,
              action: ActionTypes.TO_KEEP,
            });
          }

          return acc;
        },
        []
      );
      if (centerPictures) {
        setPictures(centerPictures);
      }

      if (center.daySlots?.items) {
        const centerDaySlots = getCenterDaySlots(intl, center.daySlots?.items);
        setDaySlots(centerDaySlots);
      }

      if (center.closedDays?.items) {
        const closed = getCenterClosedDays(center.closedDays?.items);
        setClosedDays(closed);
      }

      if (center.exceptionalOpeningHours?.items) {
        const exceptionalHours = getCenterExceptionalOpeningHours(
          center.exceptionalOpeningHours.items
        );
        setExceptionalOpeningHours(exceptionalHours);
      }
    }
  }, [center]);

  useEffect(() => {
    // If we don't have the attributes then we have an issue with the number of hooks
    // Therefore we need to wait for them
    if (center && me && attributeKeys) {
      const filteredAttrKeys = attributeKeys.filter(
        ak =>
          !ak.roles ||
          (ak.roles &&
            ak.roles.length > 0 &&
            ak.roles.includes(me.role as EnumBackOfficeUserRole))
      );

      if (filteredAttrKeys.length > 0) {
        setFilteredAttributeKeys(filteredAttrKeys);

        if (center.attributes?.items) {
          const centerAttributes = getAttributes(
            center,
            EnumAttributeType.CENTER,
            filteredAttrKeys
          );

          const ratingAttribute = centerAttributes.find(
            attr => attr?.item?.key?.name === CenterAttributeKeys.RATING
          );

          if (ratingAttribute && ratingAttribute.item.numberValue) {
            setCenterRating(ratingAttribute.item.numberValue);
          }

          setAttributes([...centerAttributes]);
          setIsAttributesLoaded(true);
        } else setIsAttributesLoaded(true);
      } else setIsAttributesLoaded(true);
    }
  }, [center, me, attributeKeys]);

  const handleChangeHours = (hoursRange: HoursRangeItem) => {
    const index = _.findIndex(
      hoursRanges,
      o => o.item.id === hoursRange.item.id
    );

    const array = hoursRanges;
    if (index >= 0) {
      const currentHoursRange = hoursRanges[index];

      // Impossible condition, we can't go from TO_ADD to TO_KEEP
      // This means we actually want to delete the hoursRange locally
      if (
        currentHoursRange.action === ActionTypes.TO_ADD &&
        hoursRange.action === ActionTypes.TO_KEEP
      )
        array.splice(index, 1);
      else array.splice(index, 1, hoursRange);

      setHoursRanges(array);
    } else {
      setHoursRanges([...hoursRanges, hoursRange]);
    }

    handleShowSavePanel();
  };

  const handleChangeExceptionalOpeningHours = (
    ...exceptionalOpeningHourItems: ExceptionalOpeningHourItem[]
  ) => {
    let array = [...exceptionalOpeningHours];
    for (const exceptionalHour of exceptionalOpeningHourItems) {
      const index = exceptionalOpeningHours.findIndex(
        e => e.item.id === exceptionalHour.item.id
      );
      if (index >= 0) array.splice(index, 1, exceptionalHour);
      else array = [...array, exceptionalHour];
    }

    setExceptionalOpeningHours(array);
    handleShowSavePanel();
  };

  const handleChangeClosedDays = (closedDay: ClosedDayItem) => {
    // find closed day using start and end
    const index = _.findIndex(
      closedDays,
      a =>
        a.item.start === closedDay.item.start &&
        a.item.end === closedDay.item.end
    );
    if (index >= 0) {
      // found
      const array = [...closedDays];
      array.splice(index, 1, closedDay);
      setClosedDays(array);
    } else {
      // not found, we add it
      setClosedDays([...closedDays, closedDay]);
    }
    handleShowSavePanel();
  };

  const handleShowSavePanel = () => {
    setTogglePanel(true);
  };

  const onAddAuthorization = (u: BackOfficeUser) => {
    const items = Object.assign([], authorizations) as {
      authorization: Authorization;
      action: ActionTypes;
    }[];

    const results = items.filter(el => el.authorization.user.id !== u.id);
    const result = items.find(el => el.authorization.user.id === u.id);

    if (result && result.action === ActionTypes.TO_DELETE) {
      // If authorization is marked to delete, and we try to add it, we will pass it to keep
      const el = { ...result };
      el.action = ActionTypes.TO_KEEP;
      results.push(el);

      setAuthorizations(results);
    } else {
      if (center?.operator) {
        items.push({
          authorization: {
            id: uuidv4(),
            user: u,
            operator: center?.operator,
            entityType: EnumPermissionEntity.CENTER,
            entityId: match.params.cid,
          },
          action: ActionTypes.TO_ADD,
        });
      }
      setAuthorizations(items);
    }

    // show save panel
    handleShowSavePanel();
  };

  const handleAddPicture = (picture: string | ArrayBuffer, file: File) => {
    // Add the picture at the end of the existing ones
    setPictures([
      ...pictures,
      {
        picture,
        source: true,
        file,
        action: ActionTypes.TO_ADD,
      },
    ]);

    // Show save panel
    handleShowSavePanel();
  };

  const handleRemovePicture = (pictureToRemove: Image) => {
    // Get all pictures except the picture to remove
    const items = pictures.filter(e => e.picture !== pictureToRemove.picture);

    if (pictureToRemove.source) {
      setPictures(items);
    } else {
      setPictures([
        ...items,
        { ...pictureToRemove, action: ActionTypes.TO_DELETE },
      ]);
    }

    // Show save panel
    handleShowSavePanel();
  };

  const handleChangeAttribute = (newAttributes: AttributeItem[]) => {
    setAttributes([...newAttributes]);
    handleShowSavePanel();
  };

  const fetchData = async () => {
    setLoading(true);

    try {
      const [attrKeys] = await Promise.all([
        searchAllAttributeKeys({
          filter: {
            type: { eq: EnumAttributeType.CENTER },
            enabled: { eq: true },
          },
          limit: DEFAULT_LIMIT,
        }),
        getCenter({ id: match.params.cid }),
        listCenterTypes({}),
        searchBuildingAuthorizations({
          limit: DEFAULT_LIMIT,
          filter: {
            entityId: { eq: match.params.cid },
            entityType: { eq: EnumPermissionEntity.CENTER },
          },
        }),
      ]);

      setAttributeKeys(attrKeys.items);
    } catch (error) {
      setIsAttributesLoaded(true);
    } finally {
      setLoading(false);
    }
  };

  if (!center) {
    return null;
  }

  const type = _.find(fieldsToUpdate, ["field", "type"]);
  const centerTypeItems: Item[] | undefined = getCenterTypeItems(
    intl,
    centerTypes.items
  );

  let selectedCenterType = center.type;
  if (type) {
    const centerType = centerTypes.items?.find(c => c.id === type.value);
    selectedCenterType = centerType;
  }

  const country = countries.find(c => c.name === center.country);

  const description: FormValue[] = [
    {
      id: "name",
      name: "name",
      type: "text",
      label: "page.center.name",
      placeholder: "page.center.name",
      value: center?.name,
      translatable: true,
      required: true,
      useIntl: true,
    },
    {
      id: "type",
      name: "type",
      type: "select",
      label: "page.center.type",
      placeholder: "page.center.type",
      required: true,
      items: centerTypeItems,
      useIntl: true,
      values: [
        {
          id: center?.type && center?.type?.id ? center?.type.id : "",
          name:
            center?.type && center?.type.name
              ? getTranslation(center?.type.name)
              : center?.type?.type,
        },
      ],
      children: [
        <Button
          key="btn-add-center-type-key"
          id="btn-add-center-type"
          name="btn-add-center-type"
          item={null}
          type="button"
          outline
          onClick={() => {
            setAction(EnumAction.CREATE);
            setModalCenterTypeOpen(true);
          }}
        >
          <IntlMessages id="page.center.add.center.type" />
        </Button>,
        <div key="div-modify-center-type-key" className="mt-4">
          <button
            id="btn-modify-center-type"
            name="btn-modify-center-type"
            data-cy="btn-modify-center-type"
            data-testid={getCypressTestId(center.type)}
            type="button"
            onClick={() => {
              setAction(EnumAction.UPDATE);
              setModalCenterTypeOpen(true);
            }}
            className="inline-flex items-center border border-transparent text-12px leading-5 font-medium bg-transparent text-ground-gray-300 hover:text-ground-blue-100 focus:outline-none active:text-ground-gray-300 transition ease-in-out duration-150"
          >
            <span>
              <IntlMessages id="page.center.update.center.type" />
            </span>
          </button>
        </div>,
      ],
    },
    {
      id: "description",
      name: "description",
      type: "textarea",
      label: "general.description",
      placeholder: "general.description",
      value: center?.description,
      translatable: true,
      useIntl: true,
    },
  ];

  const info: FormValue[] = [
    {
      id: "email",
      name: "email",
      type: "text",
      label: "general.email",
      placeholder: "general.email",
      value: center?.email,
      useIntl: true,
    },
    {
      id: "phone",
      name: "phone",
      type: "text",
      label: "general.phone",
      placeholder: "general.phone",
      value: center?.phone,
      useIntl: true,
    },
    {
      id: "address",
      name: "address",
      type: "text",
      label: "general.address",
      placeholder: "general.address",
      value: center?.address,
      required: true,
      useIntl: true,
    },
    {
      id: "zipCode",
      name: "zipCode",
      type: "text",
      label: "general.zipcode",
      placeholder: "general.zipcode",
      value: center?.zipCode,
      required: true,
      useIntl: true,
    },
    {
      id: "city",
      name: "city",
      type: "text",
      label: "general.city",
      placeholder: "general.city",
      value: center?.city,
      required: true,
      useIntl: true,
    },
    {
      id: "country",
      name: "country",
      type: "select",
      label: "general.country",
      placeholder: "general.country",
      required: true,
      items: countries,
      values: [
        {
          id: country?.id,
          name: country?.name,
        },
      ],
      useIntl: true,
    },
    {
      id: "latitude",
      name: "latitude",
      type: "number",
      step: 0.0000001,
      min: -90,
      max: 90,
      label: "page.center.latitude",
      placeholder: "page.center.latitude",
      required: true,
      value: center && center?.location?.lat ? center?.location?.lat : "",
      useIntl: true,
    },
    {
      id: "longitude",
      name: "longitude",
      type: "number",
      step: 0.0000001,
      min: -180,
      max: 180,
      label: "page.center.longitude",
      placeholder: "page.center.longitude",
      required: true,
      value: center && center?.location?.lon ? center?.location?.lon : "",
      useIntl: true,
    },
    {
      id: "timezone",
      name: "timezone",
      type: "text",
      label: "page.center.timezone",
      placeholder: "page.center.timezone",
      value: center.timezone || "",
      useIntl: true,
      disabled: true,
    },
  ];

  let locationMap: JSX.Element | null = null;

  if (center?.location?.lat && center?.location?.lon) {
    locationMap = (
      <SimpleMap
        location={{
          lat: center.location.lat,
          lng: center.location.lon,
        }}
        zoom={15}
      />
    );
  }

  const classNameDeleteButton = classnames(
    "inline-flex items-center px-4 py-2 border a rounded-5 focus:outline-none transition ease-in-out duration-150",
    {
      "border-transparent text-white bg-red-500 hover:bg-white hover:text-red-500 hover:border-1 hover:border-red-500 focus:border-red-500 active:bg-red-500 active:text-white":
        true,
    }
  );

  const requiredAttributes = getRequiredAttributes(
    filteredAttributeKeys,
    attributes
  );

  return loading || !isAttributesLoaded || !center ? (
    <div className="loading" />
  ) : (
    <div className="px-8 py-8">
      <Helmet>
        <title>
          {intl.formatMessage({
            id: "page.center.update.document.title",
          })}
        </title>
      </Helmet>
      <div className="bg-ground-black-100 rounded">
        <div className="flex flex-col px-12 pt-10">
          <span className="text-ground-gray-100 text-16px">
            <IntlMessages id="page.center.photos" />
          </span>
          <span className="text-ground-gray-100 text-14px leading-5">
            <IntlMessages id="general.image.max.size" />
          </span>
        </div>
        <div className="flex justify-center">
          <Carousel
            center={center}
            pictures={pictures}
            onAddImage={handleAddPicture}
            onRemoveImage={handleRemovePicture}
          />
        </div>
      </div>
      <div className="bg-white py-20px">
        <CardInformation
          item={center}
          title="page.center.update.title"
          content={description}
          onChange={addFieldToUpdate}
          ref={generalFormRef}
        />
        <CardHour
          item={center}
          daySlots={daySlots}
          exceptionalOpeningHours={exceptionalOpeningHours}
          onChangeHours={handleChangeHours}
          onChangeExceptionalOpeningHours={handleChangeExceptionalOpeningHours}
        />
        <CardClosedDays
          item={center}
          closedDays={closedDays}
          onChangeClosedDays={handleChangeClosedDays}
        />
        {canManageBuildingAuthorizations(me) && (
          <Block>
            <HeaderAdmin
              item={center}
              title="page.center.administrators.title"
              center={center}
              onAddAuthorization={onAddAuthorization}
              onRemoveAuthorization={onRemoveAuthorization}
              items={authorizations}
            />
            <ListAdmin
              items={authorizations}
              onRemoveAuthorization={onRemoveAuthorization}
            />
          </Block>
        )}
        <CardInformation
          item={center}
          title="page.center.subtitle"
          content={info}
          additional={locationMap}
          onChange={addFieldToUpdate}
          ref={infoFormRef}
        />
        <CardAttributes
          {...props}
          item={center}
          title="general.param.attributes"
          attributes={attributes.concat(requiredAttributes)}
          attributeKeys={filteredAttributeKeys}
          onChange={handleChangeAttribute}
          onDelete={handleChangeAttribute}
          zeroMessage="page.center.attributes.empty"
          type={EnumAttributeType.CENTER}
          ref={attributesFormRef}
        />
        <Display paths={["feature.building.rating"]}>
          <CardRatings ratingValue={centerRating} />
        </Display>
        {canDeleteBuilding(me) && (
          <div className="flex justify-end px-2">
            <button
              id="btn-delete-center"
              name="btn-delete-center"
              data-cy="btn-delete-center"
              data-testid={getCypressTestId(center)}
              type="button"
              onClick={() => setShowDeleteModal(!showDeleteModal)}
              className={classNameDeleteButton}
            >
              <span className="text-center">
                <IntlMessages id="general.delete" />
              </span>
            </button>
          </div>
        )}
      </div>
      <ModalCreateCenterType
        {...props}
        isOpen={modalCenterTypeOpen}
        toggle={() => {
          setModalCenterTypeOpen(!modalCenterTypeOpen);
        }}
        action={action}
        onRequestClose={() => setModalCenterTypeOpen(!modalCenterTypeOpen)}
        type={action === EnumAction.UPDATE ? selectedCenterType : null}
      />
      <ConfirmModal
        item={center}
        isOpen={showDeleteModal}
        toggle={() => setShowDeleteModal(!showDeleteModal)}
        onRequestClose={() => setShowDeleteModal(!showDeleteModal)}
        handleConfirm={() => handleDelete(center.id)}
        content={<IntlMessages id="page.center.delete.center" />}
      />
      <SavePanel
        label="general.panel.save"
        visible={togglePanel}
        onCancel={handleTogglePanel}
        onSave={handleSavePanel}
      />
    </div>
  );
};

export default injectIntl(CenterUpdate);
