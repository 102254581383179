import { AxiosResponse } from "axios";
import axiosBackend from "../../../../../axiosBackend";
import {
  CreateIncidentReasonOpts,
  CreateIncidentStatusOpts,
  CreateIncidentTypeOpts,
  Incident,
  IncidentComment,
  IncidentReason,
  IncidentType,
  ListIncidentStatusOpts,
  ListIncidentTypesOpts,
  PinIncidentOpts,
  SearchableIncidentsFilterInput,
  UpdateIncidentReasonOpts,
  UpdateIncidentsOpts,
  UpdateIncidentStatusOpts,
  UpdateIncidentTypeOpts,
  ViewIncidentsOpts,
} from "../../../models/Api/Incidents";
import {
  IncidentStatus,
  CommentIncidentsOpts,
} from "../../../models/Api/Incidents/index";
import {
  IGetResponse,
  IListMediaResponse,
  IListResponse,
} from "../../../models/Api";

export const getIncident = async (
  id: string
): Promise<AxiosResponse<IGetResponse<Incident>>> => {
  return axiosBackend.get(`/incidents/${id}`);
};

export const updateIncident = async ({
  id,
  ...payload
}: UpdateIncidentsOpts): Promise<AxiosResponse<IGetResponse<Incident>>> => {
  return axiosBackend.put(`/incidents/${id}`, payload);
};

export const viewIncident = async ({
  id,
}: ViewIncidentsOpts): Promise<AxiosResponse<IGetResponse<Incident>>> => {
  return axiosBackend.post(`/incidents/${id}/view`);
};

export const commentIncident = async ({
  id,
  ...payload
}: CommentIncidentsOpts): Promise<
  AxiosResponse<IGetResponse<IncidentComment>>
> => {
  return axiosBackend.post(`/incidents/${id}/comments`, payload);
};

export const pinIncident = async ({
  id,
}: PinIncidentOpts): Promise<AxiosResponse<IGetResponse<Incident>>> => {
  return axiosBackend.post(`/incidents/${id}/pin`);
};

export const unpinIncident = async ({
  id,
}: PinIncidentOpts): Promise<AxiosResponse<IGetResponse<Incident>>> => {
  return axiosBackend.post(`/incidents/${id}/unpin`);
};

export const listIncidents = async (
  buildingId: string,
  offset: number,
  limit: number,
  filter?: SearchableIncidentsFilterInput
): Promise<AxiosResponse<IListResponse<Incident>>> => {
  const params = {
    offset,
    limit,
  };

  if (filter) {
    const keys = Object.keys(filter);
    keys.forEach(key => {
      const operator = Object.keys(filter[key]);
      if (operator) {
        const value = filter[key][operator];
        if (operator.includes("between")) {
          value.forEach((val: string, index: number) => {
            params[`${key}[${operator}][${index}]`] = val;
          });
        } else {
          if (value !== null && value !== "" && value !== undefined) {
            params[`${key}[${operator}]`] = value;
          }
        }
      }
    });
  }

  return axiosBackend.get(`/incidents/buildings/${buildingId}`, { params });
};

export const exportIncidents = async (
  buildingId: string,
  offset: number,
  limit: number,
  filter?: SearchableIncidentsFilterInput
): Promise<AxiosResponse<IListResponse<Incident>>> => {
  const params = { offset, limit };

  if (filter) {
    const keys = Object.keys(filter);
    keys.forEach(key => {
      const operator = Object.keys(filter[key]);
      if (operator) {
        const value = filter[key][operator];
        if (operator.includes("between")) {
          value.forEach((val: string, index: number) => {
            params[`${key}[${operator}][${index}]`] = val;
          });
        } else {
          if (value !== null && value !== "" && value !== undefined) {
            params[`${key}[${operator}]`] = value;
          }
        }
      }
    });
  }

  return axiosBackend.get(`/export/incidents/buildings/${buildingId}`, {
    params,
  });
};

export const listIncidentStatus = async (
  payload: ListIncidentStatusOpts | undefined
): Promise<AxiosResponse<IListResponse<IncidentStatus>>> => {
  return axiosBackend.get("/incidents/status", { params: payload });
};

export const listIncidentTypes = async (
  payload: ListIncidentTypesOpts | undefined
): Promise<AxiosResponse<IListResponse<IncidentType>>> => {
  return axiosBackend.get("/incidents/types", { params: payload });
};

export const listIncidentReasons = async (
  id: string,
  offset: number,
  limit: number
): Promise<AxiosResponse<IListResponse<IncidentReason>>> => {
  return axiosBackend.get(
    `/incidents/types/${id}/reasons?offset=${offset}&limit=${limit}`
  );
};

export const getIncidentStatus = async (
  id: string
): Promise<AxiosResponse<IGetResponse<IncidentStatus>>> => {
  return axiosBackend.get(`/incidents/status/${id}`);
};

export const createIncidentStatus = async (
  payload: CreateIncidentStatusOpts
): Promise<AxiosResponse<IGetResponse<IncidentStatus>>> => {
  return axiosBackend.post("/incidents/status", payload);
};

export const updateIncidentStatus = async (
  payload: UpdateIncidentStatusOpts
): Promise<AxiosResponse<IGetResponse<IncidentStatus>>> => {
  const { id, ...params } = payload;

  return axiosBackend.put(`/incidents/status/${id}`, params);
};

export const deleteIncidentStatus = async (
  id: number
): Promise<AxiosResponse<IGetResponse<IncidentStatus>>> => {
  return axiosBackend.delete(`/incidents/status/${id}`);
};

export const getIncidentType = async (
  id: string
): Promise<AxiosResponse<IGetResponse<IncidentType>>> => {
  return axiosBackend.get(`/incidents/types/${id}`);
};

export const createIncidentType = async (
  payload: CreateIncidentTypeOpts
): Promise<AxiosResponse<IGetResponse<IncidentType>>> => {
  return axiosBackend.post("/incidents/types", payload);
};

export const updateIncidentType = async (
  payload: UpdateIncidentTypeOpts
): Promise<AxiosResponse<IGetResponse<IncidentType>>> => {
  const { id, ...params } = payload;

  return axiosBackend.put(`/incidents/types/${id}`, params);
};

export const deleteIncidentType = async (
  id: number
): Promise<AxiosResponse<IGetResponse<IncidentType>>> => {
  return axiosBackend.delete(`/incidents/types/${id}`);
};

export const getIncidentReason = async (
  typeId: string,
  reasonId: string
): Promise<AxiosResponse<IGetResponse<IncidentReason>>> => {
  return axiosBackend.get(`/incidents/types/${typeId}/reasons/${reasonId}`);
};

export const createIncidentReason = async (
  typeId: string,
  payload: CreateIncidentReasonOpts
): Promise<AxiosResponse<IGetResponse<IncidentReason>>> => {
  return axiosBackend.post(`/incidents/types/${typeId}/reasons`, payload);
};

export const updateIncidentReason = async (
  typeId: string,
  payload: UpdateIncidentReasonOpts
): Promise<AxiosResponse<IGetResponse<IncidentReason>>> => {
  const { id, ...params } = payload;

  return axiosBackend.put(`/incidents/types/${typeId}/reasons/${id}`, params);
};

export const deleteIncidentReason = async (
  typeId: string,
  reasonId: number
): Promise<AxiosResponse<IGetResponse<IncidentReason>>> => {
  return axiosBackend.delete(`/incidents/types/${typeId}/reasons/${reasonId}`);
};

export const listIncidentComments = async (
  incidentId: string,
  offset?: number,
  limit?: number
): Promise<AxiosResponse<IListResponse<IncidentComment>>> => {
  return axiosBackend.get(`/incidents/${incidentId}/comments`, {
    params: { offset, limit },
  });
};

export const listMedia = async (
  id: string
): Promise<AxiosResponse<IListMediaResponse>> => {
  return axiosBackend.get(`/incidents/${id}/media`);
};
