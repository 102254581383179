import React, { useMemo, useState } from "react";
import { IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import { FieldAttributes } from "formik";
import { toast } from "react-toastify";
import Block from "../../../../components/Tailwind/Block";
import Header from "../../../../components/Tailwind/Block/Header";
import { EnumPermissionEntity } from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import DefaultForm, {
  AdditionalFieldAttributes,
} from "../../../../components/Form";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import overrideClasses from "../../../../utils/overrideClasses";
import history from "../../../../history";
import { EnumPaths } from "../../../../utils/navigation";
import Footer from "../../../../components/Tailwind/Block/Footer";
import CustomDatePicker from "../../../../components/Tailwind/DatePicker";
import VisitorsComponent from "../../../../components/Tailwind/Visitors";
import { VisitorInfo } from "../../../../components/Tailwind/Visitors/VisitorItem";
import UserComponent, {
  UserFormData,
  UserType,
} from "../../booking/book/form-user-component";
import {
  CreateVisitHostOpts,
  CreateVisitVisitorOpts,
} from "../../../../lib/ground-aws-graphql-core/models/Api/Visits";
import {
  DATE_PICKER_FORMAT_DAY_HOUR,
  getTodayInCenterTimezone,
  isBefore,
} from "../../../../utils/config";
import InputDatePicker from "../../../../components/InputDatePicker";

interface Props {
  match: Match<{ cid: string; id?: string }>;
  intl: IntlShape;
}

const VisitForm = (props: Props): JSX.Element => {
  const { match, intl } = props;

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const emptyVisitor: VisitorInfo = { index: 0 };

  const currentDate = getTodayInCenterTimezone(centerTimezone);

  const [loading, setLoading] = useState(false);
  const [visitDate, setVisitDate] = useState<Date | null>(null);
  const [userFormData, setUserFormData] = useState<UserFormData | null>(null);
  const [visitors, setVisitors] = useState<VisitorInfo[]>([emptyVisitor]);

  const createVisit = GroundGraphqlContextStore.useStoreActions(
    actions => actions.visits.createVisit
  );

  const nonEmptyVisitors = visitors.filter(v => v.visitorInfo);

  const doesUserExist = !!(
    (userFormData?.choice === UserType.USER && userFormData?.user?.id) ||
    (userFormData?.choice === UserType.ACCOUNT_LESS &&
      userFormData?.accountlessUser?.firstname &&
      userFormData?.accountlessUser?.lastname &&
      userFormData?.accountlessUser?.email)
  );

  const handleSubmit = () => {
    if (visitDate && isBefore(visitDate, currentDate)) {
      toast(intl.formatMessage({ id: "page.visit.create.visit.error.date" }), {
        type: "error",
      });

      return;
    }

    const visitorsToAdd = nonEmptyVisitors.reduce<CreateVisitVisitorOpts[]>(
      (visAcc, v) => {
        if (v.visitorInfo)
          visAcc.push({
            first_name: v.visitorInfo.firstname,
            last_name: v.visitorInfo.lastname,
            email: v.visitorInfo.email,
            company_name: v.visitorInfo.company,
          });

        return visAcc;
      },
      []
    );

    if (visitorsToAdd.length > 0 && doesUserExist) {
      setLoading(true);

      let host: CreateVisitHostOpts | undefined = undefined;
      if (
        userFormData.accountlessUser?.firstname &&
        userFormData.accountlessUser?.lastname &&
        userFormData.accountlessUser?.email
      ) {
        host = {
          first_name: userFormData.accountlessUser.firstname,
          last_name: userFormData.accountlessUser.lastname,
          email: userFormData.accountlessUser.email,
        };
      }

      createVisit({
        building_id: match.params.cid,
        visitors: visitorsToAdd,
        date: visitDate?.toISOString(),
        host_id: userFormData?.user?.id,
        host,
      })
        .then(() => {
          history.push(
            `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${match.params.cid}/${EnumPaths.VISITS}`
          );
          toast(intl.formatMessage({ id: "page.visit.create.visit.success" }), {
            type: "success",
          });
        })
        .catch(() => {
          toast(intl.formatMessage({ id: "page.visit.create.visit.error" }), {
            type: "error",
          });
        })
        .finally(() => setLoading(false));
    }
  };

  const onChangeVisitor = (visitor: VisitorInfo) => {
    const visitorsClone = [...visitors];
    const visitorIndex = visitorsClone.findIndex(
      v => v.index === visitor.index
    );

    if (visitorIndex !== -1) {
      visitorsClone.splice(visitorIndex, 1, { ...visitor, deletable: true });

      const emptyVisitorExists = visitorsClone.some(v => !v.visitorInfo);

      if (!emptyVisitorExists)
        visitorsClone.unshift({ ...emptyVisitor, index: visitorsClone.length });
    }

    setVisitors(visitorsClone);
  };

  const onRemoveVisitor = (visitor: VisitorInfo) => {
    const visitorsClone = [...visitors];
    const visitorIndex = visitorsClone.findIndex(
      v => v.index === visitor.index
    );

    if (visitorIndex !== -1) {
      visitorsClone.splice(visitorIndex, 1);

      const visitorsIndexed = visitorsClone.map((v, i) => ({ ...v, index: i }));

      const emptyVisitorExists = visitorsIndexed.some(v => !v.visitorInfo);

      if (!emptyVisitorExists)
        visitorsIndexed.unshift({
          ...emptyVisitor,
          index: visitorsIndexed.length,
        });

      setVisitors(visitorsIndexed);
    } else setVisitors(visitorsClone);
  };

  const visitorField: FieldAttributes<AdditionalFieldAttributes> = useMemo(
    () => ({
      name: "visitors",
      label: "page.visit.create.visit.visitors",
      placeholder: "page.visit.create.visit.visitors",
      initialValue: undefined,
      required: true,
      fieldContainerClassName:
        "col-span-2 flex flex-col flex-no-wrap overflow-x-scroll",
      validate: () => {
        if (!nonEmptyVisitors.length) return "general.required.field";

        return "";
      },
      component: () => (
        <VisitorsComponent
          visitors={visitors}
          onValidate={onChangeVisitor}
          onRemove={onRemoveVisitor}
        />
      ),
    }),
    [visitors, nonEmptyVisitors]
  );

  const hostField: FieldAttributes<AdditionalFieldAttributes> = useMemo(
    () => ({
      name: "host",
      label: "page.visit.create.visit.host",
      placeholder: "page.visit.create.visit.host",
      initialValue: undefined,
      required: true,
      validate: () => {
        if (!doesUserExist) return "general.required.field";

        return "";
      },
      component: () => (
        <UserComponent
          userFormData={userFormData}
          onChange={ufd => {
            if (
              !ufd ||
              (ufd.choice === UserType.USER && !ufd.user?.id) ||
              ufd.choice === UserType.ACCOUNT_LESS
            ) {
              if (ufd?.choice === UserType.ACCOUNT_LESS) {
                setUserFormData({
                  accountlessUser: ufd.accountlessUser,
                  choice: ufd.choice,
                });
              } else {
                setUserFormData(ufd);
              }
            } else {
              setUserFormData(ufd);
            }
          }}
        />
      ),
    }),
    [userFormData]
  );

  const formFields: FieldAttributes<AdditionalFieldAttributes>[] = [
    visitorField,
    hostField,
  ];

  return !formFields ? (
    <div className="loading" />
  ) : (
    <>
      {/**
       * When we were loading the form was initialized again and we would lose all data
       * Show loading here in order to not lose the fields data after a loading
       */}
      <div className={overrideClasses({ loading })} />

      {/* Hide when loading */}
      <div className={overrideClasses("px-8 pb-32", { hidden: loading })}>
        <Block>
          <Header
            item={null}
            title="page.list.visits.create.visit"
            entity={EnumPermissionEntity.VISIT}
            className="border-b border-gray-200"
          />

          <DefaultForm fields={formFields} onSubmit={handleSubmit} disablePanel>
            {() => (
              <>
                <CustomDatePicker
                  name="date"
                  label="general.date"
                  placeholder="page.visit.create.visit.date.placeholder"
                  minDate={currentDate}
                  index={formFields.length}
                  selected={visitDate}
                  onChange={selectedDate => setVisitDate(selectedDate)}
                  dateFormat={DATE_PICKER_FORMAT_DAY_HOUR}
                  timeCaption={intl.formatMessage({ id: "general.hour" })}
                  showTimeSelect
                  timeIntervals={5}
                  isClearable
                  customInput={<InputDatePicker dataCy="visitDate" />}
                />

                <Footer item={null} index={formFields.length + 1} labels={[]} />
              </>
            )}
          </DefaultForm>
        </Block>
      </div>
    </>
  );
};

export default VisitForm;
