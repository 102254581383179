import { createIntl, createIntlCache } from "react-intl";
import { ErrorResponse } from "apollo-link-error";
import { toast } from "react-toastify";
import { getLocale } from "../../../../lang";
import { messageWithDefault } from "../../../../utils/messages/general";

/*
  Error codes
    - GRAPHQL_PARSE_FAILED
    - GRAPHQL_VALIDATION_FAILED
    - BAD_USER_INPUT: The GraphQL operation includes an invalid value for a field argument.
    - UNAUTHENTICATED: The server failed to authenticate with a required data source, such as a REST API.
    - FORBIDDEN
    - PERSISTED_QUERY_NOT_FOUND
    - PERSISTED_QUERY_NOT_SUPPORTED
    - INTERNAL_SERVER_ERROR : This is the default error code returned by any ApolloError instance that doesn't specify a different code.
*/

export const errorHandler = (error: ErrorResponse): void => {
  const currentAppLocale = getLocale(); //TODO:

  // This is optional but highly recommended
  // since it prevents memory leak
  const cache = createIntlCache();

  const intl = createIntl(
    {
      locale: currentAppLocale.locale,
      messages: currentAppLocale.messages,
    },
    cache
  );

  // Call imperatively
  intl.formatNumber(20);

  const { graphQLErrors } = error;

  if (graphQLErrors && graphQLErrors.length) {
    const currentAppLocale = getLocale(); //TODO:

    // This is optional but highly recommended
    // since it prevents memory leak
    const cache = createIntlCache();

    const intl = createIntl(
      {
        locale: currentAppLocale.locale,
        messages: currentAppLocale.messages,
      },
      cache
    );

    // Call imperatively
    intl.formatNumber(20);

    // default graphql errors
    graphQLErrors.forEach(error => {
      console.error({ "==> GraphQl error": error });

      const defaultGraphQlError = [
        "GRAPHQL_PARSE_FAILED",
        "GRAPHQL_VALIDATION_FAILED",
        "BAD_USER_INPUT",
        "FORBIDDEN",
        "PERSISTED_QUERY_NOT_FOUND",
        "PERSISTED_QUERY_NOT_SUPPORTED",
        "INTERNAL_SERVER_ERROR",
      ].includes(error.extensions?.code as string);

      const badRequestGraphQlError = ["BAD_REQUEST_EXCEPTION"].includes(
        error.extensions?.code as string
      );

      if (defaultGraphQlError) {
        toast(
          intl.formatMessage({
            id:
              error.message &&
              messageWithDefault(
                intl,
                error.message,
                "page.error.default.message"
              ),
          }),
          {
            type: "error",
          }
        );
      } else if (badRequestGraphQlError) {
        toast(
          intl.formatMessage({
            id: "page.error.default.message",
          }),
          {
            type: "error",
          }
        );
      } else if (error.message === "error.category.not.authorized") {
        toast(
          intl.formatMessage({
            id: "page.error.unauthorized.message",
          }),
          {
            type: "error",
          }
        );
      }
    });
  }
};

const fetchPolicy = "network-only";

export { fetchPolicy };
