import { IntlShape } from "react-intl";
import { InvalidParams } from "../../../lib/ground-aws-graphql-core/api/graphql/types";

interface Fields {
  [field: string]: string;
}

export const InvalidParamsFormatter = (
  inValidParams: InvalidParams,
  intl: IntlShape
): Fields => {
  return Object.keys(inValidParams).reduce((cum, field) => {
    const validation =
      inValidParams[field].validations && inValidParams[field].validations[0];
    if (validation) {
      cum[field] = intl.formatMessage(
        { id: `page.field.error.${validation.code}` },
        validation.context
      );
    }

    return cum;
  }, {} as Fields);
};

export const messageWithDefault = (
  intl: IntlShape,
  id: string,
  defaultMessage: string
): string => (intl.messages[id] ? id : defaultMessage);
