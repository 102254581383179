const base64ToArrayBuffer = (base64: string): ArrayBuffer => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes.buffer;
};

const arrayBufferToBlobUrl = (
  arrayBuffer: ArrayBuffer,
  mimeType = "image/png"
): string => {
  const blob = new Blob([arrayBuffer], { type: mimeType });

  return URL.createObjectURL(blob);
};

export const base64ToBlobUrl = (
  base64: string,
  mimeType = "image/png"
): string => arrayBufferToBlobUrl(base64ToArrayBuffer(base64), mimeType);

export const base64ToFile = (
  base64: string,
  filename: string,
  mimeType = "image/png"
): File => {
  const byteString = window.atob(base64);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  return new File([arrayBuffer], filename, { type: mimeType });
};
