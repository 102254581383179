import React, { useState, useEffect } from "react";
import _ from "lodash";
import { IntlShape } from "react-intl";
import { toast } from "react-toastify";
import { match as Match } from "react-router-dom";
import Footer from "../../../components/Tailwind/Block/Footer";
import GroundFormik from "../../../components/Tailwind/Form";
import Block from "../../../components/Tailwind/Block";
import Header from "../../../components/Tailwind/Block/Header";
import {
  FormValue,
  Image,
  ActionTypes,
  getCenterTypeItems,
  EnumAction,
} from "../../../utils/types";
import history from "../../../history";
import { EnumPaths } from "../../../utils/navigation";
import { getTranslation } from "../../../utils/translation";
import ImageGallery from "../../../components/Tailwind/ImageGallery";
import contextStore from "../../../redux/store";
import Button from "../../../components/Tailwind/Button";
import ModalCreateCenterType from "../creation/type";
import IntlMessages from "../../../utils/messages";
import { EnumPermissionEntity } from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import { GroundGraphqlContextStore } from "../../../lib/ground-aws-graphql-core";
import overrideClasses from "../../../utils/overrideClasses";
import { getCountries } from "../../../lang";
import { messageWithDefault } from "../../../utils/messages/general";

interface Props {
  match: Match;
  intl: IntlShape;
}

const CenterForm = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [pictures, setPictures] = useState([] as Image[]);
  const [togglePanel, setTogglePanel] = useState(false);
  const [values, setValues] = useState([] as FormValue[]);
  const [modalTypeOpen, setModalTypeOpen] = useState(false);
  const [action, setAction] = useState("");

  const { intl } = props;

  const centerTypes = GroundGraphqlContextStore.useStoreState(
    state => state.centerType.centerTypes
  );

  const listCenterTypes = GroundGraphqlContextStore.useStoreActions(
    actions => actions.centerType.listCenterTypes
  );

  const createCenterAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.center.createCenter
  );

  const createCenter = contextStore.useStoreActions(
    actions => actions.center.createCenter
  );

  const updateCenterAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.center.updateCenter
  );

  const center = GroundGraphqlContextStore.useStoreState(
    state => state.center.center
  );

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const countries = getCountries(locale);

  const handleAddPicture = (picture: string | ArrayBuffer, file: File) => {
    const items = [...pictures];
    items.push({
      picture,
      file,
      source: true,
      action: ActionTypes.TO_ADD,
    });
    setPictures(items);

    // show save panel
    handleShowSavePanel();
  };

  const handleRemovePicture = (el: Image) => {
    const items = pictures.filter(e => e.picture !== el.picture);
    if (el.source) {
      setPictures(items);
    } else {
      const picturesToDelete = [...items];
      picturesToDelete.push({
        ...el,
        action: ActionTypes.TO_DELETE,
      });
      setPictures(picturesToDelete);
    }

    // show save panel
    handleShowSavePanel();
  };

  const handleSubmit = formValues => {
    let submitValues = { ...formValues };
    submitValues.location = JSON.stringify({
      lat: submitValues.latitude ? parseFloat(submitValues.latitude) : 0,
      lon: submitValues.longitude ? parseFloat(submitValues.longitude) : 0,
    });

    submitValues = _.omit(submitValues, ["latitude", "longitude"]);

    if (submitValues.email === "") {
      submitValues = _.omit(submitValues, ["email"]);
    }
    const country = getCountries(locale).find(
      c => c.id === submitValues["country"]
    );
    submitValues.country = country?.name;

    setLoading(true);

    createCenter({
      center: null,
      values: submitValues,
      pictures,
      attributeKeys: null,
      attributes: null,
      enabled: false,
      callbacks: {
        updateCenter: updateCenterAction,
        createCenter: createCenterAction,
      },
    })
      .then(() => {
        history.push(`/${EnumPaths.ROOT}/${EnumPaths.CENTERS}`);
        toast(
          intl.formatMessage({
            id: "page.center.create.center.success",
          }),
          { type: "success" }
        );
      })
      .catch(err => {
        if (err.graphQLErrors?.[0]) {
          toast(
            intl.formatMessage({
              id: messageWithDefault(
                intl,
                `page.center.create.center.${err.graphQLErrors[0].message}`,
                "page.center.create.center.error"
              ),
            }),
            { type: "error" }
          );
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    listCenterTypes({}).finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setValues([
      {
        id: "name",
        name: "name",
        type: "text",
        label: "page.center.name",
        placeholder: "page.center.name",
        required: true,
        value: center && center?.name ? center?.name : "",
        translatable: true,
        useIntl: true,
      },
      {
        id: "centerTypeId",
        name: "centerTypeId",
        type: "select",
        label: "page.center.type",
        placeholder: "page.center.type",
        required: true,
        items: getCenterTypeItems(intl, centerTypes.items),
        useIntl: true,
        values: [
          {
            id: center?.type && center?.type?.id ? center?.type.id : "",
            name:
              center?.type && center?.type.name
                ? getTranslation(center?.type.name)
                : center?.type?.type,
          },
        ],
        children: [
          <Button
            key="btn-add-center-type-key"
            id="btn-add-center-type"
            name="btn-add-center-type"
            item={null}
            type="button"
            outline
            onClick={() => {
              setAction(EnumAction.CREATE);
              setModalTypeOpen(true);
            }}
          >
            <IntlMessages id="page.center.add.center.type" />
          </Button>,
        ],
      },
      {
        id: "description",
        name: "description",
        type: "textarea",
        label: "general.description",
        placeholder: "general.description",
        value: center?.description,
        translatable: true,
        useIntl: true,
      },
      {
        id: "phone",
        name: "phone",
        type: "text",
        label: "general.phone",
        placeholder: "general.phone",
        value: center && center?.phone ? center?.phone : "",
        useIntl: true,
      },
      {
        id: "email",
        name: "email",
        type: "email",
        label: "general.email",
        placeholder: "general.email",
        value: center && center?.email ? center?.email : "",
        useIntl: true,
      },
      {
        id: "address-search",
        name: "address",
        type: "text",
        label: "general.address",
        placeholder: "general.address",
        required: true,
        value: center && center?.address ? center?.address : "",
        useIntl: true,
      },
      {
        id: "zipCode",
        name: "zipCode",
        type: "text",
        label: "general.zipcode",
        placeholder: "general.zipcode",
        required: true,
        value: center && center?.zipCode ? center?.zipCode : "",
        useIntl: true,
      },
      {
        id: "city",
        name: "city",
        type: "text",
        label: "general.city",
        placeholder: "general.city",
        required: true,
        value: center && center?.city ? center?.city : "",
        useIntl: true,
      },
      {
        id: "country",
        name: "country",
        type: "select",
        label: "general.country",
        placeholder: "general.country",
        required: true,
        items: countries,
        values: [
          {
            id: center?.country,
            name: center?.country,
          },
        ],
        useIntl: true,
      },
      {
        id: "latitude",
        name: "latitude",
        type: "number",
        step: 0.0000001,
        min: -90,
        max: 90,
        label: "page.center.latitude",
        placeholder: "page.center.latitude",
        required: true,
        value: center && center?.location?.lat ? center?.location?.lat : "",
        useIntl: true,
      },
      {
        id: "longitude",
        name: "longitude",
        type: "number",
        step: 0.0000001,
        min: -180,
        max: 180,
        label: "page.center.longitude",
        placeholder: "page.center.longitude",
        required: true,
        value: center && center?.location?.lon ? center?.location?.lon : "",
        useIntl: true,
      },
    ]);
  }, [centerTypes]);

  const handleShowSavePanel = () => {
    setTogglePanel(true);
  };

  const handleHideSavePanel = () => {
    setTogglePanel(false);
  };

  return (
    <>
      {/**
       * When we were loading the form was initialized again and we would lose all data
       * Show loading here in order to not lose the fields data after a loading
       */}
      <div className={overrideClasses({ loading })} />

      {/* Hide when loading */}
      <div className={overrideClasses("px-8", { hidden: loading })}>
        <Block>
          <Header
            item={center}
            title="page.center.create.title"
            entity={EnumPermissionEntity.CENTER}
            className="border-b border-gray-200"
          />
          <ModalCreateCenterType
            {...props}
            isOpen={modalTypeOpen}
            toggle={() => {
              setModalTypeOpen(!modalTypeOpen);
            }}
            action={action}
            type={null}
          />
          <GroundFormik
            item={center}
            values={values}
            countries={countries}
            onSubmit={handleSubmit}
            onChange={handleShowSavePanel}
            showSavePanel={togglePanel}
            onHideSavePanel={handleHideSavePanel}
          >
            <ImageGallery
              entity={center}
              label="general.image.gallery.photos"
              index={values.length}
              max={5}
              images={pictures}
              onAddImage={handleAddPicture}
              onRemoveImage={handleRemovePicture}
            />
            <Footer
              item={center}
              index={values.length + 1}
              labels={["general.cancel", "general.delete"]}
            />
          </GroundFormik>
        </Block>
      </div>
    </>
  );
};

export default CenterForm;
