import React from "react";
import classnames from "classnames";
import { toast } from "react-toastify";
import { injectIntl, IntlShape } from "react-intl";
import { getCypressTestId } from "../../../utils/config";
import { Center } from "../../../lib/ground-aws-graphql-core/models/Center";
import { News } from "../../../lib/ground-aws-graphql-core/models/News";
import { Product } from "../../../lib/ground-aws-graphql-core/models/Product";
import { Service } from "../../../lib/ground-aws-graphql-core/models/Service";
import { Provider } from "../../../lib/ground-aws-graphql-core/models/Provider";
import { Event } from "../../../lib/ground-aws-graphql-core/models/Event";
import Add from "../Button/Add";

interface Props {
  item: Product | Provider | Center | Service | News | Event | null;
  label: string;
  onAddImage: (result: string | ArrayBuffer, file: File) => void;
  intl: IntlShape;
  className?: string;
}

// Weight = 4 Mo
const MAX_FILE_SIZE = 4 * 1024 * 1024;

const ImageInput = (props: Props): JSX.Element => {
  const { item, label, onAddImage, intl, className } = props;

  let inputElement: HTMLInputElement | null;

  const handleClick = () => {
    inputElement?.click();
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files: File[] = Array.from(e.target.files);

      let isFileWeightOk = true;
      files.forEach(file => {
        // If the weight of the file is greater than 4 Mo we return an error message
        if (file.size > MAX_FILE_SIZE) isFileWeightOk = false;
      });

      if (!isFileWeightOk) {
        toast(intl.formatMessage({ id: "general.image.max.size.error" }), {
          type: "error",
        });

        return;
      }

      files.forEach(file => {
        const reader = new FileReader();
        reader.onload = event => {
          if (event && event.target && event.target.result) {
            onAddImage(event.target.result, file);
          }
        };

        reader.readAsDataURL(file);
      });
    }
  };

  const custom = className || "";
  const containerClassName = classnames(
    "flex items-center justify-center rounded-3 bg-transparent border border-ground-blue-100 border-dashed h-140px w-full",
    custom
  );

  return (
    <button className={containerClassName} onClick={handleClick} type="button">
      <Add label={label} />

      <input
        id="file"
        type="file"
        accept=".jpg, .jpeg, .png"
        className="hidden"
        data-cy="block-input-file"
        data-testid={getCypressTestId(item)}
        onChange={handleChangeFile}
        ref={input => {
          inputElement = input;
        }}
      />
    </button>
  );
};

export default injectIntl(ImageInput);
