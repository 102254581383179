import React, { useEffect } from "react";
import { injectIntl, IntlShape } from "react-intl";
import images from "../../images";
import { getChangeLogWidgetKey } from "../../utils/config";
import "./styles.css";

interface Props {
  intl: IntlShape;
}

const ChangeLogWidget = (props: Props): JSX.Element => {
  useEffect(() => {
    const intl = props.intl;
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://cdn.headwayapp.co/widget.js";
    document.head.appendChild(script);

    const HWConfig = {
      selector: ".headwayapp", // CSS selector where to inject the badge
      account: getChangeLogWidgetKey(),
      translations: {
        title: intl.formatMessage({
          id: "general.changelog.latest.update",
        }),
        readMore: intl.formatMessage({
          id: "general.changelog.read.more",
        }),
        footer: `${intl.formatMessage({
          id: "general.changelog.find.out.more",
        })} 👉`,
      },
    };

    script.onload = function () {
      (window as any).Headway.init(HWConfig);
    };
  }, []);

  return (
    <button
      id="change-log"
      name="change-log"
      data-cy="change-log"
      className="headwayapp flex text-sm border-2 ml-3 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out bg-gray-100"
    >
      <img className="h-7 w-7 rounded-full" src={images.gift} alt="" />
    </button>
  );
};

export default injectIntl(ChangeLogWidget);
