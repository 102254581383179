import React, { useEffect, useState } from "react";
import { IntlShape } from "react-intl";
import { FieldAttributes } from "formik";
import { match as Match } from "react-router-dom";
import { toast } from "react-toastify";
import Block from "../../../../../../components/Tailwind/Block";
import Header from "../../../../../../components/Tailwind/Block/Header";
import DefaultForm, {
  AdditionalFieldAttributes,
} from "../../../../../../components/Form";
import overrideClasses from "../../../../../../utils/overrideClasses";
import { GroundGraphqlContextStore } from "../../../../../../lib/ground-aws-graphql-core";
import { isString } from "../../../../../../utils/types";
import history from "../../../../../../history";
import { EnumPaths } from "../../../../../../utils/navigation";

interface Props {
  match: Match<{ id: string; reasonId?: string }>;
  intl: IntlShape;
  edition: boolean;
}

const IncidentReasonForm = (props: Props): JSX.Element => {
  const { intl, match, edition } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [togglePanel, setTogglePanel] = useState(false);

  const getIncidentReason = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.getIncidentReason
  );

  const createIncidentReason = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.createIncidentReason
  );

  const updateIncidentReason = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.updateIncidentReason
  );

  const setIncidentReason = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.setIncidentReason
  );

  const incidentReason = GroundGraphqlContextStore.useStoreState(
    state => state.incidents.incidentReason
  );

  const formFields: FieldAttributes<AdditionalFieldAttributes>[] = [
    {
      name: "code",
      label: "page.incident.reason.code",
      placeholder: "page.incident.reason.code.placeholder",
      initialValue: incidentReason?.code,
      required: true,
    },
    {
      name: "label",
      label: "page.incident.reason.label",
      placeholder: "page.incident.reason.label.placeholder",
      initialValue: incidentReason?.label,
      required: true,
      translatable: true,
    },
  ];

  const fetchData = () => {
    setLoading(true);
    if (match.params.id && match.params.reasonId) {
      getIncidentReason({
        typeId: match.params.id,
        input: { id: match.params.reasonId },
      })
        .catch(() => {
          toast(
            intl.formatMessage({
              id: "page.incident.reason.get.error",
            }),
            {
              type: "error",
            }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();

    // Clean the event from the state when we unmount this component
    return () => {
      setIncidentReason(null);
    };
  }, []);

  const handleSubmit = (values: any) => {
    setLoading(true);
    const { label, ...others } = values;
    if (edition && incidentReason) {
      updateIncidentReason({
        typeId: match.params.id,
        input: {
          ...others,
          label: isString(label) ? JSON.parse(label) : label,
          id: incidentReason.id,
        },
      })
        .then(() => {
          history.push(
            `/${EnumPaths.ROOT}/${EnumPaths.INCIDENT_TYPES}/${match.params.id}/${EnumPaths.EDIT_MODE}`
          );
          toast(
            intl.formatMessage({
              id: "page.incident.reason.update.success",
            }),
            { type: "success" }
          );
        })
        .catch(() => {
          history.push(
            `/${EnumPaths.ROOT}/${EnumPaths.INCIDENT_TYPES}/${match.params.id}/${EnumPaths.EDIT_MODE}`
          );
          toast(
            intl.formatMessage({
              id: "page.incident.reason.update.error",
            }),
            {
              type: "error",
            }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      createIncidentReason({
        typeId: match.params.id,
        input: {
          ...others,
          label: isString(label) ? JSON.parse(label) : label,
        },
      })
        .then(() => {
          history.push(
            `/${EnumPaths.ROOT}/${EnumPaths.INCIDENT_TYPES}/${match.params.id}/${EnumPaths.EDIT_MODE}`
          );
          toast(
            intl.formatMessage({ id: "page.incident.reason.create.success" }),
            { type: "success" }
          );
        })
        .catch(() => {
          history.push(
            `/${EnumPaths.ROOT}/${EnumPaths.INCIDENT_TYPES}/${match.params.id}/${EnumPaths.EDIT_MODE}`
          );
          toast(
            intl.formatMessage({ id: "page.incident.reason.create.error" }),
            {
              type: "error",
            }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return loading ? (
    <div className="loading" />
  ) : (
    <>
      {/**
       * When we were loading the form was initialized again and we would lose all data
       * Show loading here in order to not lose the fields data after a loading
       */}
      <div className={overrideClasses({ loading })} />

      {/* Hide when loading */}
      <div className={overrideClasses("px-8 pb-32", { hidden: loading })}>
        <Block>
          <Header
            item={incidentReason}
            title={
              edition
                ? "page.list.incident.reasons.update.incident.reason"
                : "page.list.incident.reasons.create.incident.reason"
            }
            className="border-b border-gray-200"
          />

          <DefaultForm
            fields={formFields}
            onSubmit={handleSubmit}
            showPanel={togglePanel}
          />
        </Block>
      </div>
    </>
  );
};

export default IncidentReasonForm;
