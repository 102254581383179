import React, { useEffect, useState } from "react";
import { IntlShape } from "react-intl";
import { FieldAttributes } from "formik";
import { match as Match } from "react-router-dom";
import { toast } from "react-toastify";
import Block from "../../../../../components/Tailwind/Block";
import Header from "../../../../../components/Tailwind/Block/Header";
import DefaultForm, {
  AdditionalFieldAttributes,
} from "../../../../../components/Form";
import overrideClasses from "../../../../../utils/overrideClasses";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";
import { isString } from "../../../../../utils/types";
import history from "../../../../../history";
import { EnumPaths } from "../../../../../utils/navigation";
import ListIncidentReasons from "../incident-reasons";

interface Props {
  match: Match<{ id: string }>;
  intl: IntlShape;
  edition: boolean;
}

const IncidentTypeForm = (props: Props): JSX.Element => {
  const { intl, match, edition } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [togglePanel, setTogglePanel] = useState(false);

  const getIncidentType = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.getIncidentType
  );

  const createIncidentType = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.createIncidentType
  );

  const updateIncidentType = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.updateIncidentType
  );

  const setIncidentType = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.setIncidentType
  );

  const incidentType = GroundGraphqlContextStore.useStoreState(
    state => state.incidents.incidentType
  );

  const formFields: FieldAttributes<AdditionalFieldAttributes>[] = [
    {
      name: "code",
      label: "page.incident.type.code",
      placeholder: "page.incident.type.code.placeholder",
      initialValue: incidentType?.code,
      required: true,
    },
    {
      name: "label",
      label: "page.incident.type.label",
      placeholder: "page.incident.type.label.placeholder",
      initialValue: incidentType?.label,
      required: true,
      translatable: true,
    },
  ];

  const fetchData = () => {
    setLoading(true);
    if (match?.params.id) {
      getIncidentType({ id: match?.params.id })
        .catch(() => {
          toast(
            intl.formatMessage({
              id: "page.incident.type.get.error",
            }),
            {
              type: "error",
            }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();

    // Clean the event from the state when we unmount this component
    return () => {
      setIncidentType(null);
    };
  }, []);

  const handleSubmit = (values: any) => {
    setLoading(true);
    const { label, ...others } = values;
    if (edition && incidentType) {
      updateIncidentType({
        ...others,
        label: isString(label) ? JSON.parse(label) : label,
        id: incidentType.id,
      })
        .then(() => {
          history.push(`/${EnumPaths.ROOT}/${EnumPaths.INCIDENT_TYPES}`);
          toast(
            intl.formatMessage({
              id: "page.incident.type.update.success",
            }),
            { type: "success" }
          );
        })
        .catch(() => {
          history.push(`/${EnumPaths.ROOT}/${EnumPaths.INCIDENT_TYPES}`);
          toast(
            intl.formatMessage({
              id: "page.incident.type.update.error",
            }),
            {
              type: "error",
            }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      createIncidentType({
        ...others,
        label: isString(label) ? JSON.parse(label) : label,
      })
        .then(() => {
          history.push(`/${EnumPaths.ROOT}/${EnumPaths.INCIDENT_TYPES}`);
          toast(
            intl.formatMessage({ id: "page.incident.type.create.success" }),
            { type: "success" }
          );
        })
        .catch(() => {
          history.push(`/${EnumPaths.ROOT}/${EnumPaths.INCIDENT_TYPES}`);
          toast(intl.formatMessage({ id: "page.incident.type.create.error" }), {
            type: "error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return loading ? (
    <div className="loading" />
  ) : (
    <>
      {/**
       * When we were loading the form was initialized again and we would lose all data
       * Show loading here in order to not lose the fields data after a loading
       */}
      <div className={overrideClasses({ loading })} />

      {/* Hide when loading */}
      <div className={overrideClasses("px-8 pb-32", { hidden: loading })}>
        <Block>
          <Header
            item={incidentType}
            title={
              edition
                ? "page.list.incident.types.update.incident.type"
                : "page.list.incident.types.create.incident.type"
            }
            className="border-b border-gray-200"
          />

          <DefaultForm
            fields={formFields}
            onSubmit={handleSubmit}
            showPanel={togglePanel}
          />
        </Block>
        <ListIncidentReasons typeId={match.params.id} />
      </div>
    </>
  );
};

export default IncidentTypeForm;
