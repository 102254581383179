import { OperatorConfig } from "..";

// PREPROD
const sourcesConfig: OperatorConfig = {
  backoffice: {
    display: {
      "sidebar.menu.management.visits": true,
      "sidebar.menu.building.documents": true,
      "feature.ai": true,
    },
  },
};

export default sourcesConfig;
