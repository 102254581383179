import React from "react";
import images from "../../images";
import IntlMessages from "../../utils/messages";
import overrideClasses from "../../utils/overrideClasses";

interface Props {
  message: string;
  className?: string;
}

const AIText = (props: Props): JSX.Element => {
  const { message, className } = props;

  return (
    <div
      className={overrideClasses("flex items-center justify-center", className)}
    >
      <img src={images.sparkles} alt="sparkles" className="w-5 h-5 mr-1" />
      <span className="text-16px text-ground-black-100">
        <IntlMessages id={message} />
      </span>
    </div>
  );
};

export default AIText;
