import React, { useState, useEffect, useMemo } from "react";
import { Field, FieldAttributes, Form, Formik, FormikHelpers } from "formik";
import { ConnectedFocusError } from "focus-formik-error";
import { useIntl } from "react-intl";
import { FormikErrors, FormikProps } from "formik/dist/types";
import IntlMessages from "../../utils/messages";
import overrideClasses from "../../utils/overrideClasses";
import LocaleSwitcher from "../LocaleSwitcher";
import { useTranslatableField } from "../../utils/locale";
import contextStore from "../../redux/store";
import { getSupportedLocales } from "../../lang";
import FormSavePanel from "./FormSavePanel";
import { isString } from "../../utils/types";
import { EnumLocale } from "../../lib/ground-aws-graphql-core/api/graphql/types";

export type SelectOption = {
  value: string;
  label: string;
};

export interface AdditionalFieldAttributes {
  label: string;
  initialValue: any;
  required?: boolean;
  invalid?: boolean;
  options?: SelectOption[];
  translatable?: boolean;
  thirdColComponent?: (values, locale: EnumLocale) => JSX.Element;
  isLabel?: boolean;
  fieldContainerClassName?: string;
}

export interface IActions {
  setErrors: (
    errors: FormikErrors<FieldAttributes<AdditionalFieldAttributes>>
  ) => void;
}

interface Props {
  fields: FieldAttributes<AdditionalFieldAttributes>[];
  onSubmit: (values: any, actions?: IActions) => void;
  children?: any;
  showPanel?: boolean;
  disablePanel?: boolean;
  innerRef?: React.Ref<FormikProps<any>> | undefined;
}

const getInitialValues = (
  fields: FieldAttributes<AdditionalFieldAttributes>[]
): any =>
  fields.reduce(
    (initialValues, field) => ({
      ...initialValues,
      [field.name]: field.initialValue,
    }),
    {}
  );

const DEFAULT_TEXT_AREA_ROWS = 7;

const getEnumLocale = (language: string): EnumLocale => {
  const key = language.replace("-", "_");

  return EnumLocale[key];
};

export const getLocaleFromEnumLocale = (locale: EnumLocale): string => {
  return locale.replace("_", "-");
};

const DefaultForm = (props: Props): JSX.Element => {
  const { fields, onSubmit, children, showPanel, disablePanel, innerRef } =
    props;

  const locale = contextStore.useStoreState(state => state.settings.locale);

  const intl = useIntl();

  const filteredFields = useMemo(
    () =>
      fields.reduce(
        (
          modifiedFields: FieldAttributes<AdditionalFieldAttributes>[],
          currentField
        ) => {
          if (currentField.hidden) return [...modifiedFields];

          const defaultSelectOption = {
            value: "",
            label: intl.formatMessage({ id: "general.select" }),
          };

          if (
            currentField.options &&
            !currentField.options.includes(defaultSelectOption)
          ) {
            currentField.options.unshift(defaultSelectOption);
          }

          return [...modifiedFields, currentField];
        },
        []
      ),
    [fields]
  );

  const renderForm = formikProps => {
    const { isSubmitting, handleChange, values, touched, errors } = formikProps;

    const [isPanelVisible, setIsPanelVisible] = useState(false);

    useEffect(() => {
      if (showPanel && !disablePanel) setIsPanelVisible(true);
    }, [showPanel]);

    useEffect(() => {
      if (isSubmitting && !disablePanel) setIsPanelVisible(false);
    }, [isSubmitting]);

    const renderField = (
      field: FieldAttributes<AdditionalFieldAttributes>,
      index: number
    ) => {
      const {
        name,
        onChange: fieldOnChange,
        required,
        translatable,
        validate,
        label: fieldLabel,
        disabled,
        as: fieldAs,
        className: fieldClassName,
        fieldContainerClassName,
        placeholder,
        options,
        component,
        children: fieldChildren,
        thirdColComponent,
        // Keep initialValue despite the warning. It's because we need to get it out of the field so we can use otherProps without it
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        initialValue,
        isLabel,
        value: forcedFieldValue,
        ...otherProps
      } = field;

      const fieldValue = forcedFieldValue || values[name];

      const {
        value: localeValue,
        displayedValue,
        currentLocale,
        setCurrentLocale,
        onChange,
      } = useTranslatableField(
        translatable,
        fieldValue,
        e => {
          if (!disablePanel) setIsPanelVisible(true);
          if (fieldOnChange) fieldOnChange(e);
          else handleChange(e);
        },
        locale
      );

      const invalid = touched[name] && errors[name];

      const validateField = () => {
        if (required && !fieldValue && !validate)
          return <IntlMessages id="general.required.field" />;

        if (required && validate && validate(fieldValue))
          return <IntlMessages id={validate(fieldValue)} />;

        if (required && translatable) {
          try {
            const parsedField = isString(fieldValue)
              ? JSON.parse(fieldValue)
              : fieldValue;

            if (parsedField) {
              const localeValues = Object.values(parsedField);

              // Check if at least one value exists
              if (!localeValues.some(locValue => !!locValue))
                return <IntlMessages id="general.required.field" />;
            } else {
              return <IntlMessages id="general.required.field" />;
            }
          } catch (error) {
            return <IntlMessages id="general.required.field" />;
          }
        }

        return null;
      };

      return (
        <div
          key={name}
          className={overrideClasses(
            "grid grid-cols-3 gap-4 w-full px-8 py-2 items-center",
            {
              "bg-ground-white-200": index % 2 === 0,
            }
          )}
        >
          <label
            htmlFor={name}
            className="text-14px leading-5 text-ground-gray-100 my-2"
          >
            {isLabel && `${fieldLabel}`}
            {!isLabel && <IntlMessages id={fieldLabel} />}
            {required && " *"}
          </label>

          <div className={fieldContainerClassName}>
            <div className="relative">
              <Field
                className={overrideClasses(
                  {
                    "rounded-md shadow-sm form-input w-full text-14px leading-5 text-ground-black-100 placeholder-ground-gray-100":
                      field.type !== "checkbox",
                    "form-checkbox h-4 w-4 text-ground-green-100 transition duration-150 ease-in-out":
                      field.type === "checkbox",
                    "text-red-500 placeholder-red-300 border-red-300 focus:border-red-300 focus:shadow-outline-red":
                      invalid,
                    "bg-ground-white-200": disabled,
                    "form-select": fieldAs === "select",
                  },
                  fieldClassName
                )}
                {...otherProps}
                id={name}
                name={name}
                as={fieldAs}
                data-cy={name}
                // Set required to false and check it manually (otherwise we have the default Formik message)
                required={false}
                placeholder={
                  placeholder && intl.formatMessage({ id: placeholder })
                }
                value={displayedValue}
                onChange={onChange}
                rows={
                  fieldAs === "textarea" ? DEFAULT_TEXT_AREA_ROWS : undefined
                }
                component={component}
                validate={validateField}
                disabled={disabled}
                // Disable 'Enter' key in order to not submit the form when pressing this key
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
              >
                {options?.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Field>

              {field.type === "checkbox" && fieldChildren}

              {invalid && !fieldAs && !component && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <svg
                    className="h-5 w-5 text-red-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              )}
            </div>

            {/**
             * FIXME: Change check for displayedValue
             * This check is done to not display the edit button for a category
             * Find another way to do this
             *  */}
            {!!displayedValue && field.type !== "checkbox" && fieldChildren}

            {translatable && (
              <LocaleSwitcher
                currentLocale={currentLocale}
                setCurrentLocale={setCurrentLocale}
                locales={getSupportedLocales()}
                value={localeValue}
              />
            )}

            {invalid && (
              <p className="mt-2 text-11px text-red-600" id={`${name}-error`}>
                {errors[name]}
              </p>
            )}
          </div>

          {thirdColComponent &&
            thirdColComponent(formikProps, getEnumLocale(currentLocale))}
        </div>
      );
    };

    return (
      <Form className="flex flex-col">
        <ConnectedFocusError />

        {filteredFields.map(renderField)}

        {children && children(formikProps)}

        {!disablePanel && (
          <FormSavePanel
            visible={isPanelVisible}
            isSubmitting={isSubmitting}
            onCancel={() => setIsPanelVisible(false)}
          />
        )}
      </Form>
    );
  };

  const formikOnSubmit = (
    values: any,
    { setSubmitting, setErrors }: FormikHelpers<any>
  ) => {
    if (onSubmit) onSubmit(values, { setErrors });
    setSubmitting(false);
  };

  return (
    <Formik
      innerRef={innerRef}
      initialValues={getInitialValues(fields)}
      onSubmit={formikOnSubmit}
    >
      {renderForm}
    </Formik>
  );
};

export default DefaultForm;
