import React from "react";
import { withRouter } from "react-router-dom";
import NavItem from "./NavItem";
import NavItemImage from "./NavItemImage";
import NavLogo from "./NavLogo";
import NavUser from "./NavUser";
import images from "../../images";
import NavBurgerMenu from "./NavBurgerMenu";
import { EnumPaths } from "../../utils/navigation";
import history from "../../history";
import { Display } from "../../components/OperatorCustomization";
import { GroundAuthContextStore } from "../../lib/ground-aws-cognito-auth-core";
import LocaleSwitch from "../LocaleSwitch";

const Navbar = () => {
  const me = GroundAuthContextStore.useStoreState(
    state => state.authentication.me
  );

  const handleGoTo = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    path: string
  ) => {
    e.preventDefault();

    localStorage.removeItem("navItem");
    localStorage.removeItem("parentNavItem");

    history.push(`/${EnumPaths.ROOT}/${path}`);
  };

  return (
    <nav className="bg-ground-black-100 shadow fixed w-full z-50">
      <div className="px-2 sm:px-4 lg:px-8">
        <div className="flex justify-between h-20">
          <div className="flex px-2 lg:px-0">
            <NavLogo
              id="logo"
              src={
                me
                  ? me?.operator_id.includes("WOJO")
                    ? images.wojologo
                    : images.logo
                  : ""
              }
              onClick={() => {
                history.push("/");
              }}
            />
            <div className="hidden lg:ml-6 lg:flex">
              <Display paths={["header.centers"]}>
                <NavItemImage
                  id="menu-centers"
                  index={0}
                  label="navbar.menu.centers"
                  onMouseOutImage={images.buildings}
                  onMouseOverImage={images.buildings_white}
                  active={history.location.pathname.startsWith(
                    `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}`
                  )}
                  onClick={e => handleGoTo(e, EnumPaths.CENTERS)}
                />
              </Display>
              <Display paths={["header.providers"]}>
                <NavItemImage
                  index={1}
                  id="menu-providers"
                  label="navbar.menu.providers"
                  onMouseOutImage={images.providers}
                  onMouseOverImage={images.providers_white}
                  active={history.location.pathname.startsWith(
                    `/${EnumPaths.ROOT}/${EnumPaths.PROVIDERS}`
                  )}
                  className="ml-4"
                  onClick={e => handleGoTo(e, EnumPaths.PROVIDERS)}
                />
              </Display>
              <Display paths={["header.users"]}>
                <NavItemImage
                  index={2}
                  id="menu-users"
                  label="general.clients"
                  onMouseOutImage={images.customers}
                  onMouseOverImage={images.customers_white}
                  active={history.location.pathname.startsWith(
                    `/${EnumPaths.ROOT}/${EnumPaths.USERS}`
                  )}
                  className="ml-4"
                  onClick={e => handleGoTo(e, EnumPaths.USERS)}
                />
              </Display>
              <Display paths={["header.administrators"]}>
                <NavItemImage
                  index={3}
                  id="menu-administrators"
                  label="navbar.menu.administrators"
                  onMouseOutImage={images.admins}
                  onMouseOverImage={images.admins_white}
                  active={history.location.pathname.startsWith(
                    `/${EnumPaths.ROOT}/${EnumPaths.ADMINISTRATORS}`
                  )}
                  className="ml-4"
                  onClick={e => handleGoTo(e, EnumPaths.ADMINISTRATORS)}
                />
              </Display>
              <Display paths={["header.indicators"]}>
                <NavItemImage
                  index={4}
                  id="menu-indicators"
                  label="general.indicators"
                  onMouseOutImage={images.stats}
                  onMouseOverImage={images.stats_white}
                  active={history.location.pathname.startsWith(
                    `/${EnumPaths.ROOT}/${EnumPaths.INDICATORS}`
                  )}
                  className="ml-4"
                  onClick={e => handleGoTo(e, EnumPaths.INDICATORS_USERS)}
                />
              </Display>
              <Display paths={["header.parameters"]}>
                <NavItemImage
                  index={5}
                  id="menu-parameters"
                  label="general.parameters"
                  onMouseOutImage={images.settings}
                  onMouseOverImage={images.settings_white}
                  active={history.location.pathname.startsWith(
                    `/${EnumPaths.ROOT}/${EnumPaths.PARAMETERS}`
                  )}
                  className="ml-4"
                  onClick={e => handleGoTo(e, EnumPaths.PAYMENT_METHODS)}
                />
              </Display>
              <Display paths={["header.developers"]}>
                <NavItemImage
                  index={6}
                  id="menu-developers"
                  label="general.developers"
                  onMouseOutImage={images.dev}
                  onMouseOverImage={images.dev_white}
                  active={history.location.pathname.startsWith(
                    `/${EnumPaths.ROOT}/${EnumPaths.DEVELOPERS}`
                  )}
                  className="ml-4"
                  onClick={e => handleGoTo(e, EnumPaths.WEBHOOKS)}
                />
              </Display>
            </div>
          </div>
          <NavBurgerMenu />
          <div className="hidden lg:flex lg:items-center">
            <LocaleSwitch />
            {me && <NavUser user={me} />}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default withRouter(Navbar);
