import { getLocale } from "../../lang";
import { IMultiLanguage } from "../../lib/ground-aws-graphql-core/models/Api";

export const setTranslation = (
  text: string
): {
  [key: string]: string;
} => {
  const locale = localStorage.getItem("locale");
  const currentAppLocale = getLocale(locale);
  const result = {
    [currentAppLocale.locale]: text || "",
  };

  return result;
};

export const getFirstValue = (
  obj: Record<string, string>
): string | undefined => Object.values(obj).find(value => value !== "");

export const getTranslation = (
  initialValue: string | IMultiLanguage | null | undefined
): string => {
  if (!initialValue) return "";

  try {
    let finalValue = initialValue;
    if (typeof initialValue === "string") finalValue = JSON.parse(initialValue);
    if (typeof finalValue === "number")
      return (finalValue as number).toString();

    const locale = localStorage.getItem("locale");
    const currentAppLocale = getLocale(locale);

    return getTranslation(finalValue[currentAppLocale.locale]);
  } catch (error) {
    return initialValue.toString();
  }
};
