import { ErrorGraphQL } from "../../services/coreUtils";
/*
 *
 * PriceUnitVariation Model
 *
 */

import { Action, Thunk, action, thunk } from "easy-peasy";
import { Injections } from "../../store";
import { StoreModel } from "..";
import { Operator } from "../Operator";

import { ModelPriceUnitVariationFilterInput } from "../../api/graphql/types";

export interface PriceUnitVariation {
  id: string;
  operator?: Operator;
  key: string;
  name?: string;
  enabled?: boolean;
  markForDelete?: boolean;
}

interface SearchPriceUnitVariationsOpts {
  limit?: number;
  filter?: ModelPriceUnitVariationFilterInput;
  gql?: string | null;
}

export interface ModelPriceUnitVariationConnection {
  items: PriceUnitVariation[];
  total: number;
}

// Interface declaration
export interface PriceUnitVariationModel {
  priceUnitVariations: ModelPriceUnitVariationConnection;
  setPriceUnitVariations: Action<
    PriceUnitVariationModel,
    { data: ModelPriceUnitVariationConnection }
  >;
  searchAllPriceUnitVariations: Thunk<
    PriceUnitVariationModel,
    SearchPriceUnitVariationsOpts,
    Injections,
    StoreModel
  >;
}

export const priceUnitVariationModel: PriceUnitVariationModel = {
  priceUnitVariations: { items: [], total: 0 },
  setPriceUnitVariations: action(
    (state, payload: { data: ModelPriceUnitVariationConnection }) => {
      const { data } = payload;
      state.priceUnitVariations = data;
    }
  ),
  searchAllPriceUnitVariations: thunk(
    async (actions, payload, { injections }) => {
      const { priceUnitVariationService } = injections;
      const response =
        await priceUnitVariationService.searchAllPriceUnitVariations(payload);

      const errors = Object.prototype.hasOwnProperty.call(response, "errors");
      if (errors) return ErrorGraphQL(response);

      const data = response.data.searchAllPriceUnitVariations;
      actions.setPriceUnitVariations({ data });

      return data;
    }
  ),
};
