import { AxiosResponse } from "axios";
import axiosBackend from "../../../../../axiosBackend";
import { IGetResponse } from "../../../models/Api";
import {
  CreatePriceUnitVariationOpts,
  ListPriceUnitVariationsOpts,
  PriceUnitVariation,
  SetAsDefaultOpts,
  UpdatePriceUnitVariationOpts,
} from "../../../models/Api/PriceUnitVariations";

export interface IListResponse<T> {
  data: {
    items: [T];
    total: number;
    has_more: boolean;
    next_url: string | null;
  };
  success: boolean;
}

export const listPriceUnitVariations = async (
  payload: ListPriceUnitVariationsOpts
): Promise<AxiosResponse<IListResponse<PriceUnitVariation>>> => {
  const params = {
    offset: payload.offset,
    ...(payload.limit && {
      limit: payload.limit,
    }),
  };

  return axiosBackend.get("/price_unit_variations", { params });
};

export const createPriceUnitVariation = async (
  payload: CreatePriceUnitVariationOpts
): Promise<AxiosResponse<IGetResponse<PriceUnitVariation>>> => {
  return axiosBackend.post("/price_unit_variations", payload);
};

export const getPriceUnitVariation = async (
  id: string
): Promise<AxiosResponse<IGetResponse<PriceUnitVariation>>> => {
  return axiosBackend.get(`/price_unit_variations/${id}`);
};

export const updatePriceUnitVariation = async (
  payload: UpdatePriceUnitVariationOpts
): Promise<AxiosResponse<IGetResponse<PriceUnitVariation>>> => {
  const { id, ...params } = payload;

  return axiosBackend.put(`/price_unit_variations/${id}`, params);
};

export const deletePriceUnitVariation = async (
  id: string
): Promise<AxiosResponse<IGetResponse<PriceUnitVariation>>> => {
  return axiosBackend.delete(`/price_unit_variations/${id}`);
};

export const setAsDefault = async (
  payload: SetAsDefaultOpts
): Promise<AxiosResponse<IGetResponse<PriceUnitVariation>>> => {
  const { id } = payload;

  return axiosBackend.post(`/price_unit_variations/${id}/set_as_default`);
};
