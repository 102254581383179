import logo from "../assets/images/logo.svg";
import ground from "../assets/images/ground.svg";
import wojologo from "../assets/images/wojo-white-baseline.png";

import contact from "../assets/images/picto-contact.svg";
import notification from "../assets/images/notification.svg";
import search from "../assets/images/picto-search.svg";
import preview from "../assets/images/picto-preview.svg";
// Sidebar navigation
import nav_notification from "../assets/images/nav-notif.svg";
import nav_information from "../assets/images/nav-infos.svg";
import nav_dashboard from "../assets/images/nav-dashboard.svg";
import nav_catalog from "../assets/images/nav-catalog.svg";

import nav_catalog_2 from "../assets/images/nav-catalog-2.svg";
import nav_building_2 from "../assets/images/nav-building-2.svg";
import nav_communication_2 from "../assets/images/nav-communication-2.svg";
import nav_pilot_2 from "../assets/images/nav-pilot-2.svg";

import nav_news from "../assets/images/nav-news.svg";
import nav_connect from "../assets/images/nav-connect.svg";
import add from "../assets/images/picto-add.svg";
import addActive from "../assets/images/picto-add-active.svg";
import addInactive from "../assets/images/picto-add-inactive.svg";
import addBlue from "../assets/images/picto-add-blue.svg";
import closeBlue from "../assets/images/picto-close-blue.svg";
import trash from "../assets/images/picto-trash.svg";
import edit from "../assets/images/edit.svg";
import pencil from "../assets/images/pencil.svg";
import validate from "../assets/images/validate.svg";
import underConstruction from "../assets/images/under_construction.svg";
import dashboard from "../assets/images/dashboard.svg";
import waiting from "../assets/images/waiting.svg";
import pending from "../assets/images/pending.svg";
import sales from "../assets/images/sales.svg";
import completed from "../assets/images/completed.svg";
import cash from "../assets/images/cash.svg";
import validated from "../assets/images/validated.svg";
import bullet from "../assets/images/bullet.svg";
import room from "../assets/images/picto-meeting-room.svg";
import coworking from "../assets/images/picto-coworking.svg";
import office from "../assets/images/picto-office.svg";
import parking from "../assets/images/picto-parking.svg";
import accounts from "../assets/images/accounts.svg";
import totalAccounts from "../assets/images/total-accounts.svg";
import defaultCenter from "../assets/images/picto-center.svg";
import backBlack from "../assets/images/picto-back.svg";
import mapPin from "../assets/images/picto-pin.svg";
import searchWhite from "../assets/images/pict-search.svg";
import avatar from "../assets/images/avatar.svg";
import password from "../assets/images/password.svg";
import eye from "../assets/images/picto-eye.svg";
import eyeBlocked from "../assets/images/picto-eye-blocked.svg";
import cancel from "../assets/images/cancel.svg";
import details from "../assets/images/details.svg";
import deleteIcon from "../assets/images/delete.svg";
import userIcon from "../assets/images/user.svg";
import caseIcon from "../assets/images/case.svg";
import checked from "../assets/images/checked.svg";
import addIcon from "../assets/images/addIcon.svg";
import deskIcon from "../assets/images/desk.svg";
import padlock from "../assets/images/padlock.svg";
import padlockPrivatizable from "../assets/images/padlock_privatizable.svg";
import chevronDown from "../assets/images/chevron-down.svg";
import previous from "../assets/images/previous-icon.svg";
import next from "../assets/images/next-icon.svg";
import gift from "../assets/images/gift.svg";
import mail from "../assets/images/mail.svg";
import filterOn from "../assets/images/filter-on.svg";
import filterOff from "../assets/images/filter-off.svg";
import filterSearch from "../assets/images/filter-search.svg";
import alert from "../assets/images/alert.svg";
import pin from "../assets/images/pin.svg";
import pinned from "../assets/images/pinned.svg";
import logout from "../assets/images/logout.svg";
import bottomArrow from "../assets/images/bottom-arrow.svg";
import assistance from "../assets/images/assistance.svg";
import analytics from "../assets/images/analytics.svg";
import duplicate from "../assets/images/duplicate.svg";
import highPriority from "../assets/images/high-priority.svg";
import combined from "../assets/images/combined.svg";
import trendingUp from "../assets/images/trending-up.svg";
import trendingDown from "../assets/images/trending-down.svg";
import trendingFlat from "../assets/images/trending-flat.svg";
import users from "../assets/images/users.svg";
import ga from "../assets/images/ga.svg";
import checkIn from "../assets/images/check-in.svg";
import checkOut from "../assets/images/check-out.svg";
import realtime from "../assets/images/realtime.svg";
import webhook from "../assets/images/webhook.svg";
import paymentMethods from "../assets/images/payment-methods.svg";
import ok from "../assets/images/ok.svg";
import ko from "../assets/images/ko.svg";
import folderPlus from "../assets/images/folder-plus.svg";
import folderMinus from "../assets/images/folder-minus.svg";
import imageFile from "../assets/images/image-file.svg";
import pdfFile from "../assets/images/pdf-file.svg";
import wordFile from "../assets/images/word-file.svg";
import newIcon from "../assets/images/new.svg";
import appClients from "../assets/images/app-client.svg";
import attrKeys from "../assets/images/attribute.svg";
import priceUnitVariations from "../assets/images/price-unit-variation.svg";
import buildings from "../assets/images/buildings.svg";
import buildings_white from "../assets/images/buildings-white.svg";
import providers from "../assets/images/providers.svg";
import providers_white from "../assets/images/providers-white.svg";
import customers from "../assets/images/customers.svg";
import customers_white from "../assets/images/customers-white.svg";
import admins from "../assets/images/admins.svg";
import admins_white from "../assets/images/admins-white.svg";
import stats from "../assets/images/stats.svg";
import stats_white from "../assets/images/stats-white.svg";
import settings from "../assets/images/settings.svg";
import settings_white from "../assets/images/settings-white.svg";
import dev from "../assets/images/dev.svg";
import dev_white from "../assets/images/dev-white.svg";
import sparkles from "../assets/images/sparkles_ai.svg";

const images = {
  logo,
  wojologo,
  contact,
  notification,
  search,
  preview,
  nav_notification,
  nav_information,
  nav_dashboard,
  nav_catalog,
  nav_news,
  nav_connect,
  add,
  addActive,
  addInactive,
  addBlue,
  closeBlue,
  trash,
  edit,
  pencil,
  validate,
  underConstruction,
  ground,
  dashboard,
  waiting,
  pending,
  sales,
  completed,
  cash,
  validated,
  bullet,
  room,
  coworking,
  office,
  parking,
  accounts,
  totalAccounts,
  defaultCenter,
  backBlack,
  mapPin,
  searchWhite,
  avatar,
  password,
  nav_catalog_2,
  nav_communication_2,
  nav_building_2,
  nav_pilot_2,
  eye,
  eyeBlocked,
  cancel,
  details,
  deleteIcon,
  userIcon,
  caseIcon,
  checked,
  addIcon,
  deskIcon,
  padlock,
  padlockPrivatizable,
  chevronDown,
  previous,
  next,
  gift,
  mail,
  filterOff,
  filterOn,
  filterSearch,
  alert,
  pin,
  pinned,
  logout,
  bottomArrow,
  assistance,
  analytics,
  duplicate,
  highPriority,
  combined,
  trendingUp,
  trendingDown,
  trendingFlat,
  users,
  ga,
  checkIn,
  checkOut,
  realtime,
  webhook,
  paymentMethods,
  ok,
  ko,
  folderPlus,
  folderMinus,
  imageFile,
  pdfFile,
  wordFile,
  newIcon,
  appClients,
  attrKeys,
  priceUnitVariations,
  buildings,
  buildings_white,
  providers,
  providers_white,
  customers,
  customers_white,
  admins,
  admins_white,
  stats,
  stats_white,
  settings,
  settings_white,
  dev,
  dev_white,
  sparkles,
};

export default images;
