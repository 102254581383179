import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Location } from "history";
import { match as Match } from "react-router-dom";
import { ThunkCreator } from "easy-peasy";
import { injectIntl, IntlShape } from "react-intl";
import history from "../../../../history";
import { EnumPaths } from "../../../../utils/navigation";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import PageSubheader from "../../../../components/PageSubheader";
import { withTwoColLayout } from "../../../../utils";
import Table, {
  ALL_FILTER,
  ALL_FILTER_NUMBER,
  IFilterField,
} from "../../../../components/Table";
import { TableChangeParams } from "../../../../components/Table/types";
import Dropdown, {
  DropdownValues,
} from "../../../../components/Tailwind/Dropdown";
import { getTranslation } from "../../../../utils/translation";
import {
  Incident,
  ListIncidentsOpts,
  SearchableIncidentsFilterInput,
} from "../../../../lib/ground-aws-graphql-core/models/Api/Incidents";
import DropdownIncidentsStatus from "../../../../components/Tailwind/DropdownIncidentsStatus";
import { EnumFilterFields } from "../../../../utils/filter";
import { EnumPermissionEntity } from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import images from "../../../../images";
import { GroundAuthContextStore } from "../../../../lib/ground-aws-cognito-auth-core";
import contextStore from "../../../../redux/store";
import { frFRLang, getLocale } from "../../../../lang";
import { ILoadOptionsResponse } from "../../global-orders/global-orders-list/table";
import {
  displayDayDDMMYYYY_HHMM,
  displayDayYYYYMMDDHHMMSS,
  parseISODateFn,
} from "../../../../utils/config";

interface Props {
  match: Match<{ cid: string; id: string; pid: string }>;
  intl: IntlShape;
  location: Location<{
    pageIndex: number;
    limit: number;
    type: string;
    filters: IFilterField<{
      code: string;
      id: string;
      name: string;
    }>[];
  }>;
}

const DEFAULT_LIMIT = 20;

const ListIncidents = (props: Props) => {
  const { intl, match, location } = props;
  const [selectedFilters, setSelectedFilters] =
    useState<SearchableIncidentsFilterInput>({});

  const [loading, setLoading] = useState(false);
  const [initialParams, setInitialParams] = useState<TableChangeParams | null>(
    location?.state
  );
  const [incidentTypeFilter, setIncidentTypeFilter] = useState<
    IFilterField<{
      code: string;
      id: string;
      name: string;
    }>
  >();

  const listIncidents = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.listIncidents
  );

  const exportIncidentsAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.exportIncidents
  );

  const incidents = GroundGraphqlContextStore.useStoreState(
    state => state.incidents.incidents.items
  );

  const meDetails = GroundAuthContextStore.useStoreState(
    state => state.authentication.meDetails
  );

  const total = GroundGraphqlContextStore.useStoreState(
    state => state.incidents.incidents.total
  );
  const listIncidentStatus = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.listIncidentStatus
  );
  const listIncidentTypes = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.listIncidentTypes
  );
  const listIncidentReasons = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.listIncidentReasons
  );
  const incidentsStatus = GroundGraphqlContextStore.useStoreState(
    state => state.incidents.incidentsStatus.items
  );
  const viewIncident = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.viewIncident
  );
  const pinIncident = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.pinIncident
  );
  const unpinIncident = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.unpinIncident
  );
  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  useEffect(() => {
    if (location?.state) {
      fetchData(
        location?.state?.pageIndex,
        true,
        location?.state?.limit,
        location?.state?.filters
      );
    } else fetchData();
  }, []);

  useEffect(() => {
    if (
      incidentTypeFilter &&
      incidentTypeFilter.value &&
      incidentTypeFilter.value.id !== ALL_FILTER &&
      incidentTypeFilter.value.code &&
      initialParams?.filters?.length
    ) {
      const fetchData = async () => {
        if (incidentTypeFilter?.value) {
          const response = await listIncidentReasons({
            id: incidentTypeFilter.value.code,
            offset: 0,
            limit: 40,
          });
          const options = response.items.map(({ id, code, label }) => ({
            value: code,
            code: id,
            label: getTranslation(label),
          }));

          const allOptions = [
            {
              value: ALL_FILTER,
              code: ALL_FILTER_NUMBER,
              label: intl.formatMessage({ id: "general.all" }),
            },
            ...options,
          ];

          const newFilters =
            initialParams.filters?.filter(
              f => f.type !== EnumFilterFields[EnumFilterFields.INCIDENT_REASON]
            ) || [];

          newFilters.push({
            type: EnumFilterFields[EnumFilterFields.INCIDENT_REASON],
            value: allOptions[0],
            options: allOptions,
          });

          initialParams.filters = newFilters;

          // set initial params
          setInitialParams({ ...initialParams });
        }
      };

      // fetch incident reasons when changing incident type
      fetchData();
    }
  }, [incidentTypeFilter]);

  const fetchData = (
    pageIndex = 0,
    loader = true,
    limit = DEFAULT_LIMIT,
    filters?: IFilterField<any>[]
  ) => {
    const filter: SearchableIncidentsFilterInput = {};
    if (filters?.length) {
      let createStartDate;
      let createEndDate;
      filters.forEach(f => {
        if (
          f.type === EnumFilterFields[EnumFilterFields.STATUS] &&
          f.value?.id !== undefined &&
          f.value.id !== ALL_FILTER
        ) {
          filter.status = { eq: f.value.id };
        }

        if (
          f.type === EnumFilterFields[EnumFilterFields.INCIDENT_TYPE] &&
          f.value?.id &&
          f.value.id !== ALL_FILTER
        ) {
          filter.type = { eq: f.value.id };
        }

        if (
          f.type === EnumFilterFields[EnumFilterFields.INCIDENT_REASON] &&
          f.value?.value &&
          f.value.value !== ALL_FILTER
        ) {
          filter.reason = { eq: f.value.value };
        }

        if (
          f.type === EnumFilterFields[EnumFilterFields.CLIENT_NAME] &&
          f.value
        ) {
          filter.lastname = { like: f.value };
        }

        // This condition works because we don't want to filter for unpinned elements, we need only the pinned ones
        if (f.type === EnumFilterFields[EnumFilterFields.PINNED] && f.value) {
          filter.pinned = { eq: f.value };
        }

        if (
          f.type === EnumFilterFields[EnumFilterFields.CREATION_START_DATE] &&
          f.value
        ) {
          const start = parseISODateFn(f.value);
          start.setHours(0, 0, 0, 0);
          createStartDate = start.toISOString();
        }
        if (
          f.type === EnumFilterFields[EnumFilterFields.CREATION_END_DATE] &&
          f.value
        ) {
          const end = parseISODateFn(f.value);
          end.setHours(0, 0, 0, 0);

          createEndDate = end.toISOString();
        }
      });

      if (createStartDate && createEndDate) {
        filter.created_at = {
          between: [createStartDate, createEndDate],
        };
      }

      if (createStartDate && !createEndDate) {
        filter.created_at = {
          gte: createStartDate,
        };
      }
      if (!createStartDate && createEndDate) {
        filter.created_at = {
          lte: createEndDate,
        };
      }
    }

    if (loader) setLoading(true);

    setSelectedFilters(filter);
    Promise.all([
      listIncidents({
        buildingId: match.params.cid,
        offset: pageIndex * limit,
        limit,
        filter,
      }),
      listIncidentStatus(),
    ]).finally(() => setLoading(false));
  };

  const exportIncidents = (): ThunkCreator<ListIncidentsOpts, any> => {
    return exportIncidentsAction({
      buildingId: match.params.cid,
      filter: selectedFilters,
      limit: 100,
      offset: 0,
    });
  };

  const loadIncidentTypes = async (
    _searchQuery: any,
    _loadedOptions: any,
    { page }: { page: number }
  ): Promise<ILoadOptionsResponse> => {
    const response = await listIncidentTypes();
    let options = response.items.map(({ id, code, label }) => {
      return { id: code, code: id, name: getTranslation(label) };
    });

    options = [
      {
        id: ALL_FILTER,
        code: ALL_FILTER_NUMBER,
        name: intl.formatMessage({ id: "general.all" }),
      },
      ...options,
    ];

    return {
      options,
      hasMore: false,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadIncidentStatus = async (
    _searchQuery: any,
    _loadedOptions: any,
    { page }: { page: number }
  ): Promise<ILoadOptionsResponse> => {
    const response = await listIncidentStatus();
    let options = response.items.map(({ id, code, label }) => {
      return { id: code, code: id, name: getTranslation(label) };
    });

    options = [
      {
        id: ALL_FILTER,
        code: ALL_FILTER_NUMBER,
        name: intl.formatMessage({ id: "general.all" }),
      },
      ...options,
    ];

    return {
      options,
      hasMore: false,
      additional: {
        page: page + 1,
      },
    };
  };

  const onIncidentClick = (id: number, editLink: string) => {
    viewIncident({ id }).finally(() => history.push(editLink, initialParams));
  };

  const tableHead = [
    "page.list.incidents.table.head.name",
    "general.date",
    "general.type",
    "general.reason",
    "general.client",
    "general.status",
    "general.actions",
  ];

  const tableBody = incidents.map((incident: Incident) => {
    const {
      id,
      priority,
      ref,
      created_at: createdAt,
      type,
      reason,
      pinned,
      user,
      viewer_id: viewerId,
    } = incident;

    const editIncidentLink = `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${match.params.cid}/${EnumPaths.INCIDENTS}/${id}/${EnumPaths.EDIT_MODE}`;

    const dropdownValues: DropdownValues[] = [
      {
        id: "edit_incident",
        label: "page.list.incidents.edit",
        icon: images.edit,
        link: editIncidentLink,
      },
      {
        id: "pin",
        label: pinned ? "general.unpin" : "general.pin",
        icon: images.pin,
        onClick: () => {
          setLoading(true);

          if (pinned) unpinIncident({ id }).finally(() => setLoading(false));
          else pinIncident({ id }).finally(() => setLoading(false));
        },
      },
    ];

    const incidentElements = [
      {
        element: (
          <div className="flex flew-row items-center space-x-2">
            {pinned && (
              <img
                alt={`pin_${ref}`}
                src={images.pinned}
                style={{ width: 30, height: 30 }}
                className="list-thumbnail responsive border-0"
              />
            )}
            {!!priority && (
              <img
                alt={`alert_${ref}`}
                src={images.highPriority}
                style={{ width: 25, height: 25 }}
                className="list-thumbnail responsive border-0"
              />
            )}

            <div>{ref}</div>
          </div>
        ),
        onCellClick: () => onIncidentClick(id, editIncidentLink),
      },
      {
        element: displayDayDDMMYYYY_HHMM(createdAt, centerTimezone),
        onCellClick: () => onIncidentClick(id, editIncidentLink),
      },
      {
        element: getTranslation(type.label),
        onCellClick: () => onIncidentClick(id, editIncidentLink),
      },
      {
        element: getTranslation(reason.label),
        onCellClick: () => onIncidentClick(id, editIncidentLink),
      },
      {
        element: `${user.first_name} ${user.last_name}`,
        onCellClick: () => onIncidentClick(id, editIncidentLink),
      },
      {
        element: (
          <DropdownIncidentsStatus
            incident={incident}
            incidentsStatus={incidentsStatus}
            intl={intl}
            className="items-start"
          />
        ),
      },
      {
        element: (
          <Dropdown
            values={dropdownValues}
            dataCy={`incident-dropdown-${id}`}
          />
        ),
      },
    ];

    return {
      rowElements: incidentElements,
      rowClassName: viewerId ? "" : "bg-gray-100",
    };
  });

  const keyIncidentStatus = EnumFilterFields[EnumFilterFields.STATUS];
  const keyIncidentType = EnumFilterFields[EnumFilterFields.INCIDENT_TYPE];

  const loadOptions: {
    [key: string]: (
      searchQuery: any,
      _loadedOptions: any,
      { page }: { page: number }
    ) => Promise<ILoadOptionsResponse>;
  } = {};

  loadOptions[`${keyIncidentStatus}`] = loadIncidentStatus;
  loadOptions[`${keyIncidentType}`] = loadIncidentTypes;

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  const lang = currentAppLocale.locale || frFRLang.locale;

  const handleOnChange = (changeParams: TableChangeParams) => {
    const { pageIndex, limit, type } = changeParams;

    let { filters } = changeParams;

    if (type === EnumFilterFields[EnumFilterFields.INCIDENT_TYPE]) {
      const incidentTypeFilter = filters?.find(
        f => f.type === EnumFilterFields[EnumFilterFields.INCIDENT_TYPE]
      );

      // remove incident reasons filter
      filters = filters?.filter(
        d => d.type !== EnumFilterFields[EnumFilterFields.INCIDENT_REASON]
      );
      changeParams.filters = filters;

      // set incident type
      setIncidentTypeFilter(incidentTypeFilter);
    }

    // set initial params
    setInitialParams(changeParams);

    // fetch incidents using filters
    fetchData(pageIndex, false, limit, filters);
  };

  return (
    <div>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: "page.list.incidents.document.title",
          })}
        </title>
      </Helmet>

      <PageSubheader title="page.list.incidents.title" nbOfResults={total} />

      <Table
        initialParams={initialParams}
        head={tableHead}
        body={tableBody}
        noDataText="page.list.incidents.empty"
        onChange={handleOnChange}
        limitChoiceEnabled
        exportData={{
          onExport: exportIncidents,
          filename: `incidents-${
            meDetails?.operator.short_name
          }-${displayDayYYYYMMDDHHMMSS(new Date(), centerTimezone)}.csv`,
          headers: [
            { label: "N°", key: "ref" },
            { label: "Creation date", key: "created_at" },
            { label: "Type", key: `type[label][${lang}]` },
            { label: "Reason", key: `reason[label][${lang}]` },
            { label: "Description", key: `description[${lang}]` },
            { label: "First name", key: "user[first_name]" },
            { label: "Last name", key: "user[last_name]" },
            { label: "Status", key: `status[label][${lang}]` },
            { label: "Priority", key: "priority" },
            { label: "Location", key: `location[${lang}]` },
          ],
        }}
        paginationTotal={total}
        permissionEntity={EnumPermissionEntity.INCIDENT}
        useFilterBlock
        filterBlockTitle="page.list.incidents.filter.title"
        loading={loading}
        setLoading={setLoading}
        loadOptions={loadOptions}
        className="px-8"
      />
    </div>
  );
};

export default withTwoColLayout(injectIntl(ListIncidents));
