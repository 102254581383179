import React, { useState, useEffect } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { toast } from "react-toastify";
import { Location } from "history";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";
import { EnumPaths } from "../../../../../utils/navigation";
import history from "../../../../../history";
import Table from "../../../../../components/Table";
import Dropdown, {
  DropdownValues,
} from "../../../../../components/Tailwind/Dropdown";
import images from "../../../../../images";
import { getTranslation } from "../../../../../utils/translation";
import ConfirmModal from "../../../../../utils/modal/confirm";
import IntlMessages from "../../../../../utils/messages";
import { displayDayDDMMYYYY_HHMM } from "../../../../../utils/config";
import { IncidentReason } from "../../../../../lib/ground-aws-graphql-core/models/Api/Incidents";
import Button from "../../../../../components/Tailwind/Button";
import { TableChangeParams } from "../../../../../components/Table/types";

interface Props {
  typeId: string;
  intl: IntlShape;
  location: Location<{
    pageIndex: number;
    limit: number;
    type: string;
  }>;
}

const DEFAULT_LIMIT = 5;

const ListIncidentReasons = (props: Props): JSX.Element => {
  const { intl, typeId, location } = props;
  const [loading, setLoading] = useState(false);

  const [showDeleteIncidentReasonModal, setShowDeleteIncidentReasonModal] =
    useState(false);
  const [initialParams, setInitialParams] = useState<TableChangeParams | null>(
    location?.state
  );

  const [incidentReasonId, setIncidentReasonId] = useState<number>();
  const listIncidentReasons = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.listIncidentReasons
  );

  const incidentReasons = GroundGraphqlContextStore.useStoreState(
    actions => actions.incidents.incidentReasons.items
  );

  const total = GroundGraphqlContextStore.useStoreState(
    actions => actions.incidents.incidentReasons.total
  );

  const setIncidentReason = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.setIncidentReason
  );

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const deleteIncidentReason = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.deleteIncidentReason
  );

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = (pageIndex = 0, loader = true, limit = DEFAULT_LIMIT) => {
    console.log("detch data:");
    if (loader) setLoading(true);

    listIncidentReasons({
      id: typeId,
      offset: pageIndex * limit,
      limit,
    }).finally(() => setLoading(false));
  };

  const addNewIncidentReason = () => {
    history.push(
      `/${EnumPaths.ROOT}/${EnumPaths.INCIDENT_TYPES}/${typeId}/reasons/${EnumPaths.CREATION_MODE}`
    );
  };

  const handleOnChange = (changeParams: TableChangeParams) => {
    const { pageIndex, limit } = changeParams;
    setInitialParams(changeParams);
    fetchData(pageIndex, false, limit);
  };

  const handleEditIncidentReason = (id: number) => {
    const editLink = `/${EnumPaths.ROOT}/${EnumPaths.INCIDENT_TYPES}/${typeId}/reasons/${id}/${EnumPaths.EDIT_MODE}`;
    setIncidentReason(null);
    history.push(editLink);
  };

  const handleDelete = () => {
    setLoading(true);
    setShowDeleteIncidentReasonModal(false);
    deleteIncidentReason({ typeId, input: { id: incidentReasonId! } })
      .then(() => {
        toast(
          intl.formatMessage({
            id: "page.incident.reason.delete.success",
          }),
          { type: "success" }
        );
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "page.incident.reason.delete.error",
          }),
          {
            type: "error",
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const tableHead = [
    "page.incident.reason.label",
    "page.incident.reason.code",
    "page.incident.reason.created_at",
    "page.incident.reason.updated_at",
    "general.actions",
  ];

  const tableBody = incidentReasons.map((incidentReason: IncidentReason) => {
    const { id, code, label, created_at, updated_at } = incidentReason;
    const editLink = `/${EnumPaths.ROOT}/${EnumPaths.INCIDENT_TYPES}/${typeId}/reasons/${id}/${EnumPaths.EDIT_MODE}`;

    const dropdownValues: DropdownValues[] = [
      {
        id: "edit_incident_reason_key",
        label: "page.list.incident.reasons.edit",
        icon: images.edit,
        link: editLink,
      },
      {
        id: "delete",
        label: "general.delete",
        icon: images.deleteIcon,
        onClick: () => {
          setShowDeleteIncidentReasonModal(!showDeleteIncidentReasonModal);
          setIncidentReasonId(id);
        },
      },
    ];

    const elements = [
      {
        element: getTranslation(label),
        onCellClick: () => handleEditIncidentReason(id),
      },
      {
        element: code,
        onCellClick: () => handleEditIncidentReason(id),
      },
      {
        element: displayDayDDMMYYYY_HHMM(created_at, centerTimezone),
        onCellClick: () => handleEditIncidentReason(id),
      },
      {
        element: displayDayDDMMYYYY_HHMM(updated_at, centerTimezone),
        onCellClick: () => handleEditIncidentReason(id),
      },
      {
        element: (
          <Dropdown
            values={dropdownValues}
            dataCy={`incident-reason-dropdown-${id}`}
          />
        ),
      },
    ];

    return {
      rowElements: elements,
    };
  });

  return (
    <div>
      <div className="md:flex md:items-center md:justify-between bg-white px-8 h-20 w-full">
        <div className="flex items-center">
          <h3 className="text-16px leading-6 font-medium text-gray-900">
            <IntlMessages id="page.list.incident.reasons.title" />
          </h3>
          <span className="text-17px text-ground-blue-100 leading-5 ml-1">
            ({total})
          </span>
        </div>
        <Button
          id="btn-create-incident-reason"
          name="btn-create-incident-reason"
          item={null}
          outline
          type="button"
          onClick={addNewIncidentReason}
        >
          <IntlMessages id="page.list.incident.reasons.create.incident.reason" />
        </Button>
      </div>

      <Table
        head={tableHead}
        body={tableBody}
        noDataText="page.list.incident.reasons.empty"
        onChange={handleOnChange}
        paginationLimit={DEFAULT_LIMIT}
        paginationTotal={total}
        loading={loading}
        setLoading={setLoading}
      />

      <ConfirmModal
        isOpen={showDeleteIncidentReasonModal}
        onRequestClose={() =>
          setShowDeleteIncidentReasonModal(!showDeleteIncidentReasonModal)
        }
        toggle={() =>
          setShowDeleteIncidentReasonModal(!showDeleteIncidentReasonModal)
        }
        handleConfirm={handleDelete}
        content={<IntlMessages id="page.list.incident.reasons.delete" />}
      />
    </div>
  );
};

export default injectIntl(ListIncidentReasons);
