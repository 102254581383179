export const createAuthorization = /* GraphQL */ `
  mutation CreateAuthorization($input: CreateAuthorizationInput!) {
    createAuthorization(input: $input) {
      id
      user {
        id
        firstname
        lastname
        email
        phone
        role
        picture
        enabled
        markForDelete
        username
      }
      entityType
      entityId
      createdAt
      updatedAt
    }
  }
`;

export const createEnterprise = /* GraphQL */ `
  mutation CreateEnterprise($input: CreateEnterpriseInput!) {
    createEnterprise(input: $input) {
      id
      name
      siret
      paymentOnInvoice
    }
  }
`;

export const updateEnterprise = /* GraphQL */ `
  mutation UpdateEnterprise($input: UpdateEnterpriseInput!) {
    updateEnterprise(input: $input) {
      id
      name
      siret
      paymentOnInvoice
    }
  }
`;

export const deleteEnterprise = /* GraphQL */ `
  mutation DeleteEnterprise($input: BaseDeleteInput!) {
    deleteEnterprise(input: $input)
  }
`;

export const addPrivatization = /* GraphQL */ `
  mutation AddPrivatization($input: CreatePrivatizationInput!) {
    addPrivatization(input: $input) {
      id
      start
      end
      product {
        id
        name
      }
      markForDelete
      createdAt
      updatedAt
    }
  }
`;

export const updatePrivatization = /* GraphQL */ `
  mutation UpdatePrivatization($input: UpdatePrivatizationInput!) {
    updatePrivatization(input: $input) {
      id
      start
      end
      product {
        id
        name
      }
      markForDelete
      createdAt
      updatedAt
    }
  }
`;

export const deletePrivatization = /* GraphQL */ `
  mutation DeletePrivatization($input: BaseDeleteInput!) {
    deletePrivatization(input: $input)
  }
`;

export const createCenter = /* GraphQL */ `
  mutation CreateCenter($input: CreateCenterInput!) {
    createCenter(input: $input) {
      id
      name
      description
      address
      zipCode
      city
      country
      location {
        lat
        lon
      }
      pictures
      enabled
      markForDelete
      phone
      email
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const updateCenter = /* GraphQL */ `
  mutation UpdateCenter($input: UpdateCenterInput!) {
    updateCenter(input: $input) {
      operator {
        id
      }
      id
      type {
        id
        name
        type
        enabled
        markForDelete
        createdAt
        updatedAt
      }
      name
      description
      address
      zipCode
      city
      country
      location {
        lat
        lon
      }
      pictures
      enabled
      markForDelete
      phone
      email
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const updateCenterState = /* GraphQL */ `
  mutation UpdateCenterState($input: UpdateCenterStateInput!) {
    updateCenterState(input: $input) {
      operator {
        id
      }
      id
      type {
        id
        name
        type
        enabled
        markForDelete
        createdAt
        updatedAt
      }
      name
      description
      address
      zipCode
      city
      country
      location {
        lat
        lon
      }
      pictures
      enabled
      markForDelete
      phone
      email
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const deleteCenter = /* GraphQL */ `
  mutation DeleteCenter($input: BaseDeleteInput!) {
    deleteCenter(input: $input)
  }
`;

export const createCenterType = /* GraphQL */ `
  mutation CreateCenterType($input: CreateCenterTypeInput!) {
    createCenterType(input: $input) {
      id
      name
      type
      enabled
      markForDelete
      createdAt
      updatedAt
    }
  }
`;
export const updateCenterType = /* GraphQL */ `
  mutation UpdateCenterType($input: UpdateCenterTypeInput!) {
    updateCenterType(input: $input) {
      id
      name
      type
      enabled
      markForDelete
      createdAt
      updatedAt
    }
  }
`;
export const createService = /* GraphQL */ `
  mutation CreateService($input: CreateServiceInput!) {
    createService(input: $input) {
      id
      type
      name
      description
      pictures
      category {
        id
        type
        name
        description
        picture
        level
        position
        enabled
        markForDelete
        metadata
        createdAt
        updatedAt
      }
      authorized
      enabled
      markForDelete
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const updateService = /* GraphQL */ `
  mutation UpdateService($input: UpdateServiceInput!) {
    updateService(input: $input) {
      id
      type
      name
      description
      pictures
      category {
        id
        type
        name
        description
        picture
        level
        position
        enabled
        markForDelete
        metadata
        createdAt
        updatedAt
      }
      authorized
      enabled
      markForDelete
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const deleteService = /* GraphQL */ `
  mutation DeleteService($input: UpdateServiceInput!) {
    updateService(input: $input) {
      id
      type
      name
      description
      pictures
      category {
        id
        type
        name
        description
        picture
        level
        position
        enabled
        markForDelete
        metadata
        createdAt
        updatedAt
      }
      authorized
      enabled
      markForDelete
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      id
      type
      name
      description
      picture
      level
      position
      center {
        id
      }
      parent {
        id
        type
        name
        description
        picture
        level
        position
        enabled
        markForDelete
        metadata
        createdAt
        updatedAt
      }
      enabled
      markForDelete
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      id
      type
      name
      description
      picture
      level
      position
      center {
        id
      }
      parent {
        id
        type
        name
        description
        picture
        level
        position
        enabled
        markForDelete
        metadata
        createdAt
        updatedAt
      }
      enabled
      markForDelete
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      id
      type
      name
      description
      picture
      level
      position
      center {
        id
      }
      parent {
        id
        type
        name
        description
        picture
        level
        position
        enabled
        markForDelete
        metadata
        createdAt
        updatedAt
      }
      enabled
      markForDelete
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const createProvider = /* GraphQL */ `
  mutation CreateProvider($input: CreateProviderInput!) {
    createProvider(input: $input) {
      id
      name
      description
      address
      zipCode
      city
      country
      phone
      email
      pictures
      enabled
      receivingEmails
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const updateProvider = /* GraphQL */ `
  mutation UpdateProvider($input: UpdateProviderInput!) {
    updateProvider(input: $input) {
      id
      name
      description
      address
      zipCode
      city
      country
      phone
      email
      pictures
      enabled
      receivingEmails
      metadata
      createdAt
      updatedAt
    }
  }
`;

export const deleteProvider = /* GraphQL */ `
  mutation DeleteProvider($input: BaseDeleteInput!) {
    deleteProvider(input: $input)
  }
`;

export const createProduct = /* GraphQL */ `
  mutation CreateProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      id
      type
      sku
      name
      typology
      description
      pictures
      category {
        id
        type
        name
        description
        picture
        level
        position
        enabled
        markForDelete
        metadata
        createdAt
        updatedAt
      }
      provider {
        id
        name
        description
        address
        zipCode
        city
        country
        phone
        email
        pictures
        authorized
        enabled
        markForDelete
        metadata
        createdAt
        updatedAt
      }
      vat
      capacity
      stock
      authorized
      enabled
      markForDelete
      flexible
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      id
      type
      sku
      name
      typology
      description
      pictures
      category {
        id
        type
        name
        description
        picture
        level
        position
        enabled
        markForDelete
        metadata
        createdAt
        updatedAt
      }
      provider {
        id
        name
        description
        address
        zipCode
        city
        country
        phone
        email
        pictures
        authorized
        enabled
        markForDelete
        metadata
        createdAt
        updatedAt
      }
      vat
      capacity
      stock
      authorized
      enabled
      markForDelete
      flexible
      metadata
      createdAt
      updatedAt
    }
  }
`;

export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct($input: BaseDeleteInput!) {
    deleteProduct(input: $input)
  }
`;

export const createOption = /* GraphQL */ `
  mutation CreateOption($input: CreateOptionInput!) {
    createOption(input: $input) {
      id
      product {
        id
        type
        sku
        name
        typology
        description
        pictures
        vat
        capacity
        stock
        authorized
        enabled
        markForDelete
        flexible
        metadata
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOption = /* GraphQL */ `
  mutation DeleteOption($input: DeleteOptionInput!) {
    deleteOption(input: $input)
  }
`;
export const createSingleProduct = /* GraphQL */ `
  mutation CreateSingleProduct($input: CreateSingleProductInput!) {
    createSingleProduct(input: $input) {
      id
      product {
        id
        type
        sku
        name
        typology
        description
        pictures
        vat
        capacity
        stock
        authorized
        enabled
        markForDelete
        flexible
        metadata
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSingleProduct = /* GraphQL */ `
  mutation DeleteSingleProduct($input: BaseDeleteInput!) {
    deleteSingleProduct(input: $input)
  }
`;

export const updateAttributeKey = /* GraphQL */ `
  mutation UpdateAttributeKey($input: UpdateAttributeKeyInput!) {
    updateAttributeKey(input: $input) {
      id
      name
      attributeKeyCategoryId
      type
      description
      picture
      enabled
      markForDelete
      position
      typology
      system
      required
      roles
      unit
      metadata
      createdAt
      updatedAt
    }
  }
`;

export const createAttribute = /* GraphQL */ `
  mutation CreateAttribute($input: CreateAttributeInput!) {
    createAttribute(input: $input) {
      id
      key {
        id
        name
        type
        description
        picture
        enabled
        markForDelete
        position
        typology
        system
        required
        roles
        unit
        metadata
        createdAt
        updatedAt
      }
      booleanValue
      stringValue
      value
      jsonValue
      numberValue
      urlValue
      dateValue
      selectValue
      enabled
      markForDelete
      createdAt
      updatedAt
    }
  }
`;
export const updateAttribute = /* GraphQL */ `
  mutation UpdateAttribute($input: UpdateAttributeInput!) {
    updateAttribute(input: $input) {
      id
      key {
        id
        name
        type
        description
        picture
        enabled
        markForDelete
        position
        typology
        system
        required
        roles
        unit
        metadata
        createdAt
        updatedAt
      }
      booleanValue
      stringValue
      value
      jsonValue
      numberValue
      urlValue
      dateValue
      selectValue
      enabled
      markForDelete
      createdAt
      updatedAt
    }
  }
`;
export const deleteAttribute = /* GraphQL */ `
  mutation DeleteAttribute($input: DeleteManyInput!) {
    deleteAttribute(input: $input)
  }
`;
export const createDaySlot = /* GraphQL */ `
  mutation CreateDaySlot($input: CreateDaySlotInput!) {
    createDaySlot(input: $input) {
      id
      name
      day
      dayBreak
      markForDelete
    }
  }
`;
export const updateDaySlot = /* GraphQL */ `
  mutation UpdateDaySlot(
    $input: UpdateDaySlotInput!
    $condition: ModelDaySlotConditionInput
  ) {
    updateDaySlot(input: $input, condition: $condition) {
      operator {
        id
        shortname
        firstname
        lastname
        email
        phone
        organizationName
        address
        zipCode
        city
        country
        enabled
        markForDelete
        userPoolId
        identityPoolId
        systemAdminPolicy
        systemAdminRole
        systemUserPolicy
        systemUserRole
        operatorAdminPolicy
        operatorAdminRole
        operatorUserPolicy
        operatorUserRole
        centerAdminPolicy
        centerAdminRole
        centerUserPolicy
        centerUserRole
        tenantAdminPolicy
        tenantAdminRole
        tenantUserPolicy
        tenantUserRole
        userPolicy
        userRole
        anonymousPolicy
        anonymousRole
        trustRole
        tier
        metadata
        createdAt
        updatedAt
      }
      id
      name
      day
      dayBreak
      markForDelete
      createdAt
      updatedAt
    }
  }
`;
export const createClosedDay = /* GraphQL */ `
  mutation CreateClosedDay($input: CreateClosedDayInput!) {
    createClosedDay(input: $input) {
      id
      start
      end
      markForDelete
      createdAt
      updatedAt
    }
  }
`;

export const createPriceUnit = /* GraphQL */ `
  mutation CreatePriceUnit($input: CreatePriceUnitInput!) {
    createPriceUnit(input: $input) {
      id
      unit
      currency
      increment
      price
      variation {
        id
        key
        name
        enabled
        markForDelete
        metadata
        createdAt
        updatedAt
      }
      minQuantity
      maxQuantity
      enabled
      markForDelete
      buyableUnit
      buyableDescription
      flexible
      minApprovalMinutes
      minCancelMinutes
      createdAt
      updatedAt
    }
  }
`;
export const updatePriceUnit = /* GraphQL */ `
  mutation UpdatePriceUnit($input: UpdatePriceUnitInput!) {
    updatePriceUnit(input: $input) {
      id
      unit
      currency
      increment
      price
      variation {
        id
        key
        name
        enabled
        markForDelete
        metadata
        createdAt
        updatedAt
      }
      minQuantity
      maxQuantity
      enabled
      markForDelete
      buyableUnit
      buyableDescription
      flexible
      minApprovalMinutes
      minCancelMinutes
      createdAt
      updatedAt
    }
  }
`;
export const deletePriceUnit = /* GraphQL */ `
  mutation DeletePriceUnit($input: BaseDeleteInput!) {
    deletePriceUnit(input: $input)
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      id
      title
      description
      author {
        id
        firstname
        lastname
        email
      }
      picture
      start
      end
      publicationDate
      unpublicationDate
      type {
        id
        name
        type
        enabled
        markForDelete
      }
      authorized
      enabled
      markForDelete
      createdAt
      updatedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent($input: UpdateEventInput!) {
    updateEvent(input: $input) {
      id
      title
      description
      author {
        id
        firstname
        lastname
        email
      }
      picture
      start
      end
      publicationDate
      unpublicationDate
      type {
        id
        name
        type
        enabled
        markForDelete
      }
      authorized
      enabled
      markForDelete
      createdAt
      updatedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent($input: BaseDeleteInput!) {
    deleteEvent(input: $input)
  }
`;
export const createEventType = /* GraphQL */ `
  mutation CreateEventType($input: CreateEventTypeInput!) {
    createEventType(input: $input) {
      id
      name
      type
      enabled
      markForDelete
      createdAt
      updatedAt
    }
  }
`;
export const updateEventType = /* GraphQL */ `
  mutation UpdateEventType($input: UpdateEventTypeInput!) {
    updateEventType(input: $input) {
      id
      name
      type
      enabled
      markForDelete
      createdAt
      updatedAt
    }
  }
`;
export const createNews = /* GraphQL */ `
  mutation CreateNews($input: CreateNewsInput!) {
    createNews(input: $input) {
      id
      title
      description
      picture
      author {
        id
        firstname
        lastname
        email
      }
      publicationDate
      unpublicationDate
      enabled
      markForDelete
      createdAt
      updatedAt
    }
  }
`;
export const updateNews = /* GraphQL */ `
  mutation UpdateNews($input: UpdateNewsInput!) {
    updateNews(input: $input) {
      id
      title
      description
      picture
      author {
        id
        firstname
        lastname
        email
      }
      publicationDate
      unpublicationDate
      enabled
      markForDelete
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification($input: UpdateNotificationInput!) {
    updateNotification(input: $input) {
      id
      type
      entity
      description
      enabled
      markForDelete
      creationDate
      viewDate
      locale
      createdAt
      updatedAt
    }
  }
`;
