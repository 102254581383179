import React from "react";
import ListPriceUnits from "..";
import IntlMessages from "../../../../utils/messages";
import {
  PriceUnitItemVariation,
  getPriceUnitElementsByType,
} from "../../../../utils/price-unit";
import { getTranslation } from "../../../../utils/translation";
import { injectIntl, IntlShape } from "react-intl";
import {
  EnumProductTypology,
  EnumServiceType,
} from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { PriceUnitVariation } from "../../../../lib/ground-aws-graphql-core/models/PriceUnitVariation";
import { PriceUnit } from "../../../../lib/ground-aws-graphql-core/models/PriceUnit";

interface Props {
  intl: IntlShape;
  variations: PriceUnitVariation[];
  priceUnitItemsVariation: PriceUnitItemVariation | null;
  index: number;
  serviceType: EnumServiceType;
  productTypology: EnumProductTypology | null | undefined;
  onAddPriceUnit: (variation: PriceUnitVariation) => void;
  onUpdatePriceUnit: (item: PriceUnit, variation: PriceUnitVariation) => void;
  onDeletePriceUnit: (item: PriceUnit, variation: PriceUnitVariation) => void;
}

const ListPriceUnitsVariation = (props: Props): JSX.Element => {
  const {
    variations,
    priceUnitItemsVariation,
    intl,
    serviceType,
    productTypology,
    onAddPriceUnit,
    onUpdatePriceUnit,
    onDeletePriceUnit,
  } = props;

  const values = Object.values(
    getPriceUnitElementsByType(intl, serviceType, productTypology)
  );

  return (
    <div className="bg-white shadow sm:rounded-lg my-30px">
      <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
        <h3 className="text-16px leading-6 font-medium text-gray-900">
          <IntlMessages id="page.price.units.title" />
        </h3>
      </div>
      {variations.map((variation: PriceUnitVariation, index: number) => {
        let priceUnitItems = priceUnitItemsVariation
          ? priceUnitItemsVariation[variation.id]
          : [];
        if (!priceUnitItems) {
          priceUnitItems = [];
        }

        return (
          <div key={`price_units_variation_${index}`}>
            <ListPriceUnits
              productTypology={productTypology}
              label={getTranslation(variation.name)}
              name={`price_units_for_${variation.id}`}
              index={index + props.index}
              variation={variation}
              priceUnits={priceUnitItems}
              values={values}
              onAddPriceUnit={onAddPriceUnit}
              onUpdatePriceUnit={onUpdatePriceUnit}
              onDeletePriceUnit={onDeletePriceUnit}
            />
          </div>
        );
      })}
    </div>
  );
};

export default injectIntl(ListPriceUnitsVariation);
